<template>
    <v-container>
        <v-row style="padding:10px">
            <v-card tile :loading="loading" width="100%">
                <v-form ref="form" v-model="valid" width="100%">
                    <v-card-title>
                        Nyt Kodeord
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="8" md="12">
                                    <v-text-field v-model="Email"
                                                  label="Email"
                                                  hint="Indtast venligst en email"
                                                  :error-messages="errorMessages"
                                                  :rules="[() => !!Email || 'Vi kan ikke sende dig et link til nyt kodeord, hvis du ikke giver os en email.']"
                                                  required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn class="hidden-sm-and-down" text @click="LoginClicked">Login Istedet</v-btn>
                        <v-spacer class="hidden-sm-and-down"></v-spacer>
                        <v-btn color="primary" class="hidden-sm-and-down" tile @click="NewPassword">Nyt Kodeord</v-btn>
                        <v-btn color="primary" block class="hidden-md-and-up" style="margin-left:0px" tile @click="NewPassword">Nyt Kodeord</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-row>
        <v-row class="hidden-sm-and-up justify-center">
            <v-btn text @click="LoginClicked">Login Istedet</v-btn>
        </v-row>
        <!--<v-row style="padding:30px; padding-top:10px">
        <p>Har du ikke en bruger?</p>
        <v-spacer></v-spacer>
        <v-btn color="green lighten-1" dark @click="CreateNewUserClicked">Lav ny bruger</v-btn>
    </v-row>-->
    </v-container>
</template>

<script>
    import { mapState } from 'vuex'
    import axios from 'axios';
    export default {
            name: 'ForgotMyPasswordModule',
        data: () => ({
            Email: '',
            errorMessages: '',
            valid: false,
            rules: {
            required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            },
        }),
        computed: {
            ...mapState({
                loading: state => state.loading
            })
        },
        methods: {
            NewPassword() {
                this.$store.commit('setLoading', true);
                axios.post("/api/Login/RequestPasswordChange", { Email: this.Email })
                    .then(() => { 
                        this.$store.commit('setAlertSuccessSnack', "Email er sendt af sted.");
                        this.$emit('LoginClicked', { Login: true });
                    })
                    .finally(() => {
                        this.$store.commit('setLoading', false);
                    });
            },
            CreateNewUserClicked() {
                this.$emit('CreateUser', { CreateUser: true });
            },
            LoginClicked() {
                this.$emit('LoginClicked', { Login: true });
            }
        }
    }
</script>

<style>

</style>