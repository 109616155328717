<template>
    <v-card tile :loading="loading" :loader-height="12">
        <v-card-title>
            <span class="headline">Opdater kodeord for bruger: {{editedItem.name}}</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <h4>
                    <p class="mb-0">Her kan du opdatere brugerens kodeord direkte - dette bør kun bruges hvis brugerens "skift password" fejler af en eller anden grund.</p>
                    <p class="mb-0">Gør brugeren opmærksom på at de kan opdatere deres kode via. dinSundhedsguide portalen.</p>
                </h4>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="password"
                            label="Nyt kodeord"
                            hint="Indtast venligst et nyt kodeord"
                            required
                            autofocus>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile color="error" text @click="close">Annuller</v-btn>
            <v-btn tile color="primary" text :disabled="loading" @click="overridePassword">Gem</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { mapState } from 'vuex';
    import _ from 'lodash';

    export default {
        name: 'ChangePasswordForm',
        props: ['_editedBase'],
        data: () => ({
            password: '',
        }),
        computed: {
            ...mapState({
                loading: state => state.loading,
            }),
            editedItem() {
                return _.cloneDeep(this._editedBase);
            }
        },
        methods: {
            async overridePassword() {
                this.$store.commit("setLoading", true);
                await this.$store.dispatch("AdminOverridePassword", { userId: this.editedItem.id, password: this.password });
                this.$store.commit("setLoading", false);
                
                this.close();
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>
