<template>
    <div v-if="loggedInUser.role == 'Admin'" class="subscriptions d-flex justify-center" style="padding:10px; padding-top:20px">
        <v-card :loading="loading" width="100vw" min-width="300px">
            <v-card-title style="padding-top:0px; padding-bottom:0px;">
                <v-container style="margin:10px; max-width:100%; padding-top:0px; padding-bottom:0px;">
                    <v-row>
                        <v-col cols="4">
                            <v-row>
                                Abonnementer
                            </v-row>
                            <v-row>
                                <v-switch v-model="showOnlyActiveSubs" label="Vis kun Aktive abonnenter">
                                </v-switch>
                            </v-row>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field v-model="search"
                                          append-icon="mdi-magnify"
                                          label="Søg i Abonnementer"
                                          single-line
                                          hide-details>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="subscriptionHeaders"
                                :items="filteredSubscriptionList"
                                :footer-props="{'items-per-page-options': [5, 15, 25, 35, 99]}"
                                :items-per-page="99"
                                class="elevation-1"
                                :search="search"
                                :expanded.sync="expanded"
                                @click:row="expandRow"
                                :show-group-by="false">

                    <template v-slot:item.created="{ item }">{{formatDate(item.created)}}</template>
                    <template v-slot:item.updated="{ item }">{{formatDate(item.updated)}}</template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn text small color="error" v-if="hasOneSubscriptionActive(item) == true" @click="CancelSubscription(item)">Afbryd Abonnement</v-btn>
                    </template>

                    <template v-slot:item.open="{}">
                        <v-icon medium>mdi-arrow-expand-vertical</v-icon>
                    </template>

                    <template v-slot:item.status="{ item }">
                        <template v-if="item.status.status == 'Active'">
                            <span class="text-start" v-if="isActiveTrial(item.status)" style="color:#a61bc4; font-weight: bold;"> Aktiv trial </span>
                            <span class="text-start" v-else style="color:#77BC27; font-weight: bold;"> Aktivt ({{formatDate(item.nextChargeDate)}})</span>
                        </template>
                        <span style="color:#DD8800" v-else-if="isPendingCancelled(item.status)">Udløber {{ formatDate(item.nextChargeDate)}}</span>
                        <span style="color:#FF5B6C" v-else-if="isFullyCancelled(item.status)">Aflyst</span>
                        <span style="color:#E2CB2E" v-else-if="item.status.status == 'Pending'">Afventer abonnement</span>
                    </template>

                    <template v-slot:item.nextChargeDate="{ item }">
                        <span v-if="item.nextChargeDate == null"> - </span>
                        <span v-else-if="isPendingCancelled(item)" style="color:#DD8800" >Opsagt</span>
                        <span v-else-if="isFullyCancelled(item)" style="color:#FF5B6C" >Opsagt</span>
                        <span v-else>{{formatDate(item.nextChargeDate)}}</span>
                    </template>
                    <template v-slot:item.subscriptionFee="{ item }">{{item.subscriptionFee}},-</template>

                    <template align="center" justify="center" v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-card flat width="100%" style="margin:10px">
                                <v-card-title>
                                    Faktura'er for abonnement: {{item.id}}
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table :headers="invoiceHeaders"
                                                  :items="sortedInvoiceList"
                                                  :footer-props="{'items-per-page-options': [5, 15, 25, 35, 99]}"
                                                  :items-per-page="25"
                                                  class="elevation-1">
                                        <template v-slot:item.status="{ item }">
                                            <span style="color:#E2CB2E" v-if="item.status == 'Pending'">Afventer</span>
                                            <span style="color:#77BC27" v-if="item.status == 'Success'">Gennemført</span>
                                            <span style="color:#FF5B6C" v-if="item.status == 'Failed'">Fejlet</span>
                                            <span style="color:#DD8800" v-if="item.status == 'Cancelled'">Anulleret</span>
                                        </template>
                                        <template v-slot:item.created="{ item }">{{formatDate(item.created)}}</template>
                                        <template v-slot:item.processed="{ item }">{{formatDate(item.processed)}}</template>
                                        <template v-slot:item.feeExcludingVat="{ item }">{{item.feeExcludingVat}},-</template>
                                        <template v-slot:item.feeTaken="{ item }">{{item.feeTaken}},-</template>
                                    </v-data-table>
                                    
                                </v-card-text>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>

            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex';

    export default {
        name: 'Subscriptions',
        props: [],
        data: (instance) => {
            return {
                search: '',
                subscriptionHeaders: [
                    { text: 'Udvid', value: 'open', sortable: false, filterable: false },
                    { text: 'Navn', value: 'name', filterable: true },
                    { text: 'Email', value: 'email', filterable: true },
                    { text: 'Abonnements Id', value: 'id', sortable: true, filterable: true },
                    { text: 'Registreret - d.', value: 'created', sortable: true, filterable: false },
                    { text: 'Sidst Opdateret - d.', value: 'updated', sortable: true, filterable: false },
                    { 
                        text: 'Status', 
                        value: 'status', 
                        sortable: true, 
                        filterable: true,
                        sort: (a, b) => {
                            if (a === b && a === null) {
                                return 0;
                            }
                            if (a === null) {
                                return 1;
                            }
                            if (b === null) {
                                return -1;
                            }

                            switch (a.status) {
                                case "Active":
                                    if (b.status === "Active" && b.nextChargeDate) {
                                        if (!a.nextChargeDate) {
                                            return 1;
                                        }
                                        const aCharge = moment(a.nextChargeDate);
                                        const bCharge = moment(b.nextChargeDate);
                                        if (aCharge.isAfter(bCharge)) {
                                            return -1;
                                        }
                                        if (bCharge.isAfter(aCharge)) {
                                            return 1;
                                        }
                                        return 0;
                                    }
                                    return -1;
                                case "Pending":
                                    if (a.status === b.status) {
                                        return 0;
                                    }
                                    switch (b.status) {
                                        case "Active":
                                            return 1;
                                        case "Cancelled":
                                            return -1;
                                    }
                                    break;
                                case "Cancelled":
                                    if (a.status === b.status) {
                                        return 0;
                                    }
                                    return 1;
                            }
                            return 0;
                        },
                    },
                    { text: 'Pris', value: 'subscriptionFee', sortable: false, filterable: false },
                    { 
                        text: 'Udbyder', 
                        value: 'subscriptionProvider', 
                        sortable: true, 
                        filterable: true,
                    },
                    { text: 'Næste træk d.', value: 'nextChargeDate', sortable: true, filterable: false, },
                    { text: 'Actions', value: 'actions', sortable: false, filterable: false },
                ],
                invoiceHeaders: [
                    { text: 'Id', align: 'start', value: 'id', sortable: true, filterable: true },
                    { text: 'Registreret - d', value: 'created', sortable: true, filterable: true },
                    { text: 'Penge trukket - d.', value: 'processed', sortable: true, filterable: true },
                    { text: 'Status', value: 'status', sortable: true, filterable: true },
                    { text: 'Pris inkl. moms', value: 'feeTaken', sortable: false, filterable: false },
                    { text: 'Eksl. moms', value: 'feeExcludingVat', sortable: false, filterable: false },
                ],
                expanded: [],
                showOnlyActiveSubs: true
            };
        },
        mounted() {
            this.initialize();
        },
        computed: {
            filteredSubscriptionList() {
                var listToDisplay = [];
                if (this.showOnlyActiveSubs) {
                    for (var i = 0; i < this.subscriptions.length; i++) {
                        const sub = this.subscriptions[i];
                        if (sub.status === "Active" || this.isPendingCancelled(sub))
                            listToDisplay.push(sub);
                    }
                }
                else listToDisplay = this.subscriptions;
                return listToDisplay.map((item, idx) => ({
                    ...item,
                    status: {
                        status: item.status,
                        nextChargeDate: item.nextChargeDate
                    }
                }));
            },
            sortedInvoiceList() {
                var listToDisplay = [];
                if (this.expanded.length > 0) {
                    for (var i = 0; i < this.invoices.length; i++) {
                        if (this.invoices[i].subscriptionId === this.expanded[0].id)
                            listToDisplay.push(this.invoices[i]);
                    }
                }

                return listToDisplay.sort((a, b) => {
                    if (a.created === b.created) {
                        return 0;
                    }
                    return moment(a.created).isSameOrAfter(b.created) ? -1 : 1;
                });
            },
            ...mapState({
                loading: state => state.loading,
                loggedInUser: (state) => state.auth.user,
                invoices: state => state.subscriptions.invoices.map(i => ({
                    ...i,
                    feeExcludingVat: i.feeTaken * 0.75
                })),
                subscriptions: state => state.subscriptions.subscriptions.map(s => ({
                    ...s,
                    feeExcludingVat: s.subscriptionFee * 0.75
                }))
            })
        },
        methods: {
            initialize() {
                if (!this.loading) {
                    this.$store.commit('setLoading', true);
                    this.$store.dispatch('GetSubscriptions').then(() => this.$store.commit('setLoading', false));
                   // this.$store.dispatch('GetInvoices').then(() => this.$store.commit('setLoading', false));
                }
            },
            hasOneSubscriptionActive(item) {
                if (item.status.status == 'Active' && item.subscriptionProvider == "Quickpay")
                    return true;
                return false;
            },
            CancelSubscription(item) {
                if (!this.loading) {
                    this.$store.commit("setLoading", true);
                    this.$store
                        .dispatch("CancelSubscription_Admin", item)
                        .finally(() => {
                             this.$store.commit("setLoading", false);
                        });
                }
            },
            expandRow(item) {
                if (!this.loading) {
                    this.expanded = item === this.expanded[0] ? [] : [item]
                }
            },
            formatDate(date) {
                if (!date) {
                    return '-';
                }
                return moment(date).format('DD/MM/YYYY');
            },
            isFullyCancelled(item) {
                if (item.status !== 'Cancelled') {
                    return false;
                }

                if (!item.nextChargeDate) {
                    return true;
                }

                return moment(item.nextChargeDate).isBefore(moment());
            },
            isPendingCancelled(item) {
                if (item.status !== 'Cancelled') {
                    return false;
                }

                if (!item.nextChargeDate) {
                    return false;
                }

                return moment(item.nextChargeDate).isAfter(moment());
            },
            isActiveTrial(item) {
                const nextPayment = moment(item.nextChargeDate);
                return (nextPayment.diff(moment(), 'days')) > 31;
            }
        }
    }
</script>

<style>
</style>