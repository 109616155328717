var displayItems = [
    { text: 'Kvikfix', code: 'quickfix', shortCode: 'QF', color: 'primary', categoryType: 'main' },
    { text: 'Sanser', code: 'senses', shortCode: 'Sans', color: 'primary', categoryType: 'main' },
    { text: 'Fysik', code: 'fysical', shortCode: 'Fysi', color: 'primary', categoryType: 'main' },
    { text: 'Diæt', code: 'diet', shortCode: 'Diet', color: 'primary', categoryType: 'main' },
    { text: 'Krop', code: 'body', shortCode: 'Body', color: 'primary', categoryType: 'main' },
    { text: 'Mindset', code: 'mindset', shortCode: 'Mind', color: 'primary', categoryType: 'main' },

    { text: 'Zoneterapi', code: 'zonetherapy', shortCode: 'Zoner', color: 'secondary', categoryType: 'sub' },
    { text: 'Manuel Behandling', code: 'manualTreatment', shortCode: 'Manu', color: 'secondary', categoryType: 'sub' },
    { text: 'Akupunktur Punkter', code: 'acupuncturePoints', shortCode: 'Aku', color: 'secondary', categoryType: 'sub' },
    { text: 'Meridian Strygninger', code: 'medianMassages', shortCode: 'Meridian', color: 'secondary', categoryType: 'sub' },
    { text: 'Ledmobilisering med Flossband', code: 'jointMobilizationWithFlossband', shortCode: 'Led', color: 'secondary', categoryType: 'sub' },
    { text: 'Udspænding med Bolde', code: 'detensioningWithBalls', shortCode: 'Udsp. Bolde', color: 'secondary', categoryType: 'sub' },
    { text: 'Foamroller', code: 'foamRoller', shortCode: 'Foam', color: 'secondary', categoryType: 'sub' },
    { text: 'Grønne Bolde', code: 'greenBalls', shortCode: 'GrBold', color: 'secondary', categoryType: 'sub' },
    { text: 'Træning af Region', code: 'regionalTraining', shortCode: 'Region', color: 'secondary', categoryType: 'sub' },
    { text: 'Muskel System', code: 'muscleSystem', shortCode: 'Muskel', color: 'secondary', categoryType: 'sub' },
    { text: 'Neuromuskulær System', code: 'neuromuscularSystem', shortCode: 'Neuro', color: 'secondary', categoryType: 'sub' },
    { text: 'Funktionelle Øvelser', code: 'functionalExercises', shortCode: 'Funk', color: 'secondary', categoryType: 'sub' },
    { text: 'Proprioception', code: 'proprioception', shortCode: 'Proprio', color: 'secondary', categoryType: 'sub' },
    { text: 'Bevægelighed', code: 'agility', shortCode: 'Agil', color: 'secondary', categoryType: 'sub' },
    { text: 'Hormonsystem', code: 'hormoneSystem', shortCode: 'Hormon', color: 'secondary', categoryType: 'sub' },
    { text: 'Lymfesystem', code: 'lymphaticSystem', shortCode: 'Immun', color: 'secondary', categoryType: 'sub' },
    { text: 'Knoglesystem', code: 'boneSystem', shortCode: 'Skelet', color: 'secondary', categoryType: 'sub' },
    { text: 'Nervesystem', code: 'nervousSystem', shortCode: 'Nerve', color: 'secondary', categoryType: 'sub' },
    { text: 'Fordøjelsessystem', code: 'digestionSystem', shortCode: 'Mave', color: 'secondary', categoryType: 'sub' },
    { text: 'Luftvejssystem', code: 'airwaySystem', shortCode: 'Lunger', color: 'secondary', categoryType: 'sub' },
    { text: 'Kredsløbssystem', code: 'circulatorySystem', shortCode: 'Blod', color: 'secondary', categoryType: 'sub' },
    { text: 'Føle', code: 'feeling', shortCode: 'Føl', color: 'secondary', categoryType: 'sub' },
    { text: 'Høre', code: 'hearing', shortCode: 'Høre', color: 'secondary', categoryType: 'sub' },
    { text: 'Syn', code: 'seeing', shortCode: 'Se', color: 'secondary', categoryType: 'sub' },
    { text: 'Lugte/Smage', code: 'smeelingAndTasting', shortCode: 'smag', color: 'secondary', categoryType: 'sub' },
    { text: 'Balance Indre Øre', code: 'innerEarBalance', shortCode: 'balance', color: 'secondary', categoryType: 'sub' },
    { text: 'Ernæring', code: 'nutrition', shortCode: 'Næring', color: 'secondary', categoryType: 'sub' },
    { text: 'Åndedræt', code: 'breathing', shortCode: 'Ånde', color: 'secondary', categoryType: 'sub' },
    { text: 'Rygning', code: 'smoking', shortCode: 'Rygn', color: 'secondary', categoryType: 'sub' },
    { text: 'Alkohol', code: 'alcohol', shortCode: 'Alk', color: 'secondary', categoryType: 'sub' },
    { text: 'Væske', code: 'hydration', shortCode: 'Væsk', color: 'secondary', categoryType: 'sub' },
    { text: 'KOST-model', code: 'kOSTModel', shortCode: 'KOST', color: 'secondary', categoryType: 'sub' },
    { text: 'Bindevævsflow', code: 'connectiveTissueFlow', shortCode: 'Bind', color: 'secondary', categoryType: 'sub' },
    { text: 'Meditation', code: 'meditation', shortCode: 'Med', color: 'secondary', categoryType: 'sub' },
    { text: 'BARS', code: 'bARS', shortCode: 'BARS', color: 'secondary', categoryType: 'sub' },
    { text: 'Psykologi', code: 'psykology', shortCode: 'psyk', color: 'secondary', categoryType: 'sub' },
    { text: 'Adfærdsmodeller', code: 'behaviorModelling', shortCode: 'Adfærd', color: 'secondary', categoryType: 'sub' },
    { text: 'Smertemodeller', code: 'painModelling', shortCode: 'Smerte', color: 'secondary', categoryType: 'sub' },

    { text: 'Hele Kroppen', code: 'wholeBody', shortCode: 'HelKrop', color: 'red', categoryType: 'region' },
    { text: 'Hoved/Nakke', code: 'headAndNeck', shortCode: 'Hov/Nak', color: 'red', categoryType: 'region' },
    { text: 'Nakke/Skulder', code: 'neckAndShoulder', shortCode: 'Nak/Sku', color: 'red', categoryType: 'region' },
    { text: 'Ryg', code: 'back', shortCode: 'Ryg', color: 'red', categoryType: 'region' },
    { text: 'Lænd/Bækken', code: 'lowerBack', shortCode: 'Mind', color: 'red', categoryType: 'region' },
    { text: 'Hofte', code: 'hip', shortCode: 'Hof', color: 'red', categoryType: 'region' },
    { text: 'Knæ', code: 'knee', shortCode: 'Knæ', color: 'red', categoryType: 'region' },
    { text: 'Ankel/Fod', code: 'ancleAndFoot', shortCode: 'Fod', color: 'red', categoryType: 'region' },
    { text: 'Arm/Albue', code: 'armAndElbow', shortCode: 'Arm', color: 'red', categoryType: 'region' },
    { text: 'Håndled/Hænder', code: 'wristAndHands', shortCode: 'Hånd', color: 'red', categoryType: 'region' },
]
var editedTags = {
    exerciseId: -1,
    //main categories
    quickfix: false,
    senses: false,
    fysical: false,
    diet: false,
    body: false,
    mindset: false,
    //subcategories
    zonetherapy: false,
    manualTreatment: false,
    acupuncturePoints: false,
    medianMassages: false,
    jointMobilizationWithFlossband: false,
    detensioningWithBalls: false,
    foamRoller: false,
    greenBalls: false,
    regionalTraining: false,
    muscleSystem: false,
    neuromuscularSystem: false,
    functionalExercises: false,
    proprioception: false,
    agility: false,
    hormoneSystem: false,
    lymphaticSystem: false,
    boneSystem: false,
    nervousSystem: false,
    digestionSystem: false,
    airwaySystem: false,
    circulatorySystem: false,
    feeling: false,
    hearing: false,
    seeing: false,
    smeelingAndTasting: false,
    innerEarBalance: false,
    nutrition: false,
    breathing: false,
    smoking: false,
    alcohol: false,
    hydration: false,
    kOSTModel: false,
    connectiveTissueFlow: false,
    meditation: false,
    bARS: false,
    psykology: false,
    behaviorModelling: false,
    painModelling: false,
    //Regions
    wholeBody: false,
    headAndNeck: false,
    neckAndShoulder: false,
    back: false,
    lowerBack: false,
    hip: false,
    knee: false,
    ancleAndFoot: false,
    armAndElbow: false,
    wristAndHands: false,
    id: -1
}

function setCategoriesForEdit(editedItem, exercise) {
    var selected = [];

    editedItem.id = exercise.id;
    editedItem.tags.exerciseID = exercise.tags.exerciseID;
    editedItem.tags.id = exercise.tags.id;

    editedItem.tags.quickfix = exercise.tags.quickfix;
    editedItem.tags.senses = exercise.tags.senses;
    editedItem.tags.fysical = exercise.tags.fysical;
    editedItem.tags.diet = exercise.tags.diet;
    editedItem.tags.body = exercise.tags.body;
    editedItem.tags.mindset = exercise.tags.mindset;

    editedItem.tags.zonetherapy = exercise.tags.zonetherapy;
    editedItem.tags.manualTreatment = exercise.tags.manualTreatment;
    editedItem.tags.acupuncturePoints = exercise.tags.acupuncturePoints;
    editedItem.tags.medianMassages = exercise.tags.medianMassages;
    editedItem.tags.jointMobilizationWithFlossband = exercise.tags.jointMobilizationWithFlossband;
    editedItem.tags.detensioningWithBalls = exercise.tags.detensioningWithBalls;
    editedItem.tags.foamRoller = exercise.tags.foamRoller;
    editedItem.tags.greenBalls = exercise.tags.greenBalls;
    editedItem.tags.regionalTraining = exercise.tags.regionalTraining;
    editedItem.tags.muscleSystem = exercise.tags.muscleSystem;
    editedItem.tags.neuromuscularSystem = exercise.tags.neuromuscularSystem;
    editedItem.tags.functionalExercises = exercise.tags.functionalExercises;
    editedItem.tags.proprioception = exercise.tags.proprioception;
    editedItem.tags.agility = exercise.tags.agility;
    editedItem.tags.hormoneSystem = exercise.tags.hormoneSystem;
    editedItem.tags.lymphaticSystem = exercise.tags.lymphaticSystem;
    editedItem.tags.boneSystem = exercise.tags.boneSystem;
    editedItem.tags.nervousSystem = exercise.tags.nervousSystem;
    editedItem.tags.digestionSystem = exercise.tags.digestionSystem;
    editedItem.tags.airwaySystem = exercise.tags.airwaySystem;
    editedItem.tags.circulatorySystem = exercise.tags.circulatorySystem;
    editedItem.tags.feeling = exercise.tags.feeling;
    editedItem.tags.hearing = exercise.tags.hearing;
    editedItem.tags.seeing = exercise.tags.seeing;
    editedItem.tags.smeelingAndTasting = exercise.tags.smeelingAndTasting;
    editedItem.tags.innerEarBalance = exercise.tags.innerEarBalance;
    editedItem.tags.nutrition = exercise.tags.nutrition;
    editedItem.tags.breathing = exercise.tags.breathing;
    editedItem.tags.smoking = exercise.tags.smoking;
    editedItem.tags.alcohol = exercise.tags.alcohol;
    editedItem.tags.hydration = exercise.tags.hydration;
    editedItem.tags.kOSTModel = exercise.tags.kOSTModel;
    editedItem.tags.connectiveTissueFlow = exercise.tags.connectiveTissueFlow;
    editedItem.tags.meditation = exercise.tags.meditation;
    editedItem.tags.bARS = exercise.tags.bARS;
    editedItem.tags.psykology = exercise.tags.psykology;
    editedItem.tags.behaviorModelling = exercise.tags.behaviorModelling;
    editedItem.tags.painModelling = exercise.tags.painModelling;

    editedItem.tags.wholeBody = exercise.tags.wholeBody;
    editedItem.tags.headAndNeck = exercise.tags.headAndNeck;
    editedItem.tags.neckAndShoulder = exercise.tags.neckAndShoulder;
    editedItem.tags.back = exercise.tags.back;
    editedItem.tags.lowerBack = exercise.tags.lowerBack;
    editedItem.tags.hip = exercise.tags.hip;
    editedItem.tags.knee = exercise.tags.knee;
    editedItem.tags.ancleAndFoot = exercise.tags.ancleAndFoot;
    editedItem.tags.armAndElbow = exercise.tags.armAndElbow;
    editedItem.tags.wristAndHands = exercise.tags.wristAndHands;


    if (editedItem.tags.quickfix) selected.push(this.displayItems[0]);
    if (editedItem.tags.senses) selected.push(this.displayItems[1]);
    if (editedItem.tags.fysical) selected.push(this.displayItems[2]);
    if (editedItem.tags.diet) selected.push(this.displayItems[3]);
    if (editedItem.tags.body) selected.push(this.displayItems[4]);
    if (editedItem.tags.mindset) selected.push(this.displayItems[5]);

    if (editedItem.tags.zonetherapy) selected.push(this.displayItems[6]);
    if (editedItem.tags.manualTreatment) selected.push(this.displayItems[7]);
    if (editedItem.tags.acupuncturePoints) selected.push(this.displayItems[8]);
    if (editedItem.tags.medianMassages) selected.push(this.displayItems[9]);
    if (editedItem.tags.jointMobilizationWithFlossband) selected.push(this.displayItems[10]);
    if (editedItem.tags.detensioningWithBalls) selected.push(this.displayItems[11]);
    if (editedItem.tags.foamRoller) selected.push(this.displayItems[12]);
    if (editedItem.tags.greenBalls) selected.push(this.displayItems[13]);
    if (editedItem.tags.regionalTraining) selected.push(this.displayItems[14]);
    if (editedItem.tags.muscleSystem) selected.push(this.displayItems[15]);
    if (editedItem.tags.neuromuscularSystem) selected.push(this.displayItems[16]);
    if (editedItem.tags.functionalExercises) selected.push(this.displayItems[17]);
    if (editedItem.tags.proprioception) selected.push(this.displayItems[18]);
    if (editedItem.tags.agility) selected.push(this.displayItems[19]);
    if (editedItem.tags.hormoneSystem) selected.push(this.displayItems[20]);
    if (editedItem.tags.lymphaticSystem) selected.push(this.displayItems[21]);
    if (editedItem.tags.boneSystem) selected.push(this.displayItems[22]);
    if (editedItem.tags.nervousSystem) selected.push(this.displayItems[23]);
    if (editedItem.tags.digestionSystem) selected.push(this.displayItems[24]);
    if (editedItem.tags.airwaySystem) selected.push(this.displayItems[25]);
    if (editedItem.tags.circulatorySystem) selected.push(this.displayItems[26]);
    if (editedItem.tags.feeling) selected.push(this.displayItems[27]);
    if (editedItem.tags.hearing) selected.push(this.displayItems[28]);
    if (editedItem.tags.seeing) selected.push(this.displayItems[29]);
    if (editedItem.tags.smeelingAndTasting) selected.push(this.displayItems[30]);
    if (editedItem.tags.innerEarBalance) selected.push(this.displayItems[31]);
    if (editedItem.tags.nutrition) selected.push(this.displayItems[32]);
    if (editedItem.tags.breathing) selected.push(this.displayItems[33]);
    if (editedItem.tags.smoking) selected.push(this.displayItems[34]);

    if (editedItem.tags.alcohol) selected.push(this.displayItems[35]);
    if (editedItem.tags.hydration) selected.push(this.displayItems[36]);
    if (editedItem.tags.kOSTModel) selected.push(this.displayItems[37]);
    if (editedItem.tags.connectiveTissueFlow) selected.push(this.displayItems[38]);
    if (editedItem.tags.meditation) selected.push(this.displayItems[39]);
    if (editedItem.tags.bARS) selected.push(this.displayItems[40]);
    if (editedItem.tags.psykology) selected.push(this.displayItems[41]);
    if (editedItem.tags.behaviorModelling) selected.push(this.displayItems[42]);
    if (editedItem.tags.painModelling) selected.push(this.displayItems[43]);

    if (editedItem.tags.wholeBody) selected.push(this.displayItems[44]);
    if (editedItem.tags.headAndNeck) selected.push(this.displayItems[45]);
    if (editedItem.tags.neckAndShoulder) selected.push(this.displayItems[46]);
    if (editedItem.tags.back) selected.push(this.displayItems[47]);
    if (editedItem.tags.lowerBack) selected.push(this.displayItems[48]);
    if (editedItem.tags.hip) selected.push(this.displayItems[49]);
    if (editedItem.tags.knee) selected.push(this.displayItems[50]);
    if (editedItem.tags.ancleAndFoot) selected.push(this.displayItems[51]);
    if (editedItem.tags.armAndElbow) selected.push(this.displayItems[52]);
    if (editedItem.tags.wristAndHands) selected.push(this.displayItems[53]);

    return { editedItem: editedItem, selected: selected };
}

function doesExerciseHaveAMainCategory(editedItem) {
    var hasMainCategory = false;
    if (editedItem.tags.quickfix || editedItem.tags.senses || editedItem.tags.fysical || editedItem.tags.diet || editedItem.tags.body || editedItem.tags.mindset)
        hasMainCategory = true;
    return hasMainCategory;
}

function setCategoriesForAPI(editedItem, selections, idx, exercise, _editedTags) {
    for (var i = 0; i < selections.length; i++) {
        switch (selections[i].code) {
            case 'quickfix':
                _editedTags.quickfix = true;
                break;
            case 'senses':
                _editedTags.senses = true;
                break;
            case 'fysical':
                _editedTags.fysical = true;
                break;
            case 'diet':
                _editedTags.diet = true;
                break;
            case 'body':
                _editedTags.body = true;
                break;
            case 'mindset':
                _editedTags.mindset = true;
                break;

            //subcategories
            case 'zonetherapy':
                _editedTags.zonetherapy = true;
                break;
            case 'manualTreatment':
                _editedTags.manualTreatment = true;
                break;
            case 'acupuncturePoints':
                _editedTags.acupuncturePoints = true;
                break;
            case 'medianMassages':
                _editedTags.medianMassages = true;
                break;
            case 'jointMobilizationWithFlossband':
                _editedTags.jointMobilizationWithFlossband = true;
                break;
            case 'detensioningWithBalls':
                _editedTags.detensioningWithBalls = true;
                break;
            case 'foamRoller':
                _editedTags.foamRoller = true;
                break;
            case 'greenBalls':
                _editedTags.greenBalls = true;
                break;
            case 'regionalTraining':
                _editedTags.regionalTraining = true;
                break;
            case 'muscleSystem':
                _editedTags.muscleSystem = true;
                break;
            case 'neuromuscularSystem':
                _editedTags.neuromuscularSystem = true;
                break;
            case 'functionalExercises':
                _editedTags.functionalExercises = true;
                break;
            case 'proprioception':
                _editedTags.proprioception = true;
                break;
            case 'agility':
                _editedTags.agility = true;
                break;
            case 'hormoneSystem':
                _editedTags.hormoneSystem = true;
                break;
            case 'lymphaticSystem':
                _editedTags.lymphaticSystem = true;
                break;
            case 'boneSystem':
                _editedTags.boneSystem = true;
                break;
            case 'nervousSystem':
                _editedTags.nervousSystem = true;
                break;
            case 'digestionSystem':
                _editedTags.digestionSystem = true;
                break;
            case 'airwaySystem':
                _editedTags.airwaySystem = true;
                break;
            case 'circulatorySystem':
                _editedTags.circulatorySystem = true;
                break;
            case 'feeling':
                _editedTags.feeling = true;
                break;
            case 'hearing':
                _editedTags.hearing = true;
                break;
            case 'seeing':
                _editedTags.seeing = true;
                break;
            case 'smeelingAndTasting':
                _editedTags.smeelingAndTasting = true;
                break;
            case 'innerEarBalance':
                _editedTags.innerEarBalance = true;
                break;
            case 'nutrition':
                _editedTags.nutrition = true;
                break;
            case 'breathing':
                _editedTags.breathing = true;
                break;
            case 'smoking':
                _editedTags.smoking = true;
                break;
            case 'alcohol':
                _editedTags.alcohol = true;
                break;
            case 'hydration':
                _editedTags.hydration = true;
                break;
            case 'kOSTModel':
                _editedTags.kOSTModel = true;
                break;
            case 'connectiveTissueFlow':
                _editedTags.connectiveTissueFlow = true;
                break;
            case 'meditation':
                _editedTags.meditation = true;
                break;
            case 'bARS':
                _editedTags.bARS = true;
                break;
            case 'psykology':
                _editedTags.psykology = true;
                break;
            case 'behaviorModelling':
                _editedTags.behaviorModelling = true;
                break;
            case 'painModelling':
                _editedTags.painModelling = true;
                break;

            //Regions
            case 'wholeBody': 
                _editedTags.wholeBody = true;
                break;
            case 'headAndNeck':
                _editedTags.headAndNeck = true;
                break;
            case 'neckAndShoulder':
                _editedTags.neckAndShoulder = true;
                break;
            case 'back':
                _editedTags.back = true;
                break;
            case 'lowerBack':
                _editedTags.lowerBack = true;
                break;
            case 'hip':
                _editedTags.hip = true;
                break;
            case 'knee':
                _editedTags.knee = true;
                break;
            case 'ancleAndFoot':
                _editedTags.ancleAndFoot = true;
                break;
            case 'armAndElbow':
                _editedTags.armAndElbow = true;
                break;
            case 'wristAndHands':
                _editedTags.wristAndHands = true;
                break;
        }
    }
    if (idx > 0) {
        editedItem.id = exercise.id;
        _editedTags.id = exercise.tags.id;
        _editedTags.exerciseID = exercise.tags.exerciseID;
    }
    editedItem.tags = _editedTags;
    return editedItem;
}

module.exports = {
    displayItems: displayItems,
    editedTags: editedTags,
    setCategoriesForEdit: setCategoriesForEdit,
    setCategoriesForAPI: setCategoriesForAPI,
    doesExerciseHaveAMainCategory: doesExerciseHaveAMainCategory

};