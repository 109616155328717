<template>
    <v-layout child-flex>
        <v-data-table ref="tbl" single-select :headers="internalHeadersArr" :hide-default-footer="true" :items="contents"
            :search="search" :disable-pagination="disable_Pagination">
            <template v-slot:body="props">
                <draggable :list="contents" handle=".handle" tag="tbody">
                    <tr v-for="(obj, i) in props.items" :key="i" :class="[isSelected(obj) && 'row-selected']"
                        @click="rowClicked(obj)">
                        <td v-if="showEditColumn" style="width: 24px;">
                            <v-icon medium :color="selectedRow === obj ? 'white' : 'primary'" @click.stop="editRow(obj)">mdi-pencil</v-icon>
                        </td>
                        <td v-for="(h, j) in headers" :style="tdStyling" v-bind:key="i.toString() + '.' + j.toString()">
                            <div v-if="h.value != 'hasPaid'">
                                {{ obj[h.value] }}
                            </div>
                            <div v-else>
                                <span v-if="obj.isLocked" class="is-forced-public">
                                    Offentlig
                                </span>
                                <template v-else>
                                    <v-checkbox v-if="!obj.isFree" v-model="obj.hasPaid" @click.stop=""
                                                color="blue darken-3" class="ma-0 pa-0" hide-details="true" />
                                    <span v-else>Gratis</span>
                                </template>
                            </div>
                        </td>
                        <td v-if="allowMoveElements" style="max-width: 115px">
                            <div style=" display: flex; flex-direction: column; align-items: end;">
                                <v-icon x-large class="handle" style="cursor: pointer">
                                    mdi-drag-horizontal-variant
                                </v-icon>
                            </div>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>
    </v-layout>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "SimpleList",
    props: [
        "contents",
        "headers",
        "loading",
        "search",
        "clearTrigger",
        "allowMoveElements",
        "disablePagination",
        "selectNext",
        "tdMaxWidth",
        "selectedRow",
        "showEditColumn",
    ],
    components: {
        draggable,
    },
    data: (instance) => ({
        internalHeadersArr: [],

        row: instance.selectedRow,
    }),
    computed: {
        disable_Pagination() {
            if (
                this.disablePagination !== true &&
                this.disablePagination !== false
            ) {
                return true;
            }
            return this.disablePagination;
        },
        tdStyling() {
            if (this.tdMaxWidth != null) {
                return {
                    maxWidth: this.tdMaxWidth
                }
            }

            return {};
        },
    },
    methods: {
        isSelected(row) {
            const selectedRow = this.row;
            if (!row) {
                throw 'Row argument missing';
            }
            if (!selectedRow) {
                return false;
            }
            if (selectedRow === row) {
                return true;
            }

            return selectedRow.id === row.id;
        },
        rowClicked(item) {
            this.toggleSelection(item);
        },
        selectFirstRow() {
            const tbl = this.$refs.tbl;
            if (tbl.selectableItems.length > 0) {
                var firstSelectable = tbl.selectableItems[0];
                if (
                    firstSelectable?.id > 0 &&
                    firstSelectable?.id !== this.row?.id
                ) {
                    this.row = tbl.selectableItems[0];
                    this.toggleSelection(this.row);
                    return;
                }

                if (tbl.selectableItems.length > 1) {
                    this.row = tbl.selectableItems[1];
                    this.toggleSelection(this.row);
                    return;
                }
            }
            this.row = null;
            this.toggleSelection(this.row);
        },
        toggleSelection(item) {
            if (item.isLocked) {
                if (this.row === null) {
                    return;
                }
                this.row = null;
            } else {
                if (this.row === item) {
                    this.row = null;
                } else {
                    this.row = item;
                }
            }
            this.$emit("selectedRowChange", this.row);
        },
		editRow(val) {
			this.$emit("editRow", val)
		}
    },
    watch: {
        selectNext() {
            this.selectFirstRow();
        },
    },
    mounted() {
        this.internalHeadersArr = [...this.headers];
        if (this.allowMoveElements === true) {
            this.internalHeadersArr.push({
                text: "Flyt op/ned",
                align: "end",
                value: null,
                sortable: false,
            });
        }
		if (this.showEditColumn) {
			this.internalHeadersArr.unshift({ value: null, sortable: false })
		}
    },
};
</script>

<style scoped lang="scss">
.row-selected {
    background-color: #738ea6 !important;
    color: white !important;
}

.is-forced-public {
    color: green;
}
</style>
