<template>
    <div class="UserStats" style="padding:10px">
        <v-card tile>
            <v-container fill-height fill-width>
                <v-row>
                    <v-col>
                        <v-row class="hidden-sm-and-down">
                            <!-- small screens -->
                            <v-col cols="12" sm="7" md="7">
                                <!--<v-col  style="height: 48vh;" v-if="previewCollection != null">
                <BarChart class="small" :chart-data="previewCollection" :options="options" :styles="AllStyles"></BarChart>
            </v-col>-->
                                <v-col style="height: 48vh;">
                                    <BarChart class="small" :chart-data="datacollection" :options="options" :styles="AllStyles"></BarChart>
                                </v-col>
                            </v-col>
                            <v-col cols="12" sm="5" md="5">
                                <StatControl v-if="screeningResult.screeningValues != null"
                                             class="statControl"
                                             :screeningValues="screeningResult.screeningValues"
                                             @preview="previewScreeningDataChanges"
                                             @save="SaveScreeningDataChanges">

                                </StatControl>
                            </v-col>
                        </v-row>
                        <v-row class="hidden-md-and-up">
                            <!-- medium screens -->
                            <v-col cols="12" sm="12" md="12">
                                <StatControl v-if="screeningResult.screeningValues != null"
                                             style="padding:20px; padding-top:20px"
                                             :screeningValues="screeningResult.screeningValues"
                                             @preview="previewScreeningDataChanges"
                                             @save="SaveScreeningDataChanges">

                                </StatControl>
                            </v-col>
                            <v-col v-if="previewCollection != null" cols="12" sm="10" md="12" style="height: 48vh; width:200px">
                                <BarChart class="small" :chart-data="previewCollection" :options="options" :styles="AllStylesSmall"></BarChart>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" style="height: 48vh; width:200px">
                                <BarChart class="small" :chart-data="datacollection" :options="options" :styles="AllStylesSmall"></BarChart>
                            </v-col>
                        </v-row>
                        <v-row class="hidden-md-and-up">
                            <v-col cols="12" md="4" sm="12" style="min-height: 20vh;">
                                <BarChart class="small" :chart-data="mainCollection" :options="options" :styles="MainStyles"></BarChart>
                            </v-col>
                            <v-col cols="12" md="5" sm="12" style="min-height: 20vh;">
                                <BarChart class="small" :chart-data="subCollection" :options="options" :styles="SubStyles"></BarChart>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" style="min-height: 20vh;">
                                <BarChart class="small" :chart-data="regionCollection" :options="options" :styles="RegionStyles"></BarChart>
                            </v-col>
                        </v-row>
                        <v-row class="hidden-sm-and-down" style="min-height: 33vh;">
                            <v-col cols="12" md="4" sm="12">
                                <BarChart class="small" :chart-data="mainCollection" :options="options" :styles="MainStyles"></BarChart>
                            </v-col>
                            <v-col cols="12" md="5" sm="12">
                                <BarChart class="small" :chart-data="subCollection" :options="options" :styles="SubStyles"></BarChart>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <BarChart class="small" :chart-data="regionCollection" :options="options" :styles="RegionStyles"></BarChart>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import BarChart from '../components/BarChart.vue'
    import StatControl from '../components/StatControl.vue'
    import connectionMapHelper from '../helper/StatControlChartMapping.js'

    export default {
        name: "UserStats",
        props: ['userID'],
        data: () => ({
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: false
                        }
                    }]
                },
            },
            componentValues: [
                //{ title: "QuickFix",    value: 0, modifier: 0, weight: 1.0 },
                //{ title: "Senses",      value: 0, modifier: 0, weight: 1.0 },
                //{ title: "Fysical",     value: 0, modifier: 0, weight: 1.0 },
                //{ title: "Diet",        value: 0, modifier: 0, weight: 1.0 },
                //{ title: "Body",        value: 0, modifier: 0, weight: 1.0 },
            ],
            labelData: [],
            datacollection: null,
            categoryCollection: {
                label: 'Kategorier',
                backgroundColor: '#88FF88',
                data: []
            },
            previewCollection: {
                label: 'Preview',
                backgroundColor: '#444444',
                data: [],
            },
            mainCollection: null,
            subCollection: null,
            regionCollection: null,
            screeningResult: [],
            previewScreeningresult: null,
            uID: -1
        }),
        computed: {
            componentValue() {
                return {
                    title: '<Kategori navn>',
                    value: 5,
                    modifier: 0,
                    weight: 1,
                }
            },
            AllStyles() {
                return {
                    //left: `${-5.5}vw`,
                    height: `${100}%`,
                    width: `${100}%`,
                    position: 'relative'

                }
            },
            AllStylesSmall() {
                return {
                    left: `${-2.5}vw`,
                    height: `${100}%`,
                    width: `${100}%`,
                    position: 'relative'

                }
            },
            MainStyles() {
                return {
                    //left: `${-2}vw`,
                    height: `${80}%`,
                    width: `${90}%`,
                    position: 'relative'
                }
            },
            SubStyles() {
                return {
                    //left: `${-3}vw`,
                    height: `${80}%`,
                    width: `${90}%`,
                    position: 'relative'
                }
            },
            RegionStyles() {
                return {
                    //left: `${-1.5}vw`,
                    height: `${80}%`,
                    width: `${90}%`,
                    position: 'relative'
                }
            }
        },
        components: {
            BarChart: BarChart,
            StatControl: StatControl
        },
        created() {
            connectionMapHelper.Initialize();
        },
        mounted() {
            this.uID = this.$props.userID;
            this.getScreeningData();
        },
        methods: {
            fillData() {
                if (this.previewScreeningresult != null) {
                    this.previewCollection.data = connectionMapHelper.GenerateLabelData(this.previewScreeningresult.screeningValues, connectionMapHelper.GetLabelCodes());
                }
                if (this.screeningResult != null) {
                    this.categoryCollection.data = connectionMapHelper.GenerateLabelData(this.screeningResult.screeningValues, connectionMapHelper.GetLabelCodes());
                }

                this.datacollection = {
                    labels: connectionMapHelper.GetLabels(),
                    datasets: [
                        this.categoryCollection,
                        this.previewCollection
                    ]
                },
                this.mainCollection = {
                    labels: connectionMapHelper.mainLabels,
                    datasets: [
                        {
                            label: 'Hovedkategorier',
                            backgroundColor: '#FF8888',
                            data: connectionMapHelper.GenerateLabelData(this.screeningResult.screeningValues, connectionMapHelper.mainLabelCodes),
                        }
                    ]
                },
                this.subCollection = {
                    labels: connectionMapHelper.subLabels,
                    datasets: [
                        {
                            label: 'Underkategorier',
                            backgroundColor: '#8888FF',
                            data: connectionMapHelper.GenerateLabelData(this.screeningResult.screeningValues, connectionMapHelper.subLabelCodes),
                        }
                    ]
                },
                this.regionCollection = {
                    labels: connectionMapHelper.regionLabels,
                    datasets: [
                        {
                            label: 'Regioner',
                            backgroundColor: '#444444',
                            data: connectionMapHelper.GenerateLabelData(this.screeningResult.screeningValues, connectionMapHelper.regionLabelCodes),
                        }
                    ]
                }
               
            },
            weightChanged(value) {
                console.log("value updated: ");
                console.log(value);
                this.getScreeningData();
            },
            getScreeningData() {
                if (this.userID !== undefined) {
                    this.$store.dispatch('GetUserLatestScreeningData', this.userID)
                        .then(_screeningResult => {
                            if (_screeningResult != null) {
                                _screeningResult.screeningValues = connectionMapHelper.AddDisplayLabelsToData(_screeningResult.screeningValues, connectionMapHelper.GetLabelCodes(), connectionMapHelper.GetLabels());
                                this.screeningResult = _screeningResult;
                                //this.previewScreeningresult = _screeningResult;
                                this.fillData();
                            } else {
                                console.log(_screeningResult);
                            }
                        })
                        .catch(console.log("some error occured getting the latest screening result"));
                }
            },
            previewScreeningDataChanges(screeningValues) {
                //var previewResult = this.screeningResult;
                //previewResult.screeningValues = screeningValues;
                //console.log("preview");
                //console.log(previewResult);
                this.$store.dispatch('PreviewScreeningUpdate', { userID: this.userID, screeningResult: screeningValues })
                    .then(_screeningResult => {
                        if (_screeningResult != null) {
                            _screeningResult.screeningValues = connectionMapHelper.AddDisplayLabelsToData(_screeningResult.screeningValues, connectionMapHelper.GetLabelCodes(), connectionMapHelper.GetLabels());

                            this.previewScreeningresult = _screeningResult;
                            this.fillData();
                        } else {
                            console.log(_screeningResult);
                        }
                    })
            },
            SaveScreeningDataChanges(screeningValues) {
                this.$store.dispatch('SaveScreeningUpdate', { userID: this.userID, screeningResult: screeningValues })
                    .then(_screeningResult => {
                        if (_screeningResult != null) {
                            _screeningResult.screeningValues = connectionMapHelper.AddDisplayLabelsToData(_screeningResult.screeningValues, connectionMapHelper.GetLabelCodes(), connectionMapHelper.GetLabels());

                            this.screeningResult = _screeningResult;
                            this.fillData();
                        } else {
                            console.log(_screeningResult);
                        }
                    })
            }


        }
    }
</script>

<style>
    .small {
        /*max-width: 40vmax;*/
        /*align-content: center;
        justify-content: center;*/
    }
    .statControl {
        position: relative;
        top:20px;
        padding:20px;
        padding-top:20px;
    }
</style>