import axios from 'axios';
import moment from 'moment';
import { Module } from "vuex";
import { RootStore } from '..';

interface CategoryType {
    id: number;
    title: string;
    typeId: number;
}

interface Category {
    id: number;
    enabled: boolean;
    title: string;
    categoryTitle?: string;
    description: string;
    videoLink: string;
    vimeoId: string;
    tag: string;
    typeId: number;
    created: string;
    updated?: string;
}

interface CategoriesState {
    categories: Category[],
    categoryTypes: CategoryType[],
    categoryTitles: string[]
}

const categoriesModule: Module<CategoriesState, RootStore> = {
    state: {
        categories: [],
        categoryTypes: [],
        categoryTitles: []
    },
    mutations: {
        updateCategory(state, obj) {
            obj.updatedCategory.updated = moment.unix(obj.updatedCategory.updated).format("YYYY-MM-DDTHH:MM:SS")

            Object.assign(state.categories[obj.Idx], obj.updatedCategory)
        },
        setCategories(state, categoryData: { categories: Category[], categoryTypes: CategoryType[] }) {
            const categories = categoryData.categories;
            const categoryTypes = categoryData.categoryTypes;

            for (let i = 0; i < categories.length; i++) {
                const y = categoryTypes.find(x => categories[i].typeId == x.typeId);

                if (y != null)
                    categories[i].categoryTitle = y.title;
                else
                    categories[i].categoryTitle = "no type matches " + categories[i].typeId;
            }

            const arr = [];
            for (let z = 0; z < categoryTypes.length; z++) {
                arr.push(categoryTypes[z].title);
            }

            state.categoryTitles = arr;
            state.categoryTypes = categoryTypes
            state.categories = categories;
        }
    },
    getters: {
        getCategoryByItem: (state) => (item: Category) => {
            const index = state.categories.indexOf(item)
            return index;
        }
    },
    actions: {
        async getAllCategories({ commit, rootGetters, dispatch }) {
            await dispatch("refreshJWT", rootGetters.getJWT);
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.getJWT
                }
            }
            const response = await axios.get('/api/category/list', config);
            commit('setCategories', response.data);
        },
        async updateCategory({ commit, rootGetters, dispatch }, obj) {

            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    //define auth headers.
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + rootGetters.getJWT
                        }
                    }

                    return new Promise((resolve, reject) => {
                        axios.put('/api/category/update/' + obj.updatedCategory.id, obj.updatedCategory, config)
                            .then(
                                response => {
                                    console.log("Updating USER");
                                    console.log(response);
                                    if (response.status === 200) { //success
                                        commit('updateCategory', obj)
                                        resolve(true);
                                    } else { //failure
                                        console.log("missed it");
                                        reject(false);
                                    }
                                })
                            .catch(
                                err => { //error handling.
                                    console.log(err.response);
                                    if (err.response.status === 401) { //display error.
                                        commit('setAlertErrorSnack');
                                        console.log("Forbidden response - requesting new jwt");
                                        reject(false);
                                    }
                                });
                    });
                    //define and execute API call.
                });
        },
    }
}

export default categoriesModule;