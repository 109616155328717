






















































































































































































































































import { AdminUserEditForm } from "../models/UserEditForm";
import { mapState } from "vuex";
import _ from "lodash";
import axios from "axios";
import ConsultationForm from "../components/ConsultationForm.vue";
import List from "@/components/List.vue";
import moment, { Moment } from "moment";
import Vue from "vue";

interface DataState {
    confirmPassword: string;
    consultationFormTitle: string;
    dialogConsultation: boolean;
    editedConsultation?: any;
    editedItem?: AdminUserEditForm;
    errorMessages: string;
    gender: string;
    genders: string[];
    headers_Consultations: any[];
    minTrial: string;
    onTrial: boolean;
    password: string;
    rules: any;
    showPwdMessage: boolean;
    valid: boolean;
    working: boolean;
}

// Any methods that are set in the "methods()" should go here.
interface Methods {
    close: () => void;
    consultationAddClicked: () => void;
    consultationCloseClicked: () => void;
    consultationDeleteClicked: (item: any) => void;
    consultationEditClicked: (item: any) => void;
    consultationSaveClicked: () => void;
    CreateUser: () => void;
    getLinkSlots_Consultations: () => any[];
    initialize: () => void;
    linkSlotItemClicked: (item: any) => void;
    sendUserVerification: () => void;
    sendMailResetPassword: () => void;
}

interface Computed {
    roleLabel: string;
    today: string;
    passwordConfirmationRule: boolean | string;
    isTrialActive: boolean;
    subscriptionDaysDifference: number;
    subscriptionNextChargeDate: Moment;
    trialNextChargeDate: Moment;

    selectedTrialEnd: string;
    selectedBirthDate: string | null;
    selectedHeight: string;
    selectedWeight: string;

    creatingUser: boolean;
    linkSlots_Consultations: any[];
}

interface Props {
    _editedBase: any;
    formTitle: string;
    editedIndex: number;
    dispatchType: string;
}

export default Vue.extend<DataState, Methods, Computed, Props>({
    name: "UserForm",
    components: {
        ConsultationForm,
        List,
    },
    props: {
        _editedBase: {
            type: Object,
        },
        formTitle: {
            type: String,
        },
        editedIndex: {
            type: Number,
        },
        dispatchType: {
            type: String,
        },
    },
    data(): DataState {
        return {
            confirmPassword: "",
            consultationFormTitle: "",
            dialogConsultation: false,
            editedConsultation: {},
            editedItem: {
                birthDate: null,
                consultations: [],
                email: "",
                gender: "",
                height: 0,
                id: -1,
                isAdmin: false,
                journal: "",
                name: "",
                trialEnd: null,
                weight: 0,
            },
            errorMessages: "",
            gender: "",
            genders: ["Kvinde", "Mand", "Andet"],
            headers_Consultations: [
                { text: "ID", align: "start", sortable: true, value: "id" },
                { text: "Titel", align: "start", value: "name" },
                { text: "Oprettet", align: "start", value: "created" },
                { text: "Video", align: "start", value: "vimeoId" },
                { text: "LINK", value: "videoLink" },
                { text: "Actions", value: "actions", sortable: false, align: "end" },
            ],
            minTrial: moment().format("YYYY-MM-DD"),
            onTrial: false,
            password: "",
            rules: {
                required: (value: any) => !!value || "Required.",
                min: (v: string) => v.length >= 8 || "Min 8 characters",
                emailMatch: (v: string) =>
                    /.+@.+\..+/.test(v) || "E-mail must be valid",
            },
            showPwdMessage: false,
            valid: false,
            working: false,
        };
    },
    mounted() {
        this.onTrial = !!this._editedBase.trialEnd;
        if (!this.editedItem) {
            this.editedItem = {
                id: this._editedBase.id,
                name: this._editedBase.name,
                email: this._editedBase.email,
                gender: this._editedBase.gender,
                height: this._editedBase.height,
                weight: this._editedBase.weight,
                birthDate: this._editedBase.birthDate
                    ? moment(this._editedBase.birthDate)
                    : null,
                isAdmin: this._editedBase.role === "Admin",
                trialEnd: this._editedBase.trialEnd
                    ? moment(this._editedBase.trialEnd)
                    : null,
                consultations: this._editedBase.consultations,
                journal: this._editedBase.journal,
            };
        } else {
            this.editedItem.id = this._editedBase.id;
            this.editedItem.name = this._editedBase.name;
            this.editedItem.email = this._editedBase.email;
            this.editedItem.gender = this._editedBase.gender;
            this.editedItem.height = this._editedBase.height;
            this.editedItem.weight = this._editedBase.weight;
            this.editedItem.birthDate = this._editedBase.birthDate
                ? moment(this._editedBase.birthDate)
                : null;
            this.editedItem.isAdmin = this._editedBase.role === "Admin";
            this.editedItem.trialEnd = this._editedBase.trialEnd
                ? moment(this._editedBase.trialEnd)
                : null;
            this.editedItem.consultations = this._editedBase.consultations;
            this.editedItem.journal = this._editedBase.journal;
        }
        this.initialize();
    },
    computed: {
        roleLabel: {
            get: function (){
                if(this.editedItem?.isAdmin){
                    return "Admin";
                }
                return this._editedBase.subscription?.isActive ? 'Premium' : 'Freemium';
            }
        },
        selectedTrialEnd: {
            get: function () {
                if (!this.onTrial || !this.editedItem?.trialEnd) {
                    return moment().format("YYYY-MM-DD");
                }
                return this.editedItem.trialEnd.format("YYYY-MM-DD");
            },
            set: function (newValue: string) {
                if (this.editedItem) {
                    this.editedItem.trialEnd = moment(newValue);
                }
            },
        },
        selectedBirthDate: {
            get: function () {
                return this.editedItem?.birthDate?.format("YYYY-MM-DD") ?? null;
            },
            set: function (newValue) {
                if (this.editedItem) {
                    this.editedItem.birthDate = newValue ? moment(newValue) : null;
                }
            },
        },
        selectedHeight: {
            get: function () {
                return (this.editedItem?.height ?? 0).toString();
            },
            set: function (newValue) {
                if (this.editedItem) {
                    this.editedItem.height = parseInt(newValue);
                }
            },
        },
        selectedWeight: {
            get: function () {
                return (this.editedItem?.weight ?? 0).toString();
            },
            set: function (newValue) {
                if (this.editedItem) {
                    this.editedItem.weight = parseInt(newValue);
                }
            },
        },
        creatingUser() {
            return this.$props.editedIndex < 0;
        },
        today: () => {
            return moment().subtract(1, "years").format("YYYY-MM-DD");
        },
        passwordConfirmationRule() {
            return (
                this.password === this.confirmPassword || "Passwords must match"
            );
        },
        isTrialActive() {
            return (
                this.onTrial &&
                (this.editedItem!.trialEnd?.isSameOrAfter(moment()) ?? true)
            );
        },
        subscriptionDaysDifference() {
            if (!this.$props._editedBase.subscription) {
                return 0;
            }
            if (this.subscriptionNextChargeDate.isSameOrAfter(this.trialNextChargeDate)) {
                return 0;
            }
            if (!this.editedItem?.trialEnd) {
                return 0;
            }
            return this.editedItem!.trialEnd!.diff(
                moment(this.$props._editedBase.trialEnd),
                "days"
            );
        },
        subscriptionNextChargeDate() {
            return moment(this._editedBase?.subscription?.nextChargeDate);
        },
        trialNextChargeDate() {
            const next = moment(this.selectedTrialEnd);
            if (this.subscriptionNextChargeDate.isSameOrAfter(next)) {
                return this.subscriptionNextChargeDate;
            }
            return next;
        },
        linkSlots_Consultations() {
            return this.getLinkSlots_Consultations();
        },
        ...mapState({
            loading: (state: any) => state.loading,
        }),
    },
    methods: {
        async CreateUser() {
            const form = this.$refs!.form! as HTMLFormElement;
            form.validate();

            try {
                const item = this.editedItem!;
                console.log("item");
                console.log(item);

                if (this.valid) {
                    this.working = true;

                    item.trialEnd = this.onTrial ? item.trialEnd : null;

                    //update user.
                    if (this.$props.editedIndex > -1) {
                        await this.$store.dispatch("updateUser", item);
                    } else {
                        await this.$store.dispatch(this.$props.dispatchType, {
                            ...item,
                            password: this.password
                        });
                    }
                    this.close();
                    this.$emit("saved");
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.working = false;
            }
        },
        async initialize() {
            if (this._editedBase.id < 1) {
                return;
            }
            this.$set(this.editedItem as any, 'consultations', await this.$store.dispatch("getUserConsultations", this._editedBase.id));
            this.$set(this.editedItem as any, 'courses', await this.$store.dispatch("getUserCourses", this._editedBase.id));
            this.$set(this.editedItem as any, 'groupings', await this.$store.dispatch("getUserGroupings", this._editedBase.id));
        },
        close() {
            this.editedConsultation = {};
            this.editedItem = undefined;
            this.$emit("close");
        },
        consultationAddClicked() {
            this.consultationFormTitle = `Opret konsultation for ${this.editedItem!.name}`;
            this.dialogConsultation = true;
        },
        consultationCloseClicked() {
            this.consultationFormTitle = "";
            this.editedConsultation = {};
            this.dialogConsultation = false;
            this.initialize();
        },
        consultationDeleteClicked(item) {
            confirm("Er du sikker på at du vil slette dette kursus?") && this.$store.dispatch("deleteConsultation", item.id)
                .then(() => {
                    this.initialize();
                });
        },
        consultationEditClicked(item) {
            this.consultationFormTitle = `Rediger konsultation for ${this.editedItem!.name}`;
            this.editedConsultation = item;
            this.dialogConsultation = true;
        },
        consultationSaveClicked() {
            this.initialize();
        },
        getLinkSlots_Consultations() {
            const func = this.linkSlotItemClicked;
            const linkSlots = [
                { field: "videoLink", disableField: "vimeoId", action(item: any) { func(item); } }
            ];
            return linkSlots;
        },
        async linkSlotItemClicked(item: any) {
            //this.editedIndex = this.$store.getters.getExerciseByItem(item);
            //this.editedItem = Object.assign({}, item);

            await axios.get("/api/vimeo/getLink/" + item.vimeoId)
                .then((response) => {
                    if (response.data.files.length > 0) {
                        item.videoLink = response.data.files[0].link;
                        item.date = moment.utc();
                        var obj = {
                            Idx: item.id,
                            updatedConsultation: item,
                        };
                        console.log("saving - and dispatching");

                        this.$store.dispatch("updateConsultation", obj).then(
                            async () => {
                                console.log("promise called");
                            },
                            (error) => {
                                console.log(error);
                            }
                        );

                        setTimeout(() => {
                            this.initialize();
                        }, 2500);
                    } else {
                        this.$store.commit(
                            "setAlertErrorSnack",
                            "Der er ingen filer tilgængelige på vimeo, prøv igen senere."
                        );
                    }
                })
                .catch((error: any) => console.error(error));
        },
        sendMailResetPassword() {
            if (this.editedItem && this.editedItem.id < 1) {
                return;
            }
            this.$store.dispatch("sendMailResetPassword", this.editedItem?.email);
        },
        sendUserVerification() {
            if (this.editedItem && this.editedItem.id < 1) {
                return;
            }
            this.$store.dispatch("sendMailVerification", this.editedItem?.id);
        },
    },
    watch: {
        dialogConsultation(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.editedConsultation = {};
            }
        },
        subscriptionDaysDifference() {
            console.log(this.subscriptionDaysDifference);
        },
    }
});
