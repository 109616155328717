<template>
    <div v-if="loggedInUser.role == 'Admin'"
         class="Exercises d-flex justify-center"
         style="padding: 10px; padding-top: 20px">
        <v-toolbar v-if="dialog" flat color="white">
            <v-spacer/>
            <v-dialog v-model="dialog"
                      max-width="1500px"
                      :no-click-animation="true"
                      :persistent="loading">
                <v-card tile :loading="loading" :loader-height="12">
                    <v-form ref="form" v-model="valid">
                        <v-card-title style="background: #738ea6; color: white;">
                            <span class="headline">{{ formTitle }}</span>
                            <v-spacer></v-spacer>
                            <v-icon style="cursor: pointer; color: white; width: 50px; height: 32px;"
                                    @click="close">
                                mdi-close
                            </v-icon>
                        </v-card-title>

                        <div class="save-btn-container">
                            <div class="save-btn"
                                 @click="save">
                                Gem ændringer
                            </div>
                        </div>

                        <v-card-text>
                            <v-container>

                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.title"
                                                      label="Titel"
                                                      hint="Indtast venligst en øvelses titel"
                                                      :error-messages="errorMessages"
                                                      :rules="[() => !!editedItem.title || 'This field is required',]"
                                                      required />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-switch v-model="editedItem.enabled"
                                                  label="Aktiv" />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-switch v-model="editedItem.paidContent"
                                                  label="Premium" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea v-model="editedItem.description"
                                                    style="border-radius: 0;"
                                                    clearable
                                                    clearable-icon="cancel"
                                                    label="Intro"
                                                    hint="Indtast venligst en beskrivelse"
                                                    outlined
                                                    :error-messages="errorMessages"
                                                    :rules="[() => !!editedItem.description || 'You must enter a valid description',]"
                                                    required />
                                    </v-col>
                                </v-row>
                                <v-row :disabled="editedIndex === -1">
                                    <v-col cols="12" md="4" sm="12">
                                        <v-text-field v-model="editedItem.vimeoId"
                                                      label="vimeo ID"
                                                      :disabled="true" />
                                    </v-col>
                                    <v-col cols="12" md="4" sm="12">
                                        <v-text-field v-model="editedItem.videoLink"
                                                      label="Video Link"
                                                      hint="Indtast venligst et video link"
                                                      :error-messages="errorMessages"
                                                      :rules="[() => !!editedItem.videoLink || 'You must enter a valid video link',]"
                                                      required />
                                    </v-col>
                                    <v-col cols="12" md="4" sm="12">
                                        <v-file-input v-model="videoFile" 
                                                      label="upload" 
                                                      ref="feedVideoInputFile"/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="d-flex justify-start" cols="12" md="4" sm="4">
                                        <!--<v-btn color="error"
                                               @click="removeVideoOnVimeo(editedItem.vimeoId)"
                                               :disabled="loading || editedIndex === -1">
                                            Fjern Video
                                        </v-btn>-->
                                    </v-col>
                                    <v-col class="d-flex justify-end" cols="12" md="4" sm="4">
                                        <v-btn color="primary"
                                               @click="getLatestVideoLink()"
                                               :disabled="disableGetLinkBtn(editedItem) || editedIndex === -1">
                                            Hent Link
                                        </v-btn>
                                    </v-col>
                                    <v-col class="d-flex justify-end" cols="12" md="4" sm="4">
                                        <v-btn color="success"
                                               @click="uploadOrReplaceVideoOnVimeo"
                                               :disabled="loading || editedIndex === -1">
                                            Upload Video
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-container>
                                        <!-- Header -->
                                        <v-row align="center" justify="center">
                                            <v-col v-if="!allSelected" cols="12" md="4" sm="4">
                                                <h3>Kategorier</h3>
                                            </v-col>
                                            <!-- Search Bar -->
                                            <v-col v-if="!allSelected" cols="12" md="8" sm="4">
                                                <v-text-field v-model="searchChips"
                                                              ref="searchChips"
                                                              full-width
                                                              hide-details
                                                              label="Søg"
                                                              single-line
                                                              style="padding-top: 0px; margin-top: 0px;" />
                                            </v-col>
                                        </v-row>
                                        <v-divider v-if="!allSelected && searchChips !== ''" />
                                        <v-row v-if="searchChips !== ''">
                                            <template v-for="item in categories">
                                                <v-col :key="item.text"
                                                       cols="12"
                                                       sm="6"
                                                       md="4"
                                                       v-if="!selected.includes(item)">
                                                    <v-list-item @click="selected.push(item)">
                                                        <v-list-item-title v-text="item.text" />
                                                    </v-list-item>
                                                </v-col>
                                            </template>
                                        </v-row>
                                        <v-divider v-if="!allSelected"></v-divider>
                                        <!-- Selected Field. -->
                                        <v-row align="center" justify="start" style="margin-top: 10px; margin-bottom: 0;">
                                            <v-col cols="2" style="margin-right: -30px; padding: 6px;">
                                                <v-btn tile
                                                       outlined
                                                       color="error"
                                                       class="justify-end"
                                                       style="height: 30px;"
                                                       @click="clearAllTags">x - Fjern alle</v-btn>
                                            </v-col>
                                            <v-col cols="10" style="display: contents">
                                                <v-col v-for="(selection, i) in selections" 
                                                       class="shrink" 
                                                       style="padding: 6px;"
                                                       :key="selection.text">
                                                    <v-chip close
                                                            :color="selection.color"
                                                            text-color="white"
                                                            @click:close="selected.splice(i,1)">
                                                        <v-icon left
                                                                v-text="selection.icon" />
                                                        {{ selection.text }} ({{
                                                            selection.shortCode
                                                        }})
                                                    </v-chip>
                                                </v-col>
                                            </v-col>
                                        </v-row>

                                        <v-divider v-if="!allSelected && selected.length > 0" style="margin: 0; margin-top: 12px; margin-bottom: 20px;"/>
                                        <!-- Selectables. -->
                                        <v-row style="margin-top: 0;">
                                            <v-expansion-panels popout>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>Regioner</v-expansion-panel-header>
                                                    <v-divider></v-divider>
                                                    <v-expansion-panel-content>
                                                        <v-container>
                                                            <v-row>
                                                                <template v-for="item in categories">
                                                                    <v-col :key="
                                                                            item.text
                                                                        "
                                                                           cols="12"
                                                                           sm="6"
                                                                           md="4"
                                                                           v-if="
                                                                            !selected.includes(
                                                                                item
                                                                            ) &&
                                                                            item.categoryType ===
                                                                                'region'
                                                                        ">
                                                                        <v-list-item @click="
                                                                                selected.push(
                                                                                    item
                                                                                )
                                                                            ">
                                                                            <v-list-item-title v-text="
                                                                                    item.text
                                                                                "></v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-container>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Hoved
                                                        Kategorier
                                                    </v-expansion-panel-header>
                                                    <v-divider></v-divider>
                                                    <v-expansion-panel-content>
                                                        <v-container>
                                                            <v-row>
                                                                <template v-for="item in categories">
                                                                    <v-col :key="
                                                                            item.text
                                                                        "
                                                                           cols="12"
                                                                           sm="6"
                                                                           md="4"
                                                                           v-if="
                                                                            !selected.includes(
                                                                                item
                                                                            ) &&
                                                                            item.categoryType ===
                                                                                'main'
                                                                        ">
                                                                        <v-list-item @click="
                                                                                selected.push(
                                                                                    item
                                                                                )
                                                                            ">
                                                                            <v-list-item-title v-text="
                                                                                    item.text
                                                                                "></v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-container>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Under
                                                        Kategorier
                                                    </v-expansion-panel-header>
                                                    <v-divider></v-divider>
                                                    <v-expansion-panel-content>
                                                        <v-container>
                                                            <v-row>
                                                                <template v-for="item in categories">
                                                                    <v-col :key="
                                                                            item.text
                                                                        "
                                                                           cols="12"
                                                                           sm="6"
                                                                           md="4"
                                                                           v-if="
                                                                            !selected.includes(
                                                                                item
                                                                            ) &&
                                                                            item.categoryType ===
                                                                                'sub'
                                                                        ">
                                                                        <v-list-item @click="
                                                                                selected.push(
                                                                                    item
                                                                                )
                                                                            ">
                                                                            <v-list-item-title v-text="
                                                                                    item.text
                                                                                "></v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-col>
                                                                </template>
                                                            </v-row>
                                                        </v-container>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-row>
                                        <!-- insert the following line upon subdividing the categories. -->
                                        <!--<v-divider></v-divider>-->
                                    </v-container>
                                </v-row>
                                <v-row align="center" justify="center"
                                       style="margin-top: 30px;">
                                    <v-container class="justify-center">
                                        <div style="display: flex; margin-left: 20px;">
                                            <div class="equipmentReq-container-standing">
                                                <div class="equipmentReq-text">
                                                    Stående
                                                </div>
                                                <v-switch v-model="editedItem.requirements.standing"
                                                          class="equipmentReq-switch" />
                                            </div>
                                            <div class="equipmentReq-container-sitting">
                                                <div class="equipmentReq-text">
                                                    Siddende
                                                </div>
                                                <v-switch v-model="editedItem.requirements.sitting"
                                                          class="equipmentReq-switch" />
                                            </div>
                                            <div class="equipmentReq-container-lying">
                                                <div class="equipmentReq-text">
                                                    Liggende
                                                </div>
                                                <v-switch v-model="editedItem.requirements.lying"
                                                          class="equipmentReq-switch" />
                                            </div>
                                            <div class="equipmentReq-container-tools">
                                                <div class="equipmentReq-text">
                                                    Redskaber
                                                </div>
                                                <v-switch v-model="editedItem.requirements.tools"
                                                          class="equipmentReq-switch" />
                                            </div>
                                        </div>
                                    </v-container>
                                </v-row>
                            </v-container>

                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-btn tile
                                               outlined
                                               color="error"
                                               style="width: 135px; height: 50px;"
                                               @click="clearAll">
                                            Ryd Alt
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-form>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-toolbar v-if="dialogCourseMaterial" flat color="white">
            <v-spacer/>
            <v-dialog v-model="dialogCourseMaterial"
                      max-width="1500px"
                      :no-click-animation="true"
                      :persistent="loading">
                <CourseMaterialForm :formTitle="courseMaterialFormTitle"
                                    :_baseEditedItem="editedCourseMaterial"
                                    @close="courseMaterialClose"
                                    @save="courseMaterialSave"/>
            </v-dialog>
        </v-toolbar>

        <v-card tile :loading="loading" width="100vw" min-width="300px">
            <v-card-title>
                <v-col sm="6">
                    <v-row style="margin-bottom: 10px;">
                        <span>Videoer</span>
                    </v-row>
                    <v-row>
                        <v-btn tile
                               color="primary"
                               class="mb-2"
                               style="margin-right: 10px"
                               :disabled="loading"
                               @click="courseMaterialCreate">
                            <h2>+</h2>
                        </v-btn>
                        <div style="display: flex; align-items: center; margin-bottom: 8px; font-size: 1.0rem">
                            Upload kursusmateriale.
                        </div>
                    </v-row>
                    <v-row>
                        <v-btn tile
                               color="primary"
                               class="mb-2"
                               style="margin-right: 10px"
                               :disabled="loading"
                               @click="addClicked">
                            <h2>+</h2>
                        </v-btn>
                        <div style="display: flex; align-items: center; margin-bottom: 8px; font-size: 1.0rem">
                            Upload video til feed.
                            <v-img src="@/assets/Component_63_–_43.png" height="26px" width="23px" style="margin-left: 10px;" />
                        </div>
                    </v-row>
                </v-col>
                <!--<v-col sm="6">

                </v-col>-->
                <v-spacer></v-spacer>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Søg i Øvelser"
                              single-line
                              hide-details/>
            </v-card-title>
            <v-data-table class="elevation-1" 
                          single-expand
                          sort-by="id"
                          :expanded.sync="expanded"
                          :fixed-header="true"
                          :footer-props="{'items-per-page-options': [5, 15, 25, 35, 99],}"
                          :headers="headers"
                          :items="exercises"
                          :items-per-page="35"
                          :search="search"
                          :sort-desc="true"
                          @click:row="expandRow">
                <template v-slot:item.id="{ item }">
                    <div style="display: flex; height: 28px; align-items: center;">
                        <div style="flex: 1 1 auto">{{ item.id }}</div>
                        <div v-if="!item.isCourseMaterial">
                            <v-img src="@/assets/Component_63_–_43.png" height="18px" width="15px"/>
                        </div>
                    </div>
                </template>

                <template v-slot:item.videoLink="{ item }">
                    <v-btn v-if="item.videoLink != 'no link'"
                           icon
                           v-bind:href="'' + item.videoLink + ''"
                           target="_blank">
                        Link
                    </v-btn>
                    <v-btn v-else
                           color="primary"
                           :disabled="disableGetLinkBtn(item)"
                           @click.stop="getLatestVideoLinkFromTableView(item)">
                        Hent Link
                    </v-btn>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-icon medium
                            color="primary"
                            class="mr-2"
                            @click.stop="editItem(item)"
                            :disabled="loading">
                        mdi-pencil
                    </v-icon>
                    <v-icon medium
                            color="primary"
                            class="mr-2"
                            @click.stop="duplicateClicked(item)"
                            :disabled="loading">
                        mdi-content-duplicate
                    </v-icon>
                    <v-icon medium
                            color="primary"
                            class="mr-2"
                            @click.stop="deleteItem(item)"
                            :disabled="loading">
                        mdi-delete
                    </v-icon>
                </template>

                <template align="center"
                          justify="center"
                          v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-row justify="center"
                               style="margin-top: 8px; margin-bottom: -5px;">
                            <v-col v-for="(selection, i) in selections"
                                   class="shrink"
                                   style="padding-top: 4px; padding-bottom: 4px;"
                                   :key="i">
                                <v-chip :color="selection.color"
                                        text-color="white">
                                    {{ selection.text }} ({{
                                        selection.shortCode
                                    }})
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-divider></v-divider>
                        </v-row>
                        <v-row justify="center"
                               style="padding: 10px; margin-bottom: 0;">
                            <v-icon medium
                                    color="primary"
                                    class="mr-2"
                                    @click="editItem(item)"
                                    :disabled="loading">
                                mdi-pencil
                            </v-icon>
                            <v-icon medium
                                    color="primary"
                                    class="mr-2"
                                    @click="duplicateClicked(item)"
                                    :disabled="loading">
                                mdi-content-duplicate
                            </v-icon>
                            <v-icon medium
                                    color="primary"
                                    class="mr-2"
                                    @click="deleteItem(item)"
                                    :disabled="loading">
                                mdi-delete
                            </v-icon>
                        </v-row>
                    </td>
                </template>

                <template #footer>
                    <v-toolbar flat color="white">
                        <v-btn tile
                               color="primary"
                               class="mb-2"
                               :disabled="loading"
                               @click="addClicked">
                            <h2>+</h2>
                        </v-btn>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import axios from "axios";
import connectionMapHelper from "../helper/ExerciseCategoryConnectionMapping";
import CourseMaterialForm from "../components/CourseMaterialForm.vue";
import FormData from "form-data";
import moment from "moment";

export default {
    name: "Exercises",
    components: { 
        CourseMaterialForm, 
    },
    data: () => ({
        allRequirements: false,
        courseMaterialFormTitle: "",
        defaultItem: {
            enabled: true,
            paidContent: true,
            title: "",
            description: "",
            videoLink: "no link",
            id: -1,
            vimeoId: "",
            requirements: {
                standing: false,
                sitting: false,
                lying: false,
                tools: false,
                id: -1,
                exerciseId: -1,
            },
            //ExerciseCategoryConnection
            tags: {
                id: -1,
                exerciseId: -1,
            },
        },
        defaultTags: {
            //structure comes from the ExerciseCategoryConnectionMapping.js located in the src/helper folder.
        },
        dialog: false,
        dialogCourseMaterial: false,
        editedCourseMaterial: {},
        editedIndex: -1,
        editedItem: {},
        editedTags: {
            //structure comes from the ExerciseCategoryConnectionMapping.js located in the src/helper folder.
        },
        errorMessages: "",
        expanded: [],
        headers: [
            { text: "Video ID", value: "id", sortable: true, filterable: true, },
            { text: "Navn", align: "start", sortable: true, value: "title", filterable: true, },
            { text: "Oprettet", value: "created", sortable: true, filterable: false, },
            { text: "Opdateret", value: "updated", sortable: true, filterable: false, },
            { text: "Link", value: "videoLink", sortable: false, filterable: false, },
            { text: "Vimeo ID", value: "vimeoId", sortable: false, filterable: false, },
            { text: "Actions", value: "actions", sortable: false, filterable: false, },
        ],
        items: [],
        rules: {
            required: (value) => !!value || "Required.",
        },
        search: "",
        searchChips: "",
        selected: [],
        valid: false,
        videoFile: null,
    }),
    computed: {
        //start of chips filtering.
        allSelected() {
            return this.selected.length === this.items.length;
        },
        categories() {
            const searchChips = this.searchChips.toLowerCase();

            if (!searchChips) return this.items;

            return this.items.filter((item) => {
                const text = item.text.toLowerCase();

                return text.indexOf(searchChips) > -1;
            });
        },
        selections() {
            const selections = [];

            for (const selection of this.selected) {
                selections.push(selection);
            }

            return selections;
        }, //end of chips filtering
        formTitle() {
            return this.editedIndex === -1 ? "Tilføj øvelse til feed." : "Rediger Video";
        },
        ...mapState({
            exercises: (state) => state.exercises.exercises,
            loading: (state) => state.loading,
            loggedInUser: (state) => state.auth.user,
            loggedIn: (state) => !!state.auth.token,
        }),
    },
    watch: {
        selected() {
            this.searchChips = "";
        },
        dialog(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.close();
                this.initialize();
                this.$set(this, 'editedItem', this.getEmptyExercise());
                this.$set(this, 'editedIndex', -1);
                this.$set(this, 'selected', []);
            }
        },
        dialogCourseMaterial(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.courseMaterialReset();
                this.initialize();
                this.editedCourseMaterial = {};
            }
        },
    },
    created() {
        if (!this.loggedIn)
            this.$router.push({
                path: "/",
            });
        else {
            this.items = connectionMapHelper.displayItems;
            this.defaultTags = connectionMapHelper.editedTags;
            this.editedTags = connectionMapHelper.editedTags;
            this.initialize();
            this.editedItem = this.getEmptyExercise();
        }
    },
    methods: {
        async getLatestVideoLink() {
            //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
            if (this.editedItem.vimeoId && !this.loading) {
                console.log("getting link for object:");
                console.log(this.editedItem);
                this.setLoadingOn();
                await axios.get("/api/vimeo/getLink/" + this.editedItem.vimeoId)
                    .then((response) => {
                        if (response.data.files.length > 0) {
                            this.editedItem.videoLink =
                                response.data.files[0].link;
                            this.setLoadingOff();
                            this.save();
                        } else
                            this.$store.commit(
                                "setAlertErrorSnack",
                                "Der er ingen filer tilgængelige på vimeo, prøv igen senere."
                            );
                    })
                    .catch(() => this.setLoadingOff());
            }
        }, //todo: should be moved into a vuex store file.
        async getLatestVideoLinkFromTableView(item) {
            this.editedIndex = this.$store.getters.getExerciseByItem(item);
            this.editedItem = Object.assign({}, item);

            await axios.get("/api/vimeo/getLink/" + this.editedItem.vimeoId)
                .then((response) => {
                    if (response.data.files.length > 0) {
                        this.editedItem.videoLink = response.data.files[0].link;
                        if (this.editItem.isCourseMaterial === false) {
                            this.setCategoriesForAPI();
                        }
                        this.editedItem.updated = moment.utc().unix();
                        this.editedItem.exerciseID = this.editedItem.id;
                        var obj = {
                            Idx: this.editedIndex,
                            updatedExercise: this.editedItem,
                        };
                        console.log("saving - and dispatching");

                        this.$store.dispatch("updateExercise", obj).then(
                            async () => {
                                console.log("promise called");
                                this.setLoadingOff();
                            },
                            (error) => {
                                console.log(error);
                            }
                        );

                        setTimeout(() => {
                            this.initialize();
                        }, 2500);
                    } else {
                        this.$store.commit(
                            "setAlertErrorSnack",
                            "Der er ingen filer tilgængelige på vimeo, prøv igen senere."
                        );
                    }
                })
                .catch(() => this.setLoadingOff());
        },
        async removeVideoOnVimeo(Id) {
            //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
            if (Id != "" && !this.loading) {
                this.setLoadingOn();
                console.log("removing");
                console.log(Id);
                await axios.delete("/api/vimeo/delete/" + Id)
                    .then((response) => {
                        console.log(response);
                        if (this.editedItem.vimeoId == Id) {
                            this.editedItem.vimeoId = "";
                            this.editedItem.videoLink = "no link";
                            this.setLoadingOff();
                            this.save();
                        }
                    })
                    .catch(() => this.setLoadingOff());
            }
        }, //todo: should be moved into a vuex store file.
        async replaceVideoOnVimeo() {
            //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
            if (this.editedItem.vimeoId && !this.loading) {
                console.log("replacing");
                var oldVimeoID = this.editedItem.vimeoId;
                await this.uploadVideoToVimeo();
                //await this.removeVideoOnVimeo(oldVimeoID);
            }
        },
        async uploadVideoToVimeo() {
            //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
            if (this.videoFile != null && !this.loading) {
                this.$refs.form.validate();

                if (this.valid) {
                    this.setCategoriesForAPI();
                    if (
                        !connectionMapHelper.doesExerciseHaveAMainCategory(
                            this.editedItem
                        )
                    ) {
                        console.log("hello from main category error");
                        this.$store.commit(
                            "setAlertErrorSnack",
                            "Du skal huske at indsætte en hovedkategori på øvelsen."
                        );
                        return;
                    }

                    this.setLoadingOn();
                    console.log("uploading");
                    const formData = new FormData();

                    formData.append("file_data", this.videoFile);
                    await axios.post(
                        "/api/vimeo/create/" + this.editedItem.title,
                        formData
                    )
                        .then((response) => {
                            this.editedItem.vimeoId = response.data.videoID;
                            this.editedItem.videoLink = "no link";
                            this.setLoadingOff();
                            this.save();
                        })
                        .catch(() => this.setLoadingOff());
                } else {
                    console.log("hello from fill all fields error!");
                    this.$store.commit(
                        "setAlertErrorSnack",
                        "Udfyld venligst alle data inden du uploader/gemmer."
                    );
                }
            }
        }, //todo: should be moved into a vuex store file.
        addClicked() {
            console.log("addClicked");
            console.log(this.editedItem);
            this.$set(this, 'editedItem', this.getEmptyExercise());
            this.dialog = true;
        },
        allRequirementsClicked() {
            this.allRequirements = !this.allRequirements;
            this.editedItem.requirements.standing = this.allRequirements;
            this.editedItem.requirements.sitting = this.allRequirements;
            this.editedItem.requirements.lying = this.allRequirements;
            this.editedItem.requirements.tools = this.allRequirements;
        },
        clearAll() {
            if (!this.loading) {
                this.editedIndex = -1;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }
        },
        clearAllTags() {
            this.selected = [];
        },
        close() {
            this.dialog = false;
            this.$refs.feedVideoInputFile.internalValue = null;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
            this.setLoadingOff();
            this.$set(this, 'expanded', []);
        },
        courseMaterialClose() {
            this.courseMaterialReset();
        },
        courseMaterialCreate() {
            this.editedCourseMaterial = {};
            this.courseMaterialFormTitle = "Opret kursusvideo";
            this.dialogCourseMaterial = true;
        },
        courseMaterialEdit(item) {
            this.editedCourseMaterial = item;
            this.courseMaterialFormTitle = "Rediger kursusvideo";
            this.dialogCourseMaterial = true;
        },
        courseMaterialReset() {
            this.editedCourseMaterial = {};
            this.courseMaterialFormTitle = "";
            this.dialogCourseMaterial = false;
        },
        courseMaterialSave() {
            this.courseMaterialReset();
        },
        deleteItem(item) {
            const index = this.$store.getters.getExerciseByItem(item);
            console.log("deleting");
            console.log(item);
            var obj = { Id: item.id, Idx: index };
            confirm("Er du sikker på at du vil slette denne øvelse?") &&
                this.$store.dispatch("removeExercise", obj);
        },
        disableGetLinkBtn(obj) {
            if (obj.vimeoId && !this.loading) {
                return false;
            }
            return true;
        },
        duplicateClicked(item) {
            this.$store.dispatch("duplicateExercise", _.cloneDeep(item));
        },
        editItem(item) {
            this.editedIndex = this.$store.getters.getExerciseByItem(item);
            if (item.isCourseMaterial) {
                this.editedCourseMaterial = item;
                this.courseMaterialFormTitle = "Rediger kursusvideo";
                this.dialogCourseMaterial = true;
            }
            else {
                this.editedItem = Object.assign({}, item);
                this.setCategoriesForEdit();
                console.log(this.editedItem);
                this.dialog = true;
            }
        },
        expandRow(item) {
            // would be
            // this.expanded = [item]
            // but if you click an expanded row, you want it to collapse
            this.$set(this, 'selected', []);
            this.editedIndex = this.$store.getters.getExerciseByItem(item);
            this.editedItem = Object.assign({}, item);
            if (!item.isCourseMaterial) {
                this.setCategoriesForEdit();
            }
            this.expanded = item === this.expanded[0] ? [] : [item];
        },
        getEmptyExercise() {
            return {
                enabled: true,
                paidContent: true,
                title: "",
                description: "",
                videoLink: "no link",
                id: -1,
                vimeoId: "",
                requirements: {
                    standing: false,
                    sitting: false,
                    lying: false,
                    tools: false,
                    id: -1,
                    exerciseId: -1,
                },
                //ExerciseCategoryConnection
                tags: {
                    id: -1,
                    exerciseId: -1,
                },
            };
        },
        async initialize() {
            await this.$store.dispatch("getAllExercises");
            await this.$forceUpdate();
        },
        removeChip(item) {
            this.chips.splice(this.chips.indexOf(item), 1);
            this.chips = [...this.chips];
        }, //todo: should be moved into a vuex store file.
        save() {
            console.log("saving: " + this.loading);
            if (this.loading) {
                this.$store.commit(
                    "setAlertErrorSnack",
                    "Vent venligst indtil systemet er færdig med at loade."
                );
                return;
            }

            if (this.$refs.form) this.$refs.form.validate();
            console.log("save: " + this.valid);

            //todo: check at der er mindst 1 hovedkategori i modellen, ellers display på skærmen.
            if (this.valid) {
                this.setLoadingOn();
                this.setCategoriesForAPI();
                console.log(this.editedItem);
                if (
                    !connectionMapHelper.doesExerciseHaveAMainCategory(
                        this.editedItem
                    )
                ) {
                    this.$store.commit(
                        "setAlertErrorSnack",
                        "Du skal huske at indsætte en hovedkategori på øvelsen."
                    );
                    return;
                } else console.log("main category tag detected.");

                const videoLink = this.editedItem.videoLink;
                if (videoLink !== "" && videoLink !== "no link") {
                    if (videoLink.substring(0, 4) !== "http") {
                        if (videoLink.substring(0, 3) !== "www") {
                            this.editedItem.videoLink = "http://www." + videoLink;
                        }
                        else {
                            this.editedItem.videoLink = "http://" + videoLink;
                        }
                    }
                }

                //update exercise.
                if (this.editedIndex > -1) {
                    this.editedItem.updated = moment.utc().unix();
                    this.editedItem.exerciseID = this.editedItem.id;
                    var obj = {
                        Idx: this.editedIndex,
                        updatedExercise: this.editedItem,
                    };
                    console.log("saving - and dispatching");

                    this.$store.dispatch("updateExercise", obj).then(
                        async () => {
                            console.log("promise called");
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                    this.close();
                } //create exercise
                else {
                    this.editedItem.Created = moment.utc().unix();
                    this.$store.dispatch("addExercise", this.editedItem).then(
                        async () => {
                            console.log("promise called");
                            this.close();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else {
                console.log("exercise form invalid");
            }

            setTimeout(async () => {
                this.initialize();
            }, 2500);
        },
        setCategoriesForAPI() {
            this.editedItem = connectionMapHelper.setCategoriesForAPI(
                this.editedItem,
                this.selections,
                this.editedIndex,
                this.exercises[this.editedIndex],
                _.cloneDeep(connectionMapHelper.editedTags)
            );

            this.editedTags = this.editedItem.tags;
        },
        setCategoriesForEdit() {
            var returnItem = connectionMapHelper.setCategoriesForEdit(
                this.editedItem,
                this.exercises[this.editedIndex]
            );
            this.selected = returnItem.selected;
            this.editedItem = returnItem.editedItem;
            this.editedTags = returnItem.editedItem.tags;
        },
        setLoadingOff() {
            if (this.loading) this.$store.commit("setLoading", false);
        },
        setLoadingOn() {
            if (!this.loading) this.$store.commit("setLoading", true);
        },
        uploadOrReplaceVideoOnVimeo() {
            if (this.editedItem.vimeoId) {
                this.replaceVideoOnVimeo();
            } else {
                this.uploadVideoToVimeo();
            }
        }, //todo: should be moved into a vuex store file.
    },
};
</script>

<style scoped>
    .save-btn-container {
        background: transparent;
        color: #738ea6;
        display: flex;
        justify-content: end;
        top: 0;
        z-index: 2;
        margin-bottom: 15px;
    }

    .save-btn {
        cursor: pointer;
        margin-top: 15px;
        padding: 6px 15px;
        font-size: 1.2rem !important;
        font-weight: 400;
        line-height: 1.3rem;
        letter-spacing: normal !important;
        font-family: "Roboto", sans-serif !important;
        padding-top: 0;
    }

    .equipmentReq-container-standing,
    .equipmentReq-container-sitting,
    .equipmentReq-container-lying,
    .equipmentReq-container-tools {
        display: flex;
        flex-direction: column;
        width: 85px;
    }
    
    .equipmentReq-container-sitting,
    .equipmentReq-container-lying,
    .equipmentReq-container-tools {
        margin-left: 110px;
    }

    .equipmentReq-text {
        display: flex;
        justify-content: center;
    }

    .equipmentReq-switch {
        transform: scale(1.75);
        margin-left: 30px;
    }
</style>