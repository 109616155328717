var labels = [];
var mainLabels = [
    'Kvikfix',
    'Sanser',
    'Fysik',
    'Kost',
    'Krop',
    'Mind-Set',
];//all labels in main.
var subLabels = [
    'Zoneterapi',
    'Manuel Behandling',
    'Akupunktur Punkter',
    'Meridian Strygninger',
    'Ledmobilisering med Flossband',
    'Udspænding med Bolde',
    'Foamroller',
    'Grønne Bolde',
    'Træning af Region',
    'Muskel System',
    'Neuromuskulær System',
    'Funktionelle Øvelser',
    'Proprioception',
    'Bevægelighed',
    'Hormonsystem',
    'Lymfesystem',
    'Knoglesystem',
    'Nervesystem',
    'Fordøjelsessystem',
    'Luftvejssystem',
    'Kredsløbssystem',
    'Føle',
    'Høre',
    'Syn',
    'Lugte/Smage',
    'Balance Indre Øre',
    'Ernæring',
    'Åndedræt',
    'Rygning',
    'Alkohol',
    'Væske',
    'KOST-model',
    'Bindevævsflow',
    'Meditation',
    'BARS',
    'Psykologi',
    'Adfærdsmodeller',
    'Smertemodeller',
];//all labels in sub.
var regionLabels = [
    'Hele Kroppen',
    'Hoved/Nakke',
    'Nakke/Skulder',
    'Ryg',
    'Lænd/Bækken',
    'Hofte',
    'Knæ',
    'Ankel/Fod',
    'Arm/Albue',
    'Håndled/Hænder',
];//all labels in regions.

var labelCodes = [];
var mainLabelCodes = [
    'Quickfix',
    'Senses',
    'Fysical',
    'Diet',
    'Body',
    'Mindset'
]; //all labelCodes in main.
var subLabelCodes = [
    'Zonetherapy',
    'Manual_Treatment',
    'Acupuncture_Points',
    'Median_Massages',
    'JointMobilization_With_Flossband',
    'Detensioning_With_Balls',
    'Foam_Roller',
    'Green_Balls',
    'Regional_Training',
    'Muscle_System',
    'Neuromuscular_System',
    'Functional_Exercises',
    'Proprioception',
    'Agility',
    'Hormone_System',
    'Lymphatic_System',
    'Bone_System',
    'Nervous_System',
    'Digestion_System',
    'Airway_System',
    'Circulatory_System',
    'Feeling',
    'Hearing',
    'Seeing',
    'Smeling_And_Tasting',
    'Inner_Ear_Balance',
    'Nutrition',
    'Breathing',
    'Smoking',
    'Alcohol',
    'Hydration',
    'KOSTModel',
    'Connective_Tissue_Flow',
    'Meditation',
    'BARS',
    'Psykology',
    'Behavior_Modelling',
    'Pain_Modelling'
]; //all labelCodes in sub.
var regionLabelCodes = [
    'Whole_Body',
    'Head_And_Neck',
    'Neck_And_Shoulder',
    'Back',
    'Lower_Back',
    'Hip',
    'Knee',
    'Ancle_And_Foot',
    'Arm_And_Elbow',
    'Wrist_And_Hands'
]; //all labelCodes in regions.

var labelData = [];

function Initialize() {
    CollectAllLabels();
    CheckLabelsAndCodeLabelsUniform();
}

function CollectAllLabels() {
    GetLabelCodes();
    GetLabels();
}
function GetLabelCodes() {
    labelCodes = [];
    labelCodes = labelCodes.concat(mainLabelCodes);
    labelCodes = labelCodes.concat(subLabelCodes);
    labelCodes = labelCodes.concat(regionLabelCodes);
    return labelCodes;
}
function GetLabels() {
    labels = [];
    labels = labels.concat(mainLabels);
    labels = labels.concat(subLabels);
    labels = labels.concat(regionLabels);
    return labels;
}

function GetLabelData() {
    return labelData;
}

function GetFromAPIData(screeningValues, CodeLabel) {

    if (CheckLabelsAndCodeLabelsUniform && screeningValues != null) {
        var idx = screeningValues.findIndex(x => x.categoryId === CodeLabel);
        if (idx != -1) {
            return screeningValues[idx];
        }
        else {
            console.error("Screening value - Not Found.");
        }
    } else {
        console.error("Aborting API mapping. See errors above.");
    }
    return null;
}

function GenerateLabelData(screeningValues, givenLabelCodes) {
    if (CheckLabelsAndCodeLabelsUniform && screeningValues != null) {
        var labelData = [];
        for (var i = 0; i < givenLabelCodes.length; i++) {
            var idx = screeningValues.findIndex(x => x.categoryId === givenLabelCodes[i]);
            if (idx != -1) {
                labelData.push(screeningValues[idx].computedValue);
            }
            else {
                console.error("Screening value - Not Found.");
            }
        }
        return labelData;
    }
    else {
        console.error("Aborting API mapping. See errors above.");
    }
}

function AddDisplayLabelsToData(screeningValues, givenLabelCodes, givenLabels) {
    if (CheckLabelsAndCodeLabelsUniform && screeningValues != null) {
        for (var i = 0; i < givenLabelCodes.length; i++) {
            var idx = screeningValues.findIndex(x => x.categoryId === givenLabelCodes[i]);
            
            if (idx != -1) {
                screeningValues[idx].displayLabel = givenLabels[i];
            }
            else {
                console.error("Screening value - Not Found.");
            }
        }
        return screeningValues;
    }
    else {
        console.error("Aborting API mapping. See errors above.");
    }
}

function CheckLabelsAndCodeLabelsUniform() {

    if (labels.length == labelCodes.length && labels.length != 0) {
        console.log("labels and codeLabels has the same length - should be ok.");
        return true;
    }
    else {
        console.error("labels and codeLabels does not have the same length, or length is 0 - Please check the arrays, and make sure they defined correctly.");
        return false;
    }
}



module.exports = {
    mainLabels: mainLabels,
    subLabels: subLabels,
    regionLabels: regionLabels,
    mainLabelCodes: mainLabelCodes,
    subLabelCodes: subLabelCodes,
    regionLabelCodes: regionLabelCodes,
    GetLabels: GetLabels,
    GetLabelCodes: GetLabelCodes,
    GetLabelData: GetLabelData,
    GenerateLabelData: GenerateLabelData,
    GetFromAPIData: GetFromAPIData,
    Initialize: Initialize,
    AddDisplayLabelsToData: AddDisplayLabelsToData,
};

