<template>
    <div class="Login" style="width:100%; background-color:#fff">
        <div v-if="loggingIn === true && forgotPassword === false">
            <LoginModule :login_path="login_path" @ForgotMyPassword="GoToForgottenPassword" @LoggedIn="LoggedIn">
            </LoginModule>
        </div>
        <div v-if="forgotPassword === true">
            <ForgotMyPasswordModule @LoginClicked="GoToLoginClicked"></ForgotMyPasswordModule>
        </div>
    </div>
</template>

<script>
    import LoginModule from './LoginModule.vue';
    import ForgotMyPasswordModule from './ForgotMyPasswordModule.vue';
    export default {
        name: 'LoginView',
        props: ['login_path'],
        data: () => ({
            loggingIn: true,
            forgotPassword: false
        }),
        components: {
            LoginModule,
            ForgotMyPasswordModule
        },
        methods: {
            GoToLoginClicked() {
                this.loggingIn = true;
                this.forgotPassword = false;
            },
            GoToForgottenPassword() {
                this.forgotPassword = true;
            },
            LoggedIn() {
                this.$emit("LoggedIn");
            }
        }
    }
</script>

<style>

</style>