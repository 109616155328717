var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","loading":_vm.loading,"width":"100vw","min-width":"300px","disabled":_vm.isDisabled}},[(!_vm.isDisabled)?_c('v-card-title',[(_vm.canAddOrRemove)?_c('v-btn',{staticClass:"mb-2",staticStyle:{"margin-right":"10px"},attrs:{"tile":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.addClicked}},[_c('h2',[_vm._v("+")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.label,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.contents,"footer-props":{
      'items-per-page-options': [5, 15, 25, 35, 99],
    },"items-per-page":99,"item-class":_vm.itemStyle,"search":_vm.search,"group-by":_vm.groupBy,"show-group-by":_vm.showGrouping},scopedSlots:_vm._u([{key:"item.priority",fn:function(ref){
    var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.priority},on:{"update:returnValue":function($event){return _vm.$set(item, "priority", $event)},"update:return-value":function($event){return _vm.$set(item, "priority", $event)},"save":function($event){return _vm.savePriorityEdit(item)},"cancel":function($event){return _vm.cancelPriorityEdit(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","rules":[_vm.ensureInt],"label":"Edit","single-line":""},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.priority)+" ")])]}},_vm._l((_vm.chipSlots),function(slot,i){return {key:("item." + (slot.field)),fn:function(ref){
    var item = ref.item;
return _vm._l((item[slot.field].slice(
          0,
          _vm.getShownElemsCount(slot) + 1
        )),function(elem,j){return _c('div',{key:i.toString() + '.' + j.toString(),staticStyle:{"display":"table-cell","padding-right":"2px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(j < _vm.getShownElemsCount(slot))?_c('v-chip',_vm._g(_vm._b({style:(("display: flex; justify-content: center; width: " + (slot.shownElemsWidth))),attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('div',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(elem[slot.propertyToShow])+" ")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(elem[slot.propertyToShow]))])]),(j == _vm.getShownElemsCount(slot))?_c('v-chip',{staticStyle:{"width":"auto"},attrs:{"outlined":""}},[_vm._v(" "+_vm._s("+" + (item[slot.field].length - _vm.getShownElemsCount(slot)).toString())+" ")]):_vm._e()],1)})}}}),_vm._l((_vm.clickableSlots),function(clickSlot,i){return {key:("item." + (clickSlot.field)),fn:function(ref){
        var item = ref.item;
return [_c('div',{key:i,staticStyle:{"color":"-webkit-link","cursor":"pointer","text-decoration":"underline"},on:{"click":function($event){return _vm.clickableSlotClicked(clickSlot, item)}}},[_vm._v(" "+_vm._s(item[clickSlot.field])+" ")])]}}}),_vm._l((_vm.vimeoLinkSlots),function(linkSlot,i){return {key:("item." + (linkSlot.field)),fn:function(ref){
        var item = ref.item;
return [(_vm.hasLink(linkSlot, item))?_c('div',{key:i},[(_vm.getLinkSlotLink(linkSlot, item) != 'no link')?_c('v-btn',{attrs:{"icon":"","href":'' + _vm.getLinkSlotLink(linkSlot, item) + '',"target":"_blank"}},[_vm._v(" Link ")]):_c('v-btn',{attrs:{"color":"primary","disabled":_vm.getLinkSlotDisabled(linkSlot, item)},on:{"click":function($event){$event.stopPropagation();return _vm.vimeoLinkSlotClicked(linkSlot, item)}}},[_vm._v(" Hent Link ")])],1):_vm._e()]}}}),{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(!item.dateDeleted)?[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.editClicked(item)}}},[_vm._v(" mdi-pencil ")]),(_vm.canDuplicate)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.duplicateClicked(item)}}},[_vm._v(" mdi-content-duplicate ")]):_vm._e(),(_vm.canAddOrRemove)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.deleteClicked(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(_vm.hasStats)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.statsClicked(item)}}},[_vm._v(" mdi-chart-bar-stacked ")]):_vm._e()]:_vm._e()]}},(_vm.canAddOrRemove)?{key:"footer",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"tile":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.addClicked}},[_c('h2',[_vm._v("+")])])],1)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }