<template>
  <v-container class="pa-0">
    <v-card
      class="sign-up-card pa-4"
      flat
      :loading="loading"
      width="100%"
      color="black"
    >
      <v-form ref="form" v-model="valid">
        <v-card-text class="pa-1">
          <v-container>
            <div>
              <v-row>
                <v-col cols="12" class="pa-1">
                  <label class="alt-font">Navn</label>
                  <v-text-field
                    v-model="item.name"
                    solo
                    dense
                    color="white"
                    hide-details="auto"
                    class="pa-0 ma-0"
                    :error-messages="errorMessages"
                    :rules="[() => !!item.name || 'This field is required']"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pa-1">
                  <label class="alt-font">Email</label>
                  <v-text-field
                    v-model="item.email"
                    solo
                    hide-details="auto"
                    dense
                    class="pa-0 ma-0"
                    :error-messages="errorMessages"
                    :rules="[
                      rules.emailMatch || 'You must enter a valid email',
                    ]"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pa-1">
                  <label class="alt-font">Adgangskode</label>
                  <v-text-field
                    v-model="password"
                    solo
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[
                      rules.required,
                      rules.min || 'This field is required',
                    ]"
                    :type="showPassword ? 'text' : 'password'"
                    name="input-10-1"
                    hint="Mindst 8 bogstaver/tegn"
                    counter
                    @click:append="showPassword = !showPassword"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pa-1">
                  <v-expansion-panels
                    dark
                    flat
                    :value="$route.query.vipId ? 0 : null"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <label
                          class="alt-font primary--text"
                          style="cursor: pointer"
                          >VIP ID</label
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-text-field
                          v-model="item.vipId"
                          solo
                          dense
                          light
                          class="pa-0 ma-0"
                        >
                        </v-text-field>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row class="">
                <v-checkbox
                  v-model="consentPolicies"
                  :rules="[rules.required || 'This field is required']"
                  dark
                  class="ma-0 pa-0"
                  hide-details="true"
                >
                  <template v-slot:label>
                    <div class="body-2">
                      <span>
                        Jeg accepterer DinSundhedsguide's
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="/Fortrolighedspolitik"
                              @click.stop
                              v-on="on"
                            >
                              Fortrolighedspolitik
                            </a>
                          </template>
                          Åbner i en ny tab.
                        </v-tooltip>
                      </span>
                      <span>&emsp;& &emsp;</span>
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="/terms-of-service"
                              @click.stop
                              v-on="on"
                            >
                              Terms Of Service.
                            </a>
                          </template>
                          Åbner i en ny tab.
                        </v-tooltip>
                      </span>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            block
            dark
            color="#4B788E"
            :disabled="validate_form === false"
            class="mb-2 white--text"
            @click="CreateUser()"
            :loading="loading"
          >
            Opret Bruger
          </v-btn>
        </v-card-actions>
      </v-form>
      <div class="body-2 grey--text text-center">
        Du samtykker at modtage tilbud fra DinSundhedsGuide.dk
      </div>
    </v-card>
    <!-- <v-dialog
            v-model="showDialog"
            max-width="700"
        >
            <v-card>
                <v-card-title style="word-break: normal;" class="headline">
                    Opret dit abonnement nu og få 3 måneders gratis adgang.
                </v-card-title>

                <v-card-text>
                Du har nu 14 dages prøvetid og du får adgang til en gratis Krops-Screening som definerer din kropstilstand ud fra dine funktioner og data omkring din livssituation.
                    <br>
                    <br>
                    Din Sundhedsguide giver dig adgang til små videoer, som inspirerer dig til bevægelse. Det er øvelser der nemt integreres i din hverdag og som vedligeholder din krop. Øvelserne giver dig også selvbehandlingsteknikker til eventuelle spændinger eller smerter.
<br><br><br>
                    

                    Er du villig til at investere 3 minutter dagligt i 3 måneder og få det bedre?   
                </v-card-text>

                <v-card-actions>
                    <div class="mx-auto">

                        <v-btn
                            dark
                            color="#4B788E" 
                            class="mb-2 white--text d-block" 
                            @click="CreateUser(true)"
                        >
                            Ja tak - jeg er klar
                        </v-btn>
                        <v-btn
                            class="d-block mx-auto"
                            style="word-break: normal; font-size:12px"
                            color="black"
                            @click="CreateUser(false)"
                            outlined
                            dark
                            text
                        >
                        Nej - ikke lige nu
                        </v-btn>
                    </div>
                
                </v-card-actions>
            </v-card>
        </v-dialog> -->
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "PublicCreateUserModuleSimplified",
  data: () => ({
    showDialog: false,
    password: "",
    confirmPassword: "",
    confirmEmail: "",
    errorMessages: "",
    consentPolicies: false,
    showPwdMessage: false,
    valid: false,
    showPassword: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    },
    roles: ["Freemium"],
    defaultItem: {
      name: "",
      email: "",
      role: "Freemium",
      created: "",
      updated: "",
      id: -1,
      consentBusinessPolicy: false,
      consentPrivacyPolicy: false,
      subscription: false,
    },
    item: {
      name: "",
      email: "",
      role: "Freemium",
      created: "",
      updated: "",
      id: -1,
      consentBusinessPolicy: false,
      consentPrivacyPolicy: false,
      subscription: false,
      vipId: "",
    },
  }),
  computed: {
    validate_form() {
      if (this.item.name == "") return false;
      if (this.item.email == "") return false;
      if (!this.consentPolicies) return false;
      return true;
    },
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  mounted() {
    this.item.vipId = this.$route.query.vipId;
  },
  methods: {
    CreateUser() {
      this.valid = this.validate_form;
      console.log("saving: " + this.valid);

      if (this.valid) {
        this.$store.commit("setLoading", true);

        if (this.roles.indexOf(this.item.role) < 0)
          // we either haven't given a password, and the role is somehow not equal to one of the predefined slots.
          return;

        if (this.editedIndex < 0) if (this.password == "") return;

        this.item.consentBusinessPolicy = this.consentPolicies;
        this.item.consentPrivacyPolicy = this.consentPolicies;
        this.item.password = this.password;
        this.item.created = moment.utc().unix();
        this.$store
          .dispatch("createNewUser", { user: this.item, router: this.$router })
          .then((response) => {
            this.$store.commit(
              "setAlertSuccessSnack",
              "Bruger oprettet korrekt."
            );
            console.log(response);

            setTimeout(() => {
              this.item = Object.assign({}, this.defaultItem);
              this.password = "";
              this.confirmPassword = "";
              this.confirmEmail = "";
              this.$emit("close");
              this.$router.push({
                path: "/Home?newUser",
              });
            }, 300);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => this.$store.commit("setLoading", false));
      }
    },
  },
};
</script>

<style lang="scss">
.sign-up-card {
  label {
    color: white;
  }
  label.alt-font {
    font-size: 1.2rem;
  }
  button:disabled {
    background-color: hsl(208, 22%, 20%);
  }
  button {
    border-radius: 4px;
  }
  .v-text-field.v-input--dense .v-input__prepend-inner .v-input__icon > .v-icon,
  .v-text-field.v-input--dense .v-input__append-inner .v-input__icon > .v-icon {
    margin-top: 0px;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px;
  }
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-header {
  padding-left: 0;
  padding-right: 0;
}

.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
