<template>
    <v-container>
        <v-row class="justify-center" style="padding: 10px">
            <v-card tile width="100%">
                <v-card-title class="justify-center">
                    <span>Du mangler at verificere din e-mail</span>
                </v-card-title>
                <v-card-text class="justify-center text-center pt-6">
                    <p>Har du ikke modtaget din verificerings e-mail?</p>
                    <v-btn
                        class="text-center"
                        color="primary"
                        tile
                        text
                        :disabled="loading"
                        @click="sendNewVerify"
                    >
                        Send ny
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "PromptVerifyEmail",
    data: () => ({
        token: "",
    }),
    mounted() {
        this.initialize();
    },
    computed: {
        ...mapState({
            loading: (state) => state.loading,
            loggedIn: (state) => !!state.auth.token,
            loggedInUser: (state) => state.auth.user,
        }),
    },
    methods: {
        async initialize() {
            if (!this.loggedIn) {
                window.location.href = "/";
                return;
            }

            await this.scheduleCheckJob();
        },

        async scheduleCheckJob() {
            while (!this.loggedInUser?.verifiedAt) {
                await this.$store.dispatch("GetMyUserInformation");
                await new Promise((r) => setTimeout(() => r(), 10000));
            }
        },

        async sendNewVerify() {
            await this.$store.dispatch("SendEmailVerification");
        },
    },
};
</script>

<style>
</style>