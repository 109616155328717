<template>
  <div class="Landing d-flex justify-center" style="padding:0px; width:100%; height:100%">
    <v-container class="flatContainer">
      <Banner/>
      <div class="contentWrap">
          <Sundhedsguide/>
          <!--<div class="vimeo-wrap">
              <iframe src="https://player.vimeo.com/video/453285377?app_id=122963"
                      class="landing-video"
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                      title="Introduktion"
                      data-ready="true"></iframe>
          </div>-->
      <Breaker/>
          <Articles />
      </div>
      <!-- <LinkSection/> -->
      <Footer/>
    </v-container>
  </div>
</template>

<script>
import Banner from "../components/Landing/Banner";
import Footer from "../components/Landing/Footer";
import Sundhedsguide from "../components/Landing/Sundhedsguide";
import Articles from "../components/Landing/Articles";
import Breaker from "../components/Landing/Breaker";
// import LinkSection from "../components/Landing/LinkSection";

export default {
  name: "Landing",
  data: () => ({}),
  components: {
    Banner,
    Footer,
    Sundhedsguide,
    Articles,
    Breaker,
    // LinkSection
    //vueVimeoPlayer
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
.Landing{


  p{ font-size: 18px; }
  h1 { font-size: 37px; }
  h2 { font-size: 30px; }
  h3 { font-size: 1.5rem;}
  h4 { font-size: 1.3rem }

  .flatContainer {
    max-width: 100% !important;
    padding: 0px !important;
  }

  .landingHeader {
    width: 100%;
    margin-left: 0vw !important;
    margin-right: 0vw !important;
  }

  
  .alt-font {
    font-family: "Oregano";
  }

  @media only screen and (max-width: 480px) {
    .blue-btn {
      display: block;
      width: 100%;
    }
    .vimeo-wrap {
      width: 100%;
    }

    .landing-banner {
      height: 529px;
    }
    .landing-banner .v-image__image {
      background-position: -120px center !important;
    }
  }
  @media only screen and (max-width: 599px) {
    .blue-btn {
      display: block;
      width: 100%;
    }
  }

  @media only screen and (min-width: 480px) and (max-width: 1150px) {
    .vimeo-wrap {
      width: 50%;
    }
  }
}
</style>