import { AdminUserEditForm } from "@/models/UserEditForm";
import axios, { AxiosRequestConfig } from "axios";
import { Module } from "vuex";
import { RootStore } from "..";

interface UsersState {
  users: any[];
  screeningResult: string | null;
}

const usersModule: Module<UsersState, RootStore> = {
  state: {
    users: [],
    screeningResult: null,
  },
  mutations: {
    addUser(state, user) {
      //used by the admin system.
      state.users.push(user);
    },
    removeUser(state, Idx) {
      state.users.splice(Idx, 1);
    },
    updateUser(state, obj) {
      const idx = state.users.findIndex((u) => u.id === obj.id);
      Object.assign(state.users[idx], obj);
    },
    setUsers(state, users) {
      state.users = users;
    },
    storeCurrentEditedUsersScreening(state, screeningResult) {
      state.screeningResult = screeningResult;
    },
  },
  getters: {
    getUserByItem: (state) => (item: any) => {
      const index = state.users.indexOf(item);
      return index;
    },
  },
  actions: {
    async getAllUsers({ commit, rootGetters, dispatch }) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };

      const response = await axios.get("/api/users/list", config);
      commit("setUsers", response.data.users);
    },
    async generateExport({ rootGetters, dispatch }) {
      await dispatch("refreshJWT", rootGetters.getJWT);

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
        responseType: "blob",
      };

      return await axios.get("/api/users/export", config);
    },
    async importInvite({ rootGetters, dispatch }, csv: File) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const formData = new FormData();
      formData.append("file", csv);

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
          "Content-Type": "multipart/form-data",
        },
      };

      return await axios.post("/api/users/import", formData, config);
    },
    async toggleVerified(
      { commit, rootGetters, dispatch },
      obj: { userId: number; verified: boolean }
    ) {
      await dispatch("refreshJWT", rootGetters.getJWT);

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `/api/users/set-verification/${obj.userId}/${obj.verified}`,
        null,
        config
      );
      if (response.status === 200) {
        commit("updateUser", response.data.newUser);
      }
    },
    async addUser({ commit, rootGetters }, user) {
      //used by the admin system.
      console.log("adding user");
      const config = {
        headers: {
          //anonymous user creation.
          Authorization: "Bearer " + rootGetters.getJWT,
          "Content-Type": "application/json",
        },
      };

      const data = {
        BirthDate: user.birthDate,
        Email: user.email,
        Gender: user.gender,
        Height: user.height ? parseInt(user.height) : 0,
        Journal: user.journal,
        Name: user.name,
        Password: user.password,
        TrialEnd: user.trialEnd && user.trialEnd.format("YYYY-MM-DD"),
        Weight: user.weight ? parseInt(user.weight) : 0,
        isAdmin: user.isAdmin,
      };
      console.log(data);

      return new Promise((resolve, reject) => {
        axios
          .post("/api/users/create", JSON.stringify(data), config)
          .then((response) => {
            if (response.status == 200) {
              commit("addUser", response.data);
              commit("setAlertSuccessSnack", "Bruger oprettet korrekt.");
              resolve(true);
            } else {
              resolve(false);
              commit("setAlertErrorSnack", "Bruger oprettelse fejlet.");
            }
          })
          .catch((err) => {
            commit("setAlertErrorSnack", err);
            reject(false);
          });
      });
    },
    async createNewUser({ commit, dispatch }, { user, router }) {
      //used by the anonymous user creation, if you're not logged in as admin.
      console.log("creating user");
      const config = {
        headers: {
          //anonymous user creation.
          "Content-Type": "application/json",
        },
      };

      const data = JSON.stringify({
        Name: user.name,
        Email: user.email,
        Password: user.password,
        Role: "Freemium",
        Updated: null,
        ConsentPrivacyPolicy: user.consentPrivacyPolicy,
        ConsentBusinessPolicy: user.consentBusinessPolicy,
        Journal: user.journal,
        GroupTag: user.vipId,
      });

      try {
        const response = await axios.post("/api/users/create", data, config);
        if (response.status != 200) {
          if (response.status == 403)
            commit(
              "setAlertErrorSnack",
              "Bruger eksistere allerede med denne email."
            );
          else commit("setAlertErrorSnack", "Bruger oprettelse fejlet.");
          return false;
        }
      } catch (err) {
        const str = "" + err;
        if (str.includes("403")) {
          commit(
            "setAlertErrorSnack",
            "Bruger eksistere allerede med denne email."
          );
        } else {
          commit("setAlertErrorSnack", err);
        }
        throw err;
      }

      const obj = {
        Email: user.email,
        Password: user.password,
        NewUser: true,
        router: router,
      };
      await dispatch("login", obj);
      return true;
    },
    async removeUser({ commit, rootGetters, dispatch }, obj) {
      console.log("removing user");
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        axios
          .delete("/api/users/delete/" + obj.id, config)
          .then(() => commit("removeUser", obj.idx));
      });
    },
    async updateUser(
      { commit, rootGetters, dispatch },
      model: AdminUserEditForm
    ) {
      console.log("updating user");
      await dispatch("refreshJWT", rootGetters.getJWT);

      //define auth headers.
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      console.log("updating user: ");

      const newModel = {
        ...model,
        birthDate: model.birthDate
          ? model.birthDate.format("YYYY-MM-DD")
          : null,
        trialEnd: model.trialEnd ? model.trialEnd.format("YYYY-MM-DD") : null,
      };
      console.log(newModel);

      try {
        //define and execute API call.
        const response = await axios.put(
          "/api/users/update/" + model.id,
          newModel,
          config
        );
        console.log(response);
        if (response.status === 200) {
          //success
          commit("updateUser", response.data.newUser);
          commit("setAlertSuccessSnack", "Brugeren blev opdateret");
          return true;
        } else {
          //failure
          console.log("something went wrong");
          commit(
            "setAlertErrorSnack",
            "Der opstod fejl ved opdatering af brugeren"
          );
          return false;
        }
      } catch (err: any) {
        console.log(err.response);
        if (err.response.status === 401) {
          //display error.
          commit("setAlertErrorSnack");
          commit("setAlertErrorSnack", err);
          console.log("Forbidden response - requesting new jwt");
        }
        return false;
      }
    },
    async GetUserLatestScreeningData({ rootGetters, dispatch }, userID) {
      console.log("Getting screening data.");
      return await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        return new Promise((resolve, reject) => {
          if (userID != -1) {
            //define auth headers.
            const config = {
              headers: {
                Authorization: "Bearer " + rootGetters.getJWT,
              },
            };

            axios
              .get("/api/users/screening/" + userID, config)
              .then((response) => {
                response.data.screeningResult.screeningValues =
                  response.data.screeningValues;
                resolve(response.data.screeningResult);
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          }
        }); //end of promise
      });
    },
    async PreviewScreeningUpdate({ rootGetters, dispatch }, obj) {
      console.log("computing preview data");
      return await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        return new Promise((resolve, reject) => {
          const config = {
            headers: {
              Authorization: "Bearer " + rootGetters.getJWT,
              "Content-Type": "application/json",
            },
          };
          console.log(obj.screeningResult.changedItems); //save = true
          axios
            .post(
              "/api/users/screening/update/weights/" + obj.userID + "/false",
              obj.screeningResult.changedItems,
              config
            )
            .then((response) => {
              response.data.screeningResult.screeningValues =
                response.data.screeningValues;
              resolve(response.data.screeningResult);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        });
      });
    },
    async SaveScreeningUpdate({ rootGetters, dispatch }, obj) {
      console.log("computing preview data");
      return await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        return new Promise((resolve, reject) => {
          const config = {
            headers: {
              Authorization: "Bearer " + rootGetters.getJWT,
              "Content-Type": "application/json",
            },
          };
          console.log(obj.screeningResult.changedItems); //save = false.
          axios
            .post(
              "/api/users/screening/update/weights/" + obj.userID + "/true",
              obj.screeningResult.changedItems,
              config
            )
            .then((response) => {
              response.data.screeningResult.screeningValues =
                response.data.screeningValues;
              resolve(response.data.screeningResult);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        });
      });
    },
    async AdminOverridePassword(
      { commit, rootGetters, dispatch },
      obj: { userId: number; password: string }
    ) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
          "Content-Type": "application/json",
        },
      };
      console.log(obj);
      const body = JSON.stringify({ Password: obj.password });

      try {
        const response = await axios.put(
          "/api/users/updatePassword/" + obj.userId,
          body,
          config
        );
        commit("setAlertSuccessSnack", "Password opdateret.");
        console.log(response);
      } catch (error: any) {
        commit("setAlertErrorSnack", error.response && error.response.data);
        console.log(error.response);
      }
    },
    async sendMailVerification({ commit, rootGetters, dispatch }, userId) {
      try {
        await dispatch("refreshJWT", rootGetters.getJWT);
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };
        await axios.post(
          "/api/users/email-verification/" + userId,
          undefined,
          config
        );
        commit("setAlertSuccessSnack", "Email verificering blev sendt");
      } catch (error: any) {
        commit("setAlertErrorSnack", error.response && error.response.data);
      }
    },
    async sendMailResetPassword({ commit, rootGetters, dispatch }, userEmail) {
      try {
        axios
          .post("/api/Login/RequestPasswordChange", { Email: userEmail })
          .then(() => {
            this.commit("setAlertSuccessSnack", "Email er sendt af sted.");
          });
      } catch (error: any) {
        commit("setAlertErrorSnack", error.response && error.response.data);
      }
    },
  },
};

export default usersModule;
