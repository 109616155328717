<template>
  <v-row class="PrivacyPolicy">
    <v-img height="200px" contain src="@/assets/logo.png" class="mt-12 mb-0" />
      <div class="contentWrap">
        <v-col cols="12" id="scriptTag">
            
        </v-col>
      </div>
      <Footer></Footer>
  </v-row>
</template>

<script>// @ is an alias to /src
import Footer from "../components/Landing/Footer";
import postscribe from 'postscribe'

export default {
  name: 'Cookies',
  components: {
    Footer,
  },
  mounted: function () {
    /* eslint-disable no-useless-escape */
    const tag = "<script id='CookieDeclaration' src='https://consent.cookiebot.com/667766be-2312-44dd-9da1-223ad07fb410/cd.js' type='text/javascript' async><\/script>";
    postscribe('#scriptTag', tag);
  }
}
</script>
<style lang="scss">
.PrivacyPolicy{
  width:100%;
  span.heading {
    font-weight: bold;
    display: block;
    font-size: 20px;
    padding: 20px 0;
  }
  span.PPtitle {
    font-weight: bold;
    display: block;
    font-size: 25px;
    padding: 20px 0;
  }
  span.heading-inline {
    font-weight: bold;
  }
  .contentWrap{
    max-width: 1200px;
    margin: 0 auto;
    padding: 2.75rem;
  }
  ul li {
    padding: 10px 0;
  }
  ul {
    margin: 30px 0;
  }
}
</style>
