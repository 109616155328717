<template>
    <v-container>
        <v-row style="padding: 10px;">
            <v-col cols="12">
                <v-data-table :headers="headers"
                              :items="contents"
                              :footer-props="{ 'items-per-page-options': [5, 15, 25, 35, 99] }"
                              :items-per-page="99"
                              class="elevation-1 list"
                              id="list">

                    <template v-for="(clickSlot, i) in clickableSlots" v-slot:[`item.${clickSlot.field}`]="{ item }">
                        <div v-bind:key="i"
                             v-on:click=clickableSlotClicked(clickSlot,item)
                             style="color: -webkit-link; cursor: pointer; text-decoration: underline;">
                            <div v-if="clickableSlotShowStaticLabel(clickSlot, item)">
                                <div :title="item[clickSlot.field]">{{ clickSlot.label }}</div>
                            </div>
                            <div v-if="!clickableSlotShowStaticLabel(clickSlot, item)">
                                {{ item[clickSlot.field] }}
                            </div>
                        </div>
                    </template>

                    <template v-for="(linkSlot, i) in vimeoLinkSlots" v-slot:[`item.${linkSlot.field}`]="{ item }">
                        <v-btn v-if="getLinkSlotLink(linkSlot, item) != 'no link' && getLinkSlotLink(linkSlot, item) != null"
                               v-bind:key="i"
                               icon
                               v-bind:href="'' + getLinkSlotLink(linkSlot, item) + ''"
                               target="_blank">
                            Link
                        </v-btn>
                        <div v-else
                             v-bind:key="i">
                            <v-btn v-if="loggedInUser.role == 'Admin'"
                                   v-bind:key="i + 100000"
                                   color="primary"
                                   :disabled="getLinkSlotDisabled(linkSlot, item)"
                                   @click.stop="vimeoLinkSlotClicked(linkSlot, item)">
                                Hent Link
                            </v-btn>
                            <div v-else>
                                Intet link
                            </div>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'List',
        props: ['clickableSlots', 'contents', 'headers', 'isDisabled', 'loading', 'vimeoLinkSlots'],
        computed: {
            ...mapState({
                loggedInUser: (state) => state.auth.user,
            }),
        },
        methods: {
            getShownElemsCount(elem) {
                return elem.shownElemsCount >= 1 ? elem.shownElemsCount : 1;
            },
            clickableSlotClicked(slot, item) {
                slot.action(item);
            },
            clickableSlotShowStaticLabel(slot, item) {
                if (!slot.showStaticLabel || !slot.label) {
                    return false;
                }

                if (!item.videoLink) {
                    return false;
                }

                return true;
            },
            getLinkSlotLink(slot, item) {
                return item[slot.field];
            },
            getLinkSlotDisabled(slot, item) {
                if (item[slot.disableField]) {
                    return false;
                }
                return true;
            },
            vimeoLinkSlotClicked(slot, item) {
                slot.action(item);
            },
        },
    }

</script>

<style scoped>
    .list {
        box-shadow: none !important;
        border-radius: 0;
        border-bottom: 5px solid #738ea6;
    }

    .list::v-deep > div > table > thead > tr > th {
        border-bottom: 5px solid #738ea6!important;
    }

    .list::v-deep > div > table > tbody > tr > td {
        border-bottom: 5px solid transparent!important;
    }

    ::v-deep .v-data-footer {
        display: none;
    }
</style>