<template>
    <v-row class="PrivacyPolicy">
        <v-img height="200px" contain src="@/assets/logo.png" class="mt-12 mb-0" />
        <div class="contentWrap">
            <v-col cols="12">
                <div>
                    <span class="PPtitle">Fortrolighedspolitik til Din SundhedsGuide</span>
                    <i>Pr. 1. marts 2021</i>
                    <br><br>
                    <p>
                      Vores fortrolighedspolitik beskriver, hvilke personlige data vi indsamler, og hvordan vi bruger og
                      deler de oplysninger. Vi anbefaler dig at gennemgå vores fortrolighedspolitik.
                      Hvis du har spørgsmål om måden, vi behandler eller beskytter dine personlige data på, så
                      kontakt os venligst via kontakt@dinsundhedsguide.dk.
                    </p>
                    <p>
                      VED TEGNING AF ABONNEMENT TIL KØB ELLER BRUG AF VORES SERVICES ELLER
                      VARER, GIVER DU SAMTYKKE TIL VORES FORTROLIGHEDSPOLITIK, HVOR
                      INDSAMLINGEN, BRUGEN OG TRANSMITTERINGEN AF DINE PERSONLIGE DATA TIL
                      BRUG I INTERN ANALYSE SOM BESKREVET HERUNDER.
                    </p>
                    <h2>Hvem er vi.</h2>
                    <p>
                      Din SundhedsGuide tilbyder en mobilapplikations-baseret service via app til Android og iOS.
                      Derudover har vi app-udviklere tilknyttet, som er en del af vores team.
                    </p>
                    <h2>
                      Oplysninger om dig.
                    </h2>
                    <p>
                      Din SundhedsGuide indsamler oplysninger for at drive vores forretning og for at give dig det
                      mest individuelle og tilpassede indhold og materiale i vores app. Vi indsamler både personlige
                      data og andre oplysninger til dette formål. Se nedenfor. "Personlige Data" er oplysninger, der
                      kan blive brugt direkte eller indirekte, alene eller sammen med andre oplysninger, til at
                      identificere dig som en individuel bruger. Data fra din Krops-Screening omkring din
                      sundhedstilstand, udvikling og informationer om din progression og vaner. Her er nogle
                      eksempler på vores dataindsamling, som du giver os adgang til, når du opretter dig som bruger
                      og udfylder din 360 ° Krops-screening og anvender vores app.
                    </p>
                    <ol>
                      <li>Oplysning om din e-mail og adgangskode.</li>
                      <li>Oplysninger om højde, vægt, kropsmål og alder.</li>
                      <li>Oplysninger om dit aktivitetsniveau.</li>
                      <li>Oplysninger om diagnoser og uddybende informationer om din kropstilstand.</li>
                      <li>Data fra din brug af app´en og din udvikling af smerte-niveau, søvnmønster og energiniveau.</li>
                      <li>
                        Din ip adresse samt andre browser informationer ved kommunikation mellem din app og
                        vores server og ”Typeform”. "Andre Oplysninger" er oplysninger, der er anonyme, men
                        koblet op på din profil, eller på anden vis ikke afslører din identitet. Nogle eksempler
                        kunne være alder, køn, browser eller styresystem, eller tid brugt på vores services. Vi
                        indsamler disse data for at kunne tilpasse kommunikationen mellem dig og os, så vi kan
                        guide dig bedst muligt. Vi forbedrer og bygger features løbende, hvor vi optimerer
                        brugervenligheden og din oplevelse som bruger og tilgodeser dine behov som bruger.
                        Informationer derudover opfattes som uidentificerbare og anvendes ikke i sammenhold
                        med dine persondata uden at de opfattes som personlige data.
                      </li>
                    </ol>
                    <h2>Hvordan vi benytter personlige data.</h2>
                    <p>
                      Vi kan bruge de oplysninger vi indsamler til at drive vores forretning, annoncere og udvikle vores
                      produkt og brugeroplevelse, så vi kan tilpasse og hjælpe flest mulige mennesker.
                    </p>
                    <p>
                      Herunder eksempler på, hvordan vi anvender personlige data:
                    </p>
                    <p>
                      (a) Sikre it-strukturen og udvikling af app´en løbende: Vi bruger, de data vi indsamler, til at
                      levere de services, vi tilbyder. Dette omfatter at levere tilpasset indhold, vedligeholde og
                      opdatere indhold/funktioner af vores features – herunder kurativt indhold tilpasset til din
                      situation, så du er sikret de rigtige øvelser til din kropstilstand. Vi anvender også personlige data
                      til bedre at forstå vores brugere. Dette hjælper os med at udvikle, planlægge og designe nye
                      produkter, services, funktioner og funktionalitet, som vores brugere har behov for og ønsker. Vi
                      bruger også data, vi indsamler, til intern virksomhedsdrift. For eksempel, bruger vi data til bedre
                      at forstå vores forretning, analysere vores drift, forbedre vores services, og til at udvikle nye
                      produkter og services, for at understøtte din sundhed.
                    </p>
                    <p>
                      (b) Understøtte udviklingen af den sundhedsfaglige praksis: Vi og vores forretningspartnere,
                      klinikker, forsikringsselskaber eller 2 virksomheder kan bruge de personlige data og andre
                      oplysninger, vi indsamler, til at foretage kommerciel eller akademisk forskning om demografi,
                      interesser og forbrugerpræferencer, for at få indsigt i vores brugere, produkterne og de services,
                      vores brugere anvender, og til at udvikle innovative produkter og services til vores brugere.
                      Denne analyse kan blive brugt og delt uden for Din SundhedsGuide. Hvis din adgang er betalt
                      af andre end dig selv, giver vi tilladelse til, at dine data indgår i evt. analyser eller statistikker. Vi
                      kan give andre samarbejdspartnere adgang til din oplysninger ved at vi gør dem uidentificerbare
                      uden personificering.
                    </p>
                    <p>
                      (c) Kommunikere med dig og besvare dine anmodninger: Vi kan bruge dine personlige data til at
                      besvare dine anmodninger om teknisk support, online services, produktinformation, eller til
                      enhver anden kommunikation, du indleder. Dette inkluderer adgang til din konto for at
                      efterkomme anmodninger om teknisk support. Vær opmærksom på, at uanset dine
                      e-mailindstillinger, kan vi sende dig meddelelser vedrørende udførelsen af services, såsom
                      revision af vores betingelser eller denne fortrolighedspolitik, eller andre formelle
                      kommunikationer vedrørende vores produkter eller services, du har købt eller brugt.
                    </p>
                    <h2>
                      Sådan håndteres personlige data.
                    </h2>
                    <p>
                      Din SundhedsGuide videregiver kun dine personlige data med dit kendskab, og som angivet i
                      denne fortrolighedspolitik, eller som indikeret ved tidspunktet af indsamlingen. Dette inkluderer
                      oplysninger, der gør det muligt for os at opfylde vores forpligtelser over for dig. Vi kan videregive
                      dine personlige data som beskrevet i denne fortrolighedspolitik, og som beskrevet på følgende
                      måder:
                    </p>
                    <p>
                      (a) I forbindelse med ændringer i vores virksomhedsstruktur.
                      Ved evt. fusion med en anden virksomhed, eller hvis Din SundhedsGuide skulle beslutte sig for
                      at købe, sælge eller reorganisere dele af eller alle dens forretninger, kan vi oplyse eller overføre,
                      i det omfang det lovligt er tilladt og i overensstemmelse med gældende krav om at underrette
                      dig, dine personlige data til forventede eller egentlige købere eller den efterfølgende enhed i
                      forbindelse med en af disse transaktioner eller reorganisationer.
                    </p>
                    <p>
                      (b) Lovgivning og særlige omstændigheder.
                      Vi kan være tvunget til at videregive dine personlige data hvis: (i) det er rimelig nødvendigt for at
                      overholde juridiske forløb (såsom retskendelse, stævning, ransagningskendelse, osv.) eller
                      andre lovkrav fra enhver offentlig myndighed, (ii) sådan en videregivelse ville potentielt mindske
                      vores ansvar i en egentlig eller potentiel retssag, (iii) det er nødvendigt for at beskytte vores
                      juridiske rettigheder eller ejendom, eller (iv) det er nødvendigt for at beskytte de juridiske
                      rettigheder eller ejendomme eller andres fysiske sikkerhed, eller til forebyggelsen eller
                      opdagelsen af forbrydelse og sådan en videregivelse er retmæssig.
                    </p>
                    <p>
                      (c) Udveksling af informationer med andre virksomheder.
                      <ul>
                        <li>
                          Vi udveksler anonymiserede informationer omkring brug af app og hjemmeside med
                          følgende virksomheder: Google Analytics, Google Firebase – Google LLC (USA);
                          Facebook, Inc. (USA) og Branch.io (USA): disse virksomheder hjælper os med at
                          evaluere og analysere produkt- og forbrugertendenser samt at evaluere effekten af f.eks.
                          reklamekampagner gennem Google og Facebook.
                        </li>
                        <li>
                          Vi udveksler informationer omkring kreditkort samt adresse med Nets og QuickPay, hvis
                          du vælger at købe personlige vejledning eller forløb. Vær opmærksom på, at Din
                          SundhedsGuide ikke opbevarer informationer omkring dit kreditkort, da vi din betaling
                          foregår hos Stripe.
                        </li>
                      </ul>
                    </p>

                    <h2>Informationssikkerhed.</h2>
                    <p>
                      Vi - Din SundhedsGuide er forpligtet til at beskytte sikkerheden af dine personlige data. Vi
                      bruger tekniske og organisatoriske foranstaltninger designet til at beskytte dine oplysninger mod
                      uautoriseret adgang, tyveri, og tab. Vi bruger kryptering (HTTPS/TLS) til at beskytte data
                      transmitteret til og fra vores websted og app. Vi anbefaler også, at du tager yderligere
                      foranstaltninger for at beskytte dig selv og dine oplysninger, såsom at installere antivirus
                      software, lukke browsere efter brug, holde dine login informationer og passwords hemmelige,
                      og at sørge for du regelmæssigt opdaterer software og apps, du har downloadet, for at sikre dig,
                      at du har aktiveret de seneste sikkerhedsfunktioner på dine enheder.                      
                    </p>

                    <h2>Opbevaring af Data.</h2>
                    <p>
                      Din SundhedsGuide opbevarer dine personlige data, så længe du fører en konto, eller så
                      længe, det er nødvendigt for at levere vores services til dig. Vi beholder og bruger også dine
                      personlige data, som er nødvendige for at overholde juridiske forpligtelser, løse konflikter, og
                      håndhæve vores aftaler. Vi opbevarer højest dine data i 10 år. Vi bruger dataindsamlings
                      værktøjer og online annoncering og bruger cookies for at indsamle data, der hjælper os med at
                      personliggøre din brug af vores services.
                    </p>
                    <p>
                      (a) Typer af cookies vi bruger: Vi benytter flere forskellige typer af cookies:
                      <ol>
                        <li>
                          Påkrævet. Strengt nødvendige cookies: Disse cookies er nødvendige for driften af vores
                          services. Uden dem ville du eksempelvis ikke være i stand til at registrere dig hos os
                          eller logge ind med din bruger.
                        </li>
                        <li>
                          Funktionelle. Analytiske-/ydeevne-cookies: Disse cookies gør det muligt for os at
                          genkende og tælle antallet af besøgende og se, hvordan besøgende bevæger sig rundt i
                          app’en. De hjælper os med at forbedre måden vores hjemmesider virker på, ved for
                          eksempel at sikre at brugere let finder det, de leder efter, og ikke støder på tekniske
                          problemer. Funktionalitets-Cookies: Disse bruges til at genkende dig, når du vender
                          tilbage til vores hjemmeside. Brugen af denne type cookies gør det muligt for os at
                          personliggøre vores indhold til dig og huske dine præferencer (for eksempel dit valg af
                          sprog eller region).
                        </li>
                      </ol>
                    </p>

                    <h2> Fravalg af cookies og interessebaserede annoncer.</h2>
                    <p>
                      Hvis du ikke ønsker cookies, kan du indstille din computer til at (a) advare dig hver gang en
                      cookie bliver sendt. (b) deaktivere alle cookies (ud over Flash-cookies) gennem din browser.
                      Du kan finde mere information omkring hvordan du administrerer dine cookie-indstillinger
                      gennem din browser på www.aboutcookies.org.
                    </p>

                    <h2>Børn.</h2>
                    <p>
                      Vi indsamler ikke bevidst personlige data fra børn under 13, selvom der ikke er nogen nedre
                      grænse for brugen af vores app. Hvis du bliver opmærksom på, at et barn har forsynet os med
                      personlige data uden forældrenes samtykke, så kontakt os venligst på
                      kontakt@dinsundhedsguide.dk. Hvis vi bliver opmærksom på, at et barn under 13 har forsynet
                      os med personlige data uden forældrenes samtykke, vil vi fjerne informationen og slette barnets
                      konto.
                    </p>

                    <h2>Den registreredes rettigheder</h2>
                    <p>
                      Beboere i Det Europæisk Økonomiske Samarbejdsområde: Hvis du vælger ikke at levere
                      personlige data. Du kan vælge ikke at forsyne Din SundhedsGuide med dine personlige data.
                      For at gøre dette skal du skrive en e-mail til kontakt@dinsundhedsguide.dk. Hvis du vælger ikke
                      at levere dine personlige data, deaktiveres app’ens indhold, da vi ikke kan stå inde for indholdet
                      uden dine data.
                    </p>
                    <p>
                      Hvordan man anmoder om adgang. Din SundhedsGuide sørger for at ajourføre dine personlige
                      data. Hvis du bor i Det Europæisk Økonomiske Samarbejdsområde, kan du anmode om adgang
                      til, og modificering, opposition og sletning af de personlige data, vi har indsamlet om dig. For at
                      få adgang til dine personlige data, kontakt os venligst via kontakt@dinsundhedsguide.dk.
                      Underlagt gældende lovgivning, kan vi opkræve betaling for denne service og vil efterkomme
                      rimelige anmodninger hurtigst muligt, og under alle omstændigheder inden for fristerne
                      foreskrevet ved lov
                    </p>
                    <p>
                      Serviceopdateringer, ændringer og begrænsninger: Vores services ændrer sig regelmæssigt, og
                      deres form og funktion kan ændre sig uden forudgående varsel.
                    </p>
                    <p>
                      Tredjeparter vi ikke ejer eller styrer. Vær opmærksom på, at denne fortrolighedspolitik ikke
                      gælder praksis for virksomheder, vi ikke ejer eller styrer, eller folk, vi ikke har ansat eller
                      administrerer. For eksempel, hvis du downloader en af vores applikationer på din smartphone,
                      kan producenten af din smartphone have en politik, der gælder dens egen praksis for
                      indsamling af oplysninger gennem din smartphone. Vi anbefaler, at du læser
                      fortrolighedspolitikkerne for hver enkelt enhed, hjemmeside og service, du bruger.
                    </p>
                    <b>Ret til indsigt</b>
                    <p>
                      Du har ret til at se de personoplysninger, den vi behandler om dig, og få en række oplysninger
                      om behandlingen.
                    </p>
                    <b>Ret til berigtigelse</b>
                    <p>
                      Du har ret til at få urigtige/forkerte personoplysninger om dig rettet.
                    </p>
                    <b>Ret til sletning</b>
                    <p>
                      Du har som udgangspunkt ret til at få slettet dine personoplysninger, hvis én af en række
                      betingelser, der nævnes i databeskyttelsesforordningen, er opfyldt.
                    </p>
                    <b>Ret til begrænsning af behandling</b>
                    <p>
                      Du har ret til at få begrænset behandlingen af dine personoplysninger, hvis én af en række
                      betingelser er opfyldt.
                    </p>
                    <b>Ret til oplysning</b>
                    <p>
                      Du har ret til at modtage en række oplysninger, når virksomheder, myndigheder og
                      organisationer behandler oplysninger om dig.
                    </p>
                    <b>Ret til dataportabilitet</b>
                    <p>
                      Du har i visse tilfælde ret til at modtage dine personoplysninger og til at anmode om, at
                      personoplysningerne overføres fra én dataansvarlig til en anden.
                    </p>
                    <b>Ret til indsigelse</b>
                    <p>
                      Du har ret til at gøre indsigelse mod en ellers lovlig behandling af dine personoplysninger.
                    </p>
                    <b>Ret til ikke at være genstand for en automatisk afgørelse</b>
                    <p>
                      Du har ret til ikke at være genstand for en automatisk afgørelse udelukkende baseret på
                      automatisk behandling, herunder profilering.
                    </p>
                    <p>
                      Du kan læse mere om dine rettigheder på:
                      <br>
                      <a href="https://www.datatilsynet.dk/borger/hvad-er-dine-rettigheder">https://www.datatilsynet.dk/borger/hvad-er-dine-rettigheder</a>
                    </p>

                    <h2>Hvordan du kan kontakte os.</h2>
                    <p>
                      Hvis du har nogle spørgsmål, kommentarer eller bekymringer om måden vi håndterer dine
                      personlige data på, kan du kontakte os ved at sende en e-mail til
                      kontakt@dinsundhedsguide.dk.
                    </p>

                    <h2>Opdateringer af Fortrolighedspolitik.</h2>
                    <p>
                      Når denne fortrolighedspolitik fra tid til anden uden forudgående varsel ændre, så kan du se,
                      hvornår denne politik sidst blev ændret, ved at tjekke ikrafttrædelsesdatoen. Vi anbefaler dig
                      periodisk at gennemgå denne fortrolighedspolitik, for at være sikker på du forstår vores
                      fortrolighedspraksis. Hvis du har en registreret konto, kan vi underrette dig om ændringer til
                      vores fortrolighedspolitik via e-mail, og vi kan bede dig om at bekræfte, anerkende og samtykke
                      ændringerne, næste gang du bruger vores services.
                    </p>
                    
                    <h2>Kontakt.</h2>
                    <p>
                      Din SundhedsGuide
                    </p>
                    <p>
                      kontakt@dinsundhedsguide.dk
                    </p>
                    <p>
                      CVR: 41961651
                    </p>
                </div>
            </v-col>
        </div>
        <Footer></Footer>
    </v-row>
</template>

<script>// @ is an alias to /src
import Footer from "../components/Landing/Footer";

export default {
  name: 'PrivacyPolicy',
 components: {
    Footer,
  },
}
</script>
<style lang="scss" scoped>
h2 {
    font-size: 1.2;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    &::before {
        counter-increment: section;
        content: counter(section) ". " 
    }
}
.PrivacyPolicy{
  counter-reset: section;
  width:100%;
  span.heading {
    font-weight: bold;
    display: block;
    font-size: 20px;
    padding: 20px 0;
  }
   span.PPtitle {
    font-weight: bold;
    display: block;
    font-size: 25px;
    padding: 20px 0;
  }
  span.heading-inline {
    font-weight: bold;
  }
  .contentWrap{
    max-width: 1200px;
    margin: 0 auto;
    padding: 2.75rem;
  }
  li {
    padding: 5px 2px;
  }
  ul, ol {
    margin: 10px 0;
  }
}
</style>
