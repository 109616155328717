<template>
    <div>
        <v-img max-height="600" alt="Stine Lee banner" class="justify-right banner black" src="@/assets/landingBanner4.jpeg">
            <v-row class="b-wrap justify-right pa-sm-6"
                   style="height: 100%; align-items: flex-end;">

                <v-col class="offset-sm-6 offset-lg-7 pa-0" cols="12" sm="6" lg="4" xl="3">
                    <PublicCreateUserModuleSimplified></PublicCreateUserModuleSimplified>
                </v-col>
            </v-row>
        </v-img>
    </div>
</template>

<script>
    import PublicCreateUserModuleSimplified from "../PublicCreateUserModuleSimplified";

    export default {
        name: 'landingBanner',
        components: {
            PublicCreateUserModuleSimplified,
        },
    }
</script>
<style lang="scss" scoped>
.banner{
  .b-wrap {
    /*max-width: 1200px;*/
    margin: 0 auto;
    /*padding: 2.75rem 0;*/
  }

    /*padding: 0 50.5px;*/
    /*height: 634px;*/
    background-position: center center;
}

</style>