<template>
  <v-card
    tile
    :loading="loading"
    width="100vw"
    min-width="300px"
    :disabled="isDisabled"
  >
    <v-card-title v-if="!isDisabled">
      <v-btn
        v-if="canAddOrRemove"
        tile
        color="primary"
        class="mb-2"
        @click="addClicked"
        :disabled="loading"
        style="margin-right: 10px"
        ><h2>+</h2></v-btn
      >
      {{ title }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="label"
        single-line
        hide-details
      >
      </v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="contents"
      :footer-props="{
        'items-per-page-options': [5, 15, 25, 35, 99],
      }"
      :items-per-page="99"
      class="elevation-1"
      :item-class="itemStyle"
      :search="search"
      :group-by="groupBy"
      :show-group-by="showGrouping"
    >
      <template v-slot:[`item.priority`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.priority"
          @save="savePriorityEdit(item)"
          @cancel="cancelPriorityEdit(item)"
        >
          {{ item.priority }}
          <template v-slot:input>
            <v-text-field
              type="number"
              v-model="item.priority"
              :rules="[ensureInt]"
              label="Edit"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template
        v-for="(slot, i) in chipSlots"
        v-slot:[`item.${slot.field}`]="{ item }"
      >
        <div
          v-for="(elem, j) in item[slot.field].slice(
            0,
            getShownElemsCount(slot) + 1
          )"
          :key="i.toString() + '.' + j.toString()"
          style="display: table-cell; padding-right: 2px"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-if="j < getShownElemsCount(slot)"
                v-bind="attrs"
                v-on="on"
                outlined
                :style="`display: flex; justify-content: center; width: ${slot.shownElemsWidth}`"
              >
                <div style="overflow: hidden; text-overflow: ellipsis">
                  {{ elem[slot.propertyToShow] }}
                </div>
              </v-chip>
            </template>
            <span>{{ elem[slot.propertyToShow] }}</span>
          </v-tooltip>

          <v-chip
            v-if="j == getShownElemsCount(slot)"
            outlined
            style="width: auto"
          >
            {{
              "+" +
              (item[slot.field].length - getShownElemsCount(slot)).toString()
            }}
          </v-chip>
        </div>
      </template>

      <template
        v-for="(clickSlot, i) in clickableSlots"
        v-slot:[`item.${clickSlot.field}`]="{ item }"
      >
        <div
          :key="i"
          @click="clickableSlotClicked(clickSlot, item)"
          style="
            color: -webkit-link;
            cursor: pointer;
            text-decoration: underline;
          "
        >
          {{ item[clickSlot.field] }}
        </div>
      </template>

      <template
        v-for="(linkSlot, i) in vimeoLinkSlots"
        v-slot:[`item.${linkSlot.field}`]="{ item }"
      >
        <div :key="i" v-if="hasLink(linkSlot, item)">
          <v-btn
            v-if="getLinkSlotLink(linkSlot, item) != 'no link'"
            icon
            :href="'' + getLinkSlotLink(linkSlot, item) + ''"
            target="_blank"
          >
            Link
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="getLinkSlotDisabled(linkSlot, item)"
            @click.stop="vimeoLinkSlotClicked(linkSlot, item)"
          >
            Hent Link
          </v-btn>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <template v-if="!item.dateDeleted">
          <v-icon
            medium
            color="primary"
            class="mr-2"
            @click="editClicked(item)"
            :disabled="loading"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="canDuplicate"
            medium
            color="primary"
            class="mr-2"
            @click="duplicateClicked(item)"
            :disabled="loading"
          >
            mdi-content-duplicate
          </v-icon>
          <v-icon
            v-if="canAddOrRemove"
            medium
            color="primary"
            class="mr-2"
            @click="deleteClicked(item)"
            :disabled="loading"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="hasStats"
            medium
            color="primary"
            class="mr-2"
            @click="statsClicked(item)"
            :disabled="loading"
          >
            mdi-chart-bar-stacked
          </v-icon>
        </template>
      </template>
      <template #footer v-if="canAddOrRemove">
        <v-toolbar flat color="white">
          <v-btn
            tile
            color="primary"
            class="mb-2"
            @click="addClicked"
            :disabled="loading"
            ><h2>+</h2></v-btn
          >
        </v-toolbar>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "List",
  props: [
    "title",
    "label",
    "contents",
    "headers",
    "loading",
    "canAddOrRemove",
    "canDuplicate",
    "groupBy",
    "showGrouping",
    "hasStats",
    "chipSlots",
    "clickableSlots",
    "isDisabled",
    "vimeoLinkSlots",
  ],
  data: () => ({
    search: "",
    ensureInt: (v) => /^[0-9]*$/.test(v) || "Indtast kun hele tal",
  }),
  methods: {
    itemStyle(item) {
      return item.dateDeleted ? "faded" : "";
    },
    savePriorityEdit(item) {
      this.$emit("updatePriority", item);
    },
    editClicked(item) {
      this.$emit("editClicked", item);
    },
    deleteClicked(item) {
      this.$emit("deleteClicked", item);
    },
    addClicked() {
      this.$emit("addClicked");
    },
    statsClicked(item) {
      this.$emit("statsClicked", item);
    },
    duplicateClicked(item) {
      this.$emit("duplicateClicked", item);
    },
    getShownElemsCount(elem) {
      return elem.shownElemsCount >= 1 ? elem.shownElemsCount : 1;
    },
    clickableSlotClicked(slot, item) {
      slot.action(item);
    },
    getLinkSlotLink(slot, item) {
      return item[slot.field];
    },
    getLinkSlotDisabled(slot, item) {
      if (item[slot.disableField]) {
        return false;
      }
      return true;
    },
    hasLink(slot, item) {
      return item[slot.field] != null && item[slot.field] != "";
    },
    vimeoLinkSlotClicked(slot, item) {
      slot.action(item);
    },
  },
};
</script>

<style>
.faded {
  opacity: 0.5;
}
</style>
