import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Categories from '../views/Categories.vue'
import Cookies from '../views/Cookies.vue'
import Exercises from '../views/Exercises.vue'
import Groupings from '../views/Groupings.vue';
import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'
import OnlineCourses from '../views/OnlineCourses.vue'
import OpretAbonnement from '../views/OpretAbonnement.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Subscriptions from '../views/Subscriptions.vue'
import TermsOfService from '../views/TermsOfService.vue'
import Users from '../views/Users.vue'
import Verify from '../views/Verify.vue'

Vue.use(VueRouter)

//import store from "../store/index.js"

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'Landing',
        component: Landing
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Users',
        name: 'Users',
        component: Users,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Subscriptions',
        name: 'Subscriptions',
        component: Subscriptions,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Categories',
        name: 'Categories',
        component: Categories,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Exercises',
        name: 'Exercises',
        component: Exercises,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/OnlineCourses',
        name: 'OnlineCourses',
        component: OnlineCourses,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/Groupings',
        name: 'Groupings',
        component: Groupings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/terms-of-service',
        name: 'TermsOfService',
        component: TermsOfService
    },
    {
        path: '/Fortrolighedspolitik',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/Privatlivspolitik',
        redirect: to => 'Fortrolighedspolitik',
        component: PrivacyPolicy
    },
    {
        path: '/Handelsbetingelser',
        redirect: to => 'terms-of-service',
        component: PrivacyPolicy
    },
    {
        path: '/Cookies',
        name: 'Cookies',
        component: Cookies
    },
    {
        path: '/OpretAbonnement',
        name: 'OpretAbonnement',
        component: OpretAbonnement
    },
    {
        path: '/Verify',
        name: 'Verify',
        component: Verify
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
