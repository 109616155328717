<template>
    <div>
        <div class="OpretAbonnement" v-if="isLoggedIn === true" style="padding:10px;">
            <v-card  tile :loading="loading" width="100vw" min-width="300px">
                <v-card-title>
                    Abonnement
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <HomeSubscriptionManager :user="me"></HomeSubscriptionManager>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <div class="OpretAbonnement" v-else style="padding:10px;">
            <Login login_path="/OpretAbonnement"></Login>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import HomeSubscriptionManager from '../components/Home/HomeSubscriptionManager'
    import Login from '../components/Login'
    export default {
        name: 'Home',
        components: {
            HomeSubscriptionManager,
            Login
        },
        data: () => ({
            tabShowing: 'account'
        }),
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn;
            },
            ...mapState({
                loggedInUser: (state) => state.auth.user,
                me: (state) => state.me.me,
                loggedIn: (state) => !!state.auth.token,
                loading: (state) => state.loading,
                isSubscribed: (state) => state.me.isSubscribed
            })
        },
        mounted() {
            this.initialize();
        },
        methods:{
            initialize() {
                if (!this.loading) {
                    this.$store.commit('setLoading', true);
                    this.$store.dispatch('GetMyUserInformation')
                        .then(() => {
                            //if (!this.isSubscribed && this.isLoggedIn.role != "Admin") {
                            //    //this.tabShowing = 'subscription';
                            //}
                            this.$store.commit('setLoading', false);
                        });
                }
            }
        }
    }
</script>
