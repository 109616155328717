<template>
	<v-container>
		<v-row style="padding:10px">
			<v-card tile :loading="loading" width="100%">
				<v-form ref="form" v-model="valid" width="100%">
					<v-card-title>
						Opret dit kodeord herunder
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6" md="6">
									<v-text-field v-model="password"
										:rules="[rules.required, rules.min || 'This field is required']"
										:type="showPwdMessage ? 'text' : 'password'" name="input-10-1"
										hint="Mindst 8 bogstaver/tegn" counter clearable
										:append-icon="showPwdMessage ? 'mdi-eye' : 'mdi-eye-off'"
										@click:append="showPwdMessage = !showPwdMessage" label="Kodeord" required>
									</v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<v-text-field v-model="confirmPassword"
										:rules="[rules.required, rules.min, passwordConfirmationRule || 'This field is required']"
										:type="showPwdMessage ? 'text' : 'password'" name="input-10-1"
										hint="Mindst 8 bogstaver/tegn" counter clearable
										:append-icon="showPwdMessage ? 'mdi-eye' : 'mdi-eye-off'"
										@click:append="showPwdMessage = !showPwdMessage" label="Bekræft kodeord" required>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row v-if="showConsent" class="">
								<v-checkbox v-model="consentPolicies" :rules="[rules.required || 'This field is required']"
									class="ma-0 pa-0" hide-details="true">
									<template v-slot:label>
										<div class="body-2">
											<span class="text-black">
												Jeg accepterer appens
												<v-tooltip bottom>
													<template v-slot:activator="{ on }">
														<a target="_blank" href="/terms-of-service" @click.stop
															v-on="on">Handelsbetingelser</a>
													</template>
													Åbner i en ny tab.
												</v-tooltip>
											</span>
											<span> & </span>
											<span>
												<v-tooltip bottom>
													<template v-slot:activator="{ on }">
														<a target="_blank" href="/Fortrolighedspolitik" @click.stop
															v-on="on">Privatlivspolitik</a>
													</template>
													Åbner i en ny tab.
												</v-tooltip>

											</span>
										</div>
									</template>
								</v-checkbox>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn type="submit" :disabled="(showConsent && !consentPolicies)" tile color="primary"
							@click="NewPassword">Godkend</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
	name: 'ResetPassword',
	data: () => ({
		token: '',
		password: '',
		confirmPassword: '',
		errorMessages: '',
		showPwdMessage: false,
		valid: false,
		rules: {
			required: value => !!value || 'Required.',
			min: v => v?.length >= 8 || 'Min 8 characters',
		},
		consentPolicies: false,
		showConsent: false,
		newUser: false
	}),
	mounted() {
		this.token = this.$route.query.pwdChangeToken;
		this.getInitData();
	},
	computed: {
		passwordConfirmationRule() {
			return this.password === this.confirmPassword || "Passwords must match";
		},
		...mapState({
			loading: state => state.loading,
			resetRole: state => state.auth.resetRole
		})
	},
	methods: {
		getInitData() {
			axios.get(`/api/Login/ResetPassword/${this.token}`)
				.then((response) => {
					this.showConsent = response.data.showConsent;
					this.newUser = response.data.newUser;
				})
				.catch((error) => {
					this.$store.commit(
						"setAlertErrorSnack",
						error.response.data
					);
				});
		},
		NewPassword() {
			var hash = this.password;
			this.$store.commit('setLoading', true);
			axios.post('/api/Login/ResetPassword', {
				ConsentPolicies: this.consentPolicies,
				Token: this.token,
				Password: hash
			})
				.then(
					response => {
						console.log(response);
						if (response.status == 200) {
							this.$store.commit('setLoggedIn', response.data);
							let route = "/Verify";
							if (this.newUser) {
								route += "?userJustCreatedPassword=1"
							}
							this.$router.push(route);
							this.$store.commit('setAlertSuccessSnack', "Kodeord ændret korrekt.");
						}
						else if (response.status == 500) {
							this.$store.commit('setAlertErrorSnack', "Token allerede brugt eller udløbet. Anmod om en ny. Hvis dette er en fejl så kontakt Kontakt@DinSundhedsguide.dk.");
						}
						else if (response.status == 401) {
							this.$store.commit('setAlertErrorSnack', "Den leverede hemmelighed er forkert.");
						}
						else
							this.$store.commit('setAlertErrorSnack', "Noget gik galt undervejs, kontakt Kontakt@DinSundhedsguide.dk eller prøv igen senere.");
					})
				.catch((error) => this.$store.commit('setAlertErrorSnack', error.response.data));
		}
	}
}
</script>

<style></style>