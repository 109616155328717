<template>
    <v-row class="content-container">
        <v-row>
            <v-col cols="5">
                <v-row style="flex: 0 1 auto;">
                    <span class="headline">{{ targetTitle }}</span>
                    <div style="flex: 1 1 auto"></div>
                    <div class="content-count-root">
                        <div class="content-count-container">
                            <span class="headline">{{(targetItems && targetItems.length) || 0}}</span>
                        </div>
                        <div class="content-count-text-container">
                            <span class="headline">på listen</span>
                        </div>
                    </div>
                </v-row>
                <v-row style="flex: 0 1 auto;">
                    <v-text-field 
                        v-model="targetSearchText"
                        append-icon="mdi-magnify"
                        :label="targetSearchPlaceholder"
                    />
                </v-row>
                <v-row style="flex: 1 1 auto" class="simple-list-container">
                    <SimpleList
                        ref="targetTable"
                        :contents="targetItems"
                        :allowMoveElements="true"
                        :disablePagination="true"
                        :headers="targetColumns"
                        :search="targetSearchText"
                        :selectedRow="targetSelected"
                        @selectedRowChange="targetSelect"
                        :showEditColumn="showTargetEditColumn"
                        @editRow="editRow"
                    />
                </v-row>
            </v-col>
            <v-col cols="2">
                <div class="move-btns-root">
                    <div class="move-btns-container">
                        <div style="margin-bottom: 30px;">Tilføj/Fjern</div>
                        <div style="margin-bottom: 15px">
                            <img 
                                class="move-arrow"
                                :class="[!!targetSelected && 'clickable']"
                                :src="!!targetSelected ? require('@/assets/Pil_aktiv.png') : require('@/assets/Pil_inaktiv.png')"
                                @click="removeFromTarget"
                            />
                        </div>
                        <div>
                            <img 
                                class="move-arrow move-arrow-rotated"
                                :class="[!!sourceSelected && 'clickable']"
                                :src="!!sourceSelected ? require('@/assets/Pil_aktiv.png') : require('@/assets/Pil_inaktiv.png')"
                                @click="addToTarget"
                            />
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="5">
                <v-row style="flex: 0 1 auto">
                    <span class="headline">{{ sourceTitle }}</span>
                </v-row>
                <v-row style="flex: 0 1 auto">
                    <v-text-field 
                        v-model="sourceSearchText"
                        append-icon="mdi-magnify"
                        :label="sourceSearchPlaceholder"
                    />
                </v-row>
                <v-row class="simple-list-container">
                    <SimpleList
                        ref="sourceTable"
                        :contents="sourceItems"
                        :disablePagination="true"
                        :headers="sourceColumns"
                        :search="sourceSearchText"
                        :selectedRow="sourceSelected"
                        @selectedRowChange="sourceSelect"
                    />
                </v-row>
            </v-col>
        </v-row>
    </v-row>
</template>

<script>
    import SimpleList from "@/components/SimpleList.vue";

    export default {
        name: "OrderFilterTable",
        components: {
            SimpleList,
        },
        props: {
            sourceTitle: {
                type: String,
                required: true,
            },
            targetTitle: {
                type: String,
                required: true,
            },

            sourceSearchPlaceholder: {
                type: String,
                required: true,
            },
            targetSearchPlaceholder: {
                type: String,
                required: true,
            },

            sourceItems: {
                type: Array,
                required: true,
            },
            targetItems: {
                type: Array,
                required: true,
            },

            sourceColumns: {
                type: Array,
                required: true,
            },
            targetColumns: {
                type: Array,
                required: true,
            },
            showTargetEditColumn: {
                type: Boolean
            }
        },
        data: () => ({
            targetSelected: null,
            sourceSelected: null,

            targetSearchText: '',
            sourceSearchText: '',
        }),
        methods: {
            targetSelect(row) {
                this.targetSelected = row;
                this.sourceSelected = null;
            },
            sourceSelect(row) {
                this.sourceSelected = row;
                this.targetSelected = null;
            },

            removeFromTarget() {
                if (!this.targetSelected) {
                    return;
                }
                this.$emit('removeFromTarget', this.targetSelected);
                this.$refs.targetTable.selectFirstRow();
            },
            addToTarget() {
                if (!this.sourceSelected) {
                    return;
                }
                this.$emit('addToTarget', this.sourceSelected);
                this.$refs.sourceTable.selectFirstRow();
            },
            editRow(obj) {
                this.$emit("editRow", obj);
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-container {
        border: 1px solid black;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;
        padding-bottom: 0;
    }

    .content-container:first-child {
        margin-top: 0px;
    }

    .content-container:not(:first-child) {
        margin-top: 55px;
    }

    .content-count-root {
        color: #738ea6;
        display: flex;
        flex-direction: row;
        line-height: 1.2rem;
    }

    .content-count-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        border-bottom: 2px solid #738ea6;
        width: 35px;
        margin-right: 5px;
    }

    .content-count-text-container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        border: 2px solid transparent;
    }

    .move-btns-root {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .move-btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .move-arrow {
        height: 30px;
        width: 35px;
        cursor: not-allowed;

        &.move-arrow-rotated {
            transform: rotate(180deg);
        }

        &.clickable {
            cursor: pointer;
        }
    }

    .simple-list-container {
        flex: 1 1 auto;
        height: 300px;
        overflow: scroll;
    }

    .simple-list-container::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
</style>