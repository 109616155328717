import axios from 'axios';
import { Module } from "vuex";
import { RootStore } from '..';

interface Consultation {
    id: number;
}

interface ConsultationsState {
    consultations: Consultation[];
}

const consultationsModule: Module<ConsultationsState, RootStore> = {
    state: {
        consultations: [],
    },
    mutations: {

    },
    actions: {
        async createConsultation({ commit, rootGetters, dispatch }, obj) {
            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + rootGetters.getJWT
                        }
                    };

                    return new Promise((resolve, reject) => {
                        axios.post('api/consultation', obj, config)
                            .then(
                                response => {
                                    if (response.status === 200) {
                                        resolve(true);
                                    }
                                    else {
                                        reject(false);
                                    }
                                })
                            .catch(
                                err => {
                                    if (err.response?.status === 401) {
                                        commit('setAlertErrorSnack');
                                        reject(false);
                                    }
                                }
                            )
                    });
                });
        },
        async deleteConsultation({ commit, rootGetters, dispatch }, consultationId) {
            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + rootGetters.getJWT
                        }
                    }

                    return new Promise((resolve, reject) => {
                        axios.delete('api/consultation/' + consultationId, config)
                            .then(
                                response => {
                                    if (response.status === 200) {
                                        resolve(true);
                                    }
                                    else {
                                        reject(false);
                                    }
                                })
                            .catch(
                                err => {
                                    if (err.response?.status === 401) {
                                        commit('setAlertErrorSnack');
                                        reject(false);
                                    }
                                });
                    });
                });
        },
        async getUserConsultations({ commit, rootGetters, dispatch }, userId) {
            await dispatch("refreshJWT", rootGetters.getJWT);
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.getJWT
                }
            }
            const response = await axios.get('api/consultation/user/' + userId, config);
            return response.data;
        },
        async updateConsultation({ commit, rootGetters, dispatch }, obj) {
            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + rootGetters.getJWT
                        }
                    }

                    return new Promise((resolve, reject) => {
                        axios.put('/api/consultation/' + obj.updatedConsultation.id, obj.updatedConsultation, config)
                            .then(
                                response => {
                                    if (response.status === 200) {
                                        resolve(true);
                                    }
                                    else {
                                        reject(false);
                                    }
                                })
                            .catch(
                                err => {
                                    console.log(err);
                                    if (err.response?.status === 401) {
                                        commit('setAlertErrorSnack');
                                        reject(false);
                                    }
                                });
                    });
                })
        }
    }
}

export default consultationsModule;