var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-card',{staticClass:"sign-up-card pa-4",attrs:{"flat":"","loading":_vm.loading,"width":"100%","color":"black"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"pa-1"},[_c('v-container',[_c('div',[_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('label',{staticClass:"alt-font"},[_vm._v("Navn")]),_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"solo":"","dense":"","color":"white","hide-details":"auto","error-messages":_vm.errorMessages,"rules":[function () { return !!_vm.item.name || 'This field is required'; }],"required":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('label',{staticClass:"alt-font"},[_vm._v("Email")]),_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"solo":"","hide-details":"auto","dense":"","error-messages":_vm.errorMessages,"rules":[
                      _vm.rules.emailMatch || 'You must enter a valid email' ],"required":""},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('label',{staticClass:"alt-font"},[_vm._v("Adgangskode")]),_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"solo":"","dense":"","hide-details":"auto","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                      _vm.rules.required,
                      _vm.rules.min || 'This field is required' ],"type":_vm.showPassword ? 'text' : 'password',"name":"input-10-1","hint":"Mindst 8 bogstaver/tegn","counter":"","required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"dark":"","flat":"","value":_vm.$route.query.vipId ? 0 : null}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('label',{staticClass:"alt-font primary--text",staticStyle:{"cursor":"pointer"}},[_vm._v("VIP ID")])]),_c('v-expansion-panel-content',[_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"solo":"","dense":"","light":""},model:{value:(_vm.item.vipId),callback:function ($$v) {_vm.$set(_vm.item, "vipId", $$v)},expression:"item.vipId"}})],1)],1)],1)],1)],1),_c('v-row',{},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"rules":[_vm.rules.required || 'This field is required'],"dark":"","hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"body-2"},[_c('span',[_vm._v(" Jeg accepterer DinSundhedsguide's "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"/Fortrolighedspolitik"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" Fortrolighedspolitik ")])]}}])},[_vm._v(" Åbner i en ny tab. ")])],1),_c('span',[_vm._v(" &  ")]),_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"/terms-of-service"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" Terms Of Service. ")])]}}])},[_vm._v(" Åbner i en ny tab. ")])],1)])]},proxy:true}]),model:{value:(_vm.consentPolicies),callback:function ($$v) {_vm.consentPolicies=$$v},expression:"consentPolicies"}})],1)],1)])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mb-2 white--text",attrs:{"block":"","dark":"","color":"#4B788E","disabled":_vm.validate_form === false,"loading":_vm.loading},on:{"click":function($event){return _vm.CreateUser()}}},[_vm._v(" Opret Bruger ")])],1)],1),_c('div',{staticClass:"body-2 grey--text text-center"},[_vm._v(" Du samtykker at modtage tilbud fra DinSundhedsGuide.dk ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }