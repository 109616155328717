import axios from 'axios';
import moment from 'moment';
import { Module } from "vuex";
import { RootStore } from '..';

interface Exercise {
    Created: string;
    Description: string;
    Enabled: boolean;
    id: number;
    IsCourseMaterial: boolean;
    PaidContent: boolean;
    Title: string;
    Updated?: string;
    VideoLink: string;
    VimeoId: string;
}

interface ExerciseState {
    exercises: Exercise[];
}

const exerciseModule: Module<ExerciseState, RootStore> = {
    state: {
        exercises: []
    },
    mutations: {
        addExercise(state, exercise: { exercise: Exercise }) {
            state.exercises.push(exercise.exercise);
        },
        removeExercise(state, Idx) {
            state.exercises.splice(Idx, 1)
        },
        updateExercise(state, obj) {
            obj.updatedExercise.updated = moment.unix(obj.updatedExercise.updated).format("YYYY-MM-DDTHH:MM:SS");
            const index = state.exercises.findIndex(elem => {
                return elem.id === obj.Idx;
            });
            if (index > 0) {
                Object.assign(state.exercises[index], obj.updatedExercise);
            }
            else { // Should not happen when updating
                throw 'Err updating exercise in state';
            }
        },
        setExercises(state, exercises) {
            state.exercises = exercises;
        }
    },
    getters: {
        getExerciseByItem: (state) => (item: Exercise) => {
            const index = state.exercises.indexOf(item)
            return index;
        }
    },
    actions: {
        duplicateExercise({ dispatch, commit }, exercise) {
            commit('setLoading', true);
            exercise.id = -1,
                exercise.created = moment.utc().unix();
            exercise.updated = null;

            if (exercise.requirements) {
                exercise.requirements.id = -1;
                exercise.requirements.exerciseId = -1;
            }

            if (exercise.tags) {
                exercise.tags.exerciseId = -1;
                exercise.tags.id = -1;
            }

            exercise.vimeoId = null;
            exercise.videoLink = "no link";
            exercise.title = exercise.title + " - Copy";
            dispatch('addExercise', exercise)
                .finally(() => commit('setLoading', false));

        },
        async getAllExercises({ commit, rootGetters, dispatch }) {
            commit('setLoading', true);
            await dispatch("refreshJWT", rootGetters.getJWT);
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + rootGetters.getJWT
                }
            }

            const response = await axios.get('/api/exercise/list', config);
            commit('setExercises', response.data.exercises)
            commit('setLoading', false);
        },
        async addExercise({ commit, rootGetters, dispatch }, exercise) {
            commit('setLoading', true);
            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    const config = {
                        headers: { //anonymous user creation.
                            'Authorization': 'Bearer ' + rootGetters.getJWT,
                            'Content-Type': 'application/json',
                        }
                    }

                    const data = JSON.stringify(exercise);
                    return new Promise((resolve, reject) => {
                        axios.post('/api/exercise/create', data, config)
                            .then(response => {
                                commit('addExercise', response.data);
                                commit('setAlertSuccessSnack', "Øvelsen er oprettet korrekt.");
                                resolve(true);
                            })
                            .catch(err => {
                                commit('setAlertErrorSnack', err); console.log("calling promise"); reject(false);
                            });
                    });
                });
            commit('setLoading', false);
        },
        async addCourseMaterial({ commit, rootGetters, dispatch }, courseMaterial) {
            commit('setloading', true);
            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + rootGetters.getJWT,
                            'Content-type': 'application/json',
                        }
                    };

                    const data = JSON.stringify(courseMaterial);
                    return new Promise((resolve, reject) => {
                        axios.post('api/exercise/create-course-material', data, config)
                            .then(response => {
                                commit('addExercise', response.data);
                                commit('setAlertSuccessSnack', 'Kursusmaterialet er oprettet korrekt.');
                                resolve(true);
                            })
                            .catch(err => {
                                commit('setAlertErrorSnack', err); console.log("calling promise"); reject(false);
                            });
                    });
                });
        },
        async removeExercise({ commit, rootGetters, dispatch }, obj) {
            commit('setLoading', true);
            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + rootGetters.getJWT
                        }
                    }

                    axios.delete('/api/exercise/delete/' + obj.Id, config).then(() => (commit('removeExercise', obj.Idx)))
                        .then(() => commit('setAlertSuccessSnack', "Øvelsen er slettet korrekt."))
                        .catch(err => (commit('setAlertErrorSnack', err)));
                });
            commit('setLoading', false);
        },
        async updateExercise({ commit, rootGetters, dispatch }, obj) {
            commit('setLoading', true);
            await dispatch("refreshJWT", rootGetters.getJWT)
                .then(() => {
                    //define auth headers.
                    const config = {
                        headers: {
                            'Authorization': 'Bearer ' + rootGetters.getJWT
                        }
                    }
                    return new Promise((resolve, reject) => {
                        //define and execute API call.
                        axios.put('/api/exercise/update/' + obj.updatedExercise.id, obj.updatedExercise, config)
                            .then(response => {
                                console.log("Updating Exercise");
                                if (response.status === 200) { //success
                                    commit('setAlertSuccessSnack', "Videoen blev opdateret");
                                    commit('updateExercise', obj)
                                    resolve(true);
                                } else { //failure
                                    commit('setAlertErrorSnack', "Opdatering fejlede.");
                                    console.log("missed it");
                                }
                            })
                            .catch(err => { //error handling.
                                console.log(err);
                                if (err.response?.status === 401) { //display error.
                                    commit('setAlertErrorSnack', err);
                                    console.log("Forbidden response - requesting new jwt");
                                    reject(false);
                                }
                            });
                    });
                })
                .catch((err: any) => { console.log(err); });
            commit('setLoading', false);
        },
    }
}

export default exerciseModule;