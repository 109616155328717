<template>
    <v-container>
        <v-row style="padding: 10px">
            <v-card flat :loading="loading" width="100%">
                <v-form ref="user_form" v-model="valid">
                    <v-card-title>
                        <span class="headline">Min profil</span>
                        <v-spacer />
                        <div style="color: #738EA6; cursor: pointer;"
                             @click="UpdateUser">
                            Gem ændringer
                        </div>
                    </v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="5" sm="5" md="5">
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="user.name"
                                                      label="Navn"
                                                      :hint="user.name"
                                                      :error-messages="errorMessages"
                                                      :rules="[() => !!user.name || 'This field is required']"
                                                      required />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="5" sm="5" md="5">
                                        <v-menu v-model="startDateMenu"
                                                min-width="auto"
                                                offset-y
                                                transition="scale-transition"
                                                :close-on-content-click="false"
                                                :nudge-right="40">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="user.birthDate"
                                                              label="Fødseldag"
                                                              readonly
                                                              type="date"
                                                              v-bind="attrs"
                                                              v-on="on" />
                                            </template>
                                            <v-date-picker v-model="user.birthDate"
                                                           @input="startDateMenu = false" />
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2" sm="2" md="2" />
                                    <v-col cols="5" sm="5" md="5">
                                        <v-text-field v-model="user.weight"
                                                      type="number"
                                                      label="Vægt(kg)"
                                                      min="0"
                                                      placeholder="Valgfrit" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="5" sm="5" md="5">
                                        <v-select v-model="user.role"
                                                  hint="Angiv venligst en rolle"
                                                  label="Rolle"
                                                  required
                                                  :disabled="true"
                                                  :error-messages="errorMessages"
                                                  :items="roles"
                                                  :rules="[() => !!user.role || 'This field is required']">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="2" sm="2" md="2" />
                                    <v-col cols="5" sm="5" md="5" />
                                </v-row>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" />
                            <v-col cols="5" sm="5" md="5">
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="user.email"
                                                      hint="Indtast venligst en email"
                                                      label="Email"
                                                      required
                                                      :error-messages="errorMessages"
                                                      :rules="[rules.emailMatch || 'You must enter a valid email']">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="5" sm="5" md="5">
                                        <v-text-field v-model="user.height"
                                                      type="number"
                                                      label="Højde(cm)"
                                                      min="0"
                                                      placeholder="Valgfrit" />
                                    </v-col>
                                    <v-col cols="2" sm="2" md="2" />
                                    <v-col cols="5" sm="5" md="5">
                                        <v-select v-model="user.gender"
                                                  label="Køn"
                                                  hint="Angiv venligst dit køn"
                                                  :items="genders"
                                                  :error-messages="errorMessages">
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card>
        </v-row>
        <v-dialog v-model="showScreeningDialog" max-width="500px">
            <v-card tile :loading="loading" width="100%">
                <v-form ref="new_screen_form" v-model="valid">
                    <v-card-text class="text-center"
                                 style="padding-bottom: 8px">
                        <h3 v-if="userJustCreatedPassword" class="mb-5 pb-5">
                            Du har nu oprettet dit kodeord korrekt
                            <br>
                            og du kan nu logge ind i appen.
                            <br>
                            Find appen på appstore eller googleplay.
                            <br>
                        </h3>
                        <h3>
                            Vi anbefaler, at du gennemfører din
                            <br />Krops-Screening nu, så du får adgang til
                            personlige <br />tilpassede øvelser i din app.
                        </h3>
                    </v-card-text>

                    <v-card-actions class="justify-center"
                                    style="flex-direction: column">
                        <v-btn color="primary"
                               :disabled="loading"
                               @click="ShowExistingScreening()">Tag krops-screening nu</v-btn>
                        <v-btn color="primary"
                               :disabled="loading"
                               text
                               @click="screeningDismissed = true">Vent til senere</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showRefreshScreeningDialog" max-width="500px">
            <v-card tile :loading="loading" width="100%">
                <v-form ref="renew_screen_form" v-model="valid">
                    <v-card-text class="text-center"
                                 style="padding-bottom: 8px">
                        <h3>
                            Det er længe siden du opdaterede din screening.
                            <br />
                            For at kunne foreslå de bedste videoer til dine
                            <br />
                            omstændigheder anbefaler vi at du opdaterer den nu.
                            <br />
                            Ønsker du at opdatere den?
                        </h3>
                    </v-card-text>

                    <v-card-actions class="justify-center"
                                    style="flex-direction: column">
                        <v-btn color="primary"
                               :disabled="loading"
                               @click="ShowRefreshScreening()">Tag ny krops-screening</v-btn>
                        <v-btn color="primary"
                               :disabled="loading"
                               text
                               @click="DismissRefreshScreening()">Nej tak</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import moment from "moment";
    import { mapState } from "vuex";

    export default {
        name: "HomeUserForm",
        props: ["_baseUser", "nextScreeningDate"],
        data() {
            return {
                password: "",
                confirmPassword: "",
                errorMessages: "",
                gender: this._baseUser.gender,
                genders: [
                    { text: "Mand", value: "Male" },
                    { text: "Kvinde", value: "Female" },
                    { text: "Andet", value: "Other" },
                ],
                roles: ["Freemium", "Premium", "Admin"],
                rules: {
                    required: (value) => !!value || "Required.",
                    min: (v) => v.length >= 8 || "Min 8 characters",
                    emailMatch: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
                },
                screeningDismissed: false,
                showPwdMessage: false,
                startDateMenu: false,
                user: {},
                valid: false,
                userJustCreatedPassword: false,
            }
        },
        computed: {
            passwordConfirmationRule() {
                return (
                    this.password === this.confirmPassword || "Passwords must match"
                );
            },
            isCreating() {
                return this.editedIndex < 0;
            },
            ...mapState({
                loading: (state) => state.loading,
            }),
            showScreeningDialog: {
                get() {
                    if (this.screeningDismissed) {
                        return false;
                    }
                    const token = this.user.screeningToken;
                    return !!token;
                },
                set(newVal) {
                    console.log("Computed - showScreeningDialog");
                    console.log(newVal);
                }
            },
            showRefreshScreeningDialog() {
                if (this.showScreeningDialog) {
                    return false;
                }
                if (this.screeningDismissed) {
                    return false;
                }

                if (!this.nextScreeningDate) {
                    return false;
                }

                return moment(this.nextScreeningDate).isSameOrBefore(moment());
            },
        },
        mounted() {
            this.$refs.user_form.validate();
            this.screeningDismissed = this._baseUser.role === 'Admin';
            this._baseUser.height = this._baseUser.height || null;
            this._baseUser.weight = this._baseUser.weight || null;
            console.log("mounted");
            console.log(this._baseUser);
            this.user = this._baseUser;
            this.userJustCreatedPassword = this.$route.query.userJustCreatedPassword == 1;
        },
        methods: {
            UpdateUser() {
                this.$refs.user_form.validate();
                this.$store.dispatch("UpdateMyUserInformation", this.user);
            },
            ShowExistingScreening() {
                this.screeningDismissed = true;
                const token = this.user.screeningToken;
                if (!token) {
                    return;
                }
                const url =
                    "https://DinSundhedsGuide.Typeform.com/to/hIjmcLsY#token=" +
                    token;
                window.open(url, "_blank");
            },
            GetNewScreening() {
                this.$store.dispatch("getNewScreening");
            },
            ShowRefreshScreening() {
                this.screeningDismissed = true;
                this.$store.dispatch("getNewScreening");
            },
            DismissRefreshScreening() {
                this.screeningDismissed = true;
                this.$store.dispatch("skipScreening");
            },
        },
        watch: {
            _baseUser(newVal, oldVal) {
                if (newVal !== null && newVal !== oldVal && this.user?.id !== newVal?.id) {
                    console.log(newVal);
                    this.user = newVal;
                }
            }
        }
    };
</script>
