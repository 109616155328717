<template>
    <v-row class="TermsOfService">
        <v-img height="200px" contain src="@/assets/logo.png" class="mt-12 mb-0" />
        <div class="contentWrap">
            <v-col cols="12">
                <div>
                    <span class="TOStitle">Handelsbetingelser til Din SundhedsGuide</span>
                    <i>Pr. 22. november 2022</i>
                    <h2>
                        Køb hos Din SundhedsGuide ApS
                    </h2>
                    <p>
                        Nærværende handelsbetingelser finder anvendelse på køb foretaget på Din
                        SundhedsGuide.
                    </p>
                    <p>
                        Når du bestiller en adgang til din bruger på Din SundhedsGuide, skal du være opmærksom på,
                        at aftalen ikke anses for endeligt indgået, før du modtager en faktura fra os.
                    </p>
                    <p>
                        Der tages forbehold for prisfejl mv. som følge af valutaændringer og trykfejl.
                    </p>
                    <h2>
                        Priser og kontrakter
                    </h2>
                    <p>
                        Alle priser er inklusive moms, hvor intet andet nævnes. Priserne er kun gældende indenfor
                        Danmarks grænser, ekskl. Færøerne og Grønland. Alle kontrakter indgås på dansk.
                    </p>
                    <p>
                        Priser kan reguleres uden forudgående varsel.
                    </p>
                    <h2>
                        Serviceadgang
                    </h2>
                    <p>
                        Ved tegning af abonnement er servicen tilgængelig umiddelbart efter din betaling.
                    </p>
                    <h2>
                        Ordrebekræftelse
                    </h2>
                    <p>
                        Du modtager umiddelbart efter din bestilling en automatisk genereret ordrebekræftelse. Denne
                        er alene en automatisk genereret meddelelse. Dit køb er først endeligt, når du modtager faktura
                        fra Din SundhedsGuide.
                    </p>
                    <p>
                        Din SundhedsGuide opbevarer selve ordrebekræftelsen efter den er fremsendt til kunden.
                    </p>
                    <h2>
                        Tilmelding, medlemskab og betaling
                    </h2>
                    <p>
                        Tilmelding til Din SundhedsGuide kræver, at du har forbindelse til internettet, at du har en enhed
                        der er kompatibel med tjenesten samt, at du tilmelder dig den af Din SundhedsGuide anvendte
                        tjenester. Ved oprettelsen af medlemskabet skal der oplyses kontaktoplysninger og andre
                        former for relevant data til brug af tjenesten.
                    </p>
                    <p>
                        Det er medlemmets ansvar, at Din SundhedsGuide til hver en tid har medlemmets korrekte
                        kontakt- og bank-/kortoplysninger.
                    </p>
                    <p>
                        Du skal være 18 år, for at kunne blive medlem af Din SundhedsGuide. Medlemskabet af Din
                        SundhedsGuide er personligt og må ikke benyttes af andre end medlemmet.
                    </p>
                    <p>
                        Betaling for medlemskab hos Din SundhedsGuide kan kun ske med:
                        <ul>
                            <li>Dankort</li>
                            <li>VisaDankort</li>
                            <li>Mastercard</li>
                        </ul>
                    </p>
                    <p>
                        Betaling med kort behandles fortroligt gennem vores betalingsindløseraftale. Det er således kun
                        dig, der kan læse informationen.
                    </p>
                    <p>
                        Månedsmedlemskaber kan opsiges løbende. Medlemskaber med mere end en måneds
                        varighed, kan opsiges frem til dagen før udløb, herefter gentegnes det automatisk.
                    </p>
                    <p>
                        Ændringer i handelsbetingelser varsles altid mindst 30 dage før ændringen træder i kraft.
                    </p>
                    <p>
                        Ved køb af et forløb igangsættes dit medlemskab på den dato, hvor forløbet starter.
                    </p>
                    <p>
                        Såfremt du ikke afmelder dit medlemskab inden fornyelsesdatoen, giver du samtykke til at Din
                        SundhedsGuide trækker betaling for den næste måneds medlemskab. Det gælder også hvis du
                        ikke afmelder medlemskabet inden udgangen af den gratis prøveperiode eller eventuel gratis
                        periode eller en periode med nedsat pris.
                    </p>
                    <p>
                        Hvis et skyldigt beløb ikke betales rettidigt, sender vi en rykker. Betales den ikke inden den
                        angivne forfaldsdato, har Din SundhedsGuide ret til uden varsel at lukke medlemskabet så
                        medlemmet kan ikke benytte tjenesterne i Din SundhedsGuide. Din SundhedsGuide forbeholder
                        sig ret til at opkræve det udestående beløb, et gebyr for rykning og for sen betaling samt at
                        indberette dårlige betalere til RKI i henhold til RKI’s gældende vilkår.
                    </p>
                    <p>
                        Ved oprettelse som bruger af Din SundhedsGuide giver du samtykke til, at Din SundhedsGuide
                        må tilsende dig nyhedsbreve og mails.
                    </p>
                    <h2>
                        Fortrydelsesret
                    </h2>
                    <p>
                        Din SundhedsGuide yder 14 dages fortrydelsesret på abonnementsbetalingen fra den dag, du
                        opretter dit abonnement.
                    </p>
                    <p>
                        Såfremt du ønsker at gøre brug af fortrydelsesretten, skal du inden 14 dage fra varens
                        modtagelse, give Din SundhedsGuide meddelelse herom ved en utvetydig erklæring. Du kan i
                        den forbindelse kontakte os på kontakt@dinsundhedsguide.dk
                    </p>
                    <p>
                        Hvis du fortryder købet, tilbagebetales den fulde købesum for varen til dig. Købesummen
                        overføres til det kort, som du har betalt med eller returneres til din bankkonto.
                    </p>
                    <h2>
                        Reklamationsret
                    </h2>
                    <p>
                        Som forbruger har man 2 års reklamationsret, hvilket betyder, at du enten kan få varen
                        repareret, ombyttet, pengene tilbage eller afslag i prisen, afhængig af den konkrete situation.
                        Dette betinger selvfølgelig, at reklamationen er berettiget. Forskellen på reklamationsret og
                        garanti kan ses her.
                    </p>
                    <p>
                        Din SundhedsGuide kan ikke gøres ansvarlig for tabte data og andre indirekte følgeskader, som
                        varer solgt af Din SundhedsGuide måtte have skabt, med mindre det følger direkte af dansk
                        lovgivning. Der henvises i øvrigt til Købeloven for fremgangsmåde vedrørende reklamationer.
                    </p>
                    <p>
                        Reklamation angående fejl og mangler skal meddeles Din SundhedsGuide inden rimelig tid
                        efter modtagelse af adgang til appen. Kunden opfordres på det kraftigste til at afgive erklæring
                        omkring produktets fejl eller mangel i forbindelse med reklamationen, idet undladelse kan
                        betyde en forlængelse af afhjælpningsprocessen grundet fejlsøgning.
                    </p>
                    <h2>
                        Afmelding af medlemskab
                    </h2>
                    <p>
                        Du kan til enhver tid opsige dit abonnement under “Min konto” på dinsundhedsguide.dk. Ved
                        opsigelse er ophør af dit abonnement angivet til din igangværende abonnementsperiodes
                        udløb. Din SundhedsGuide yder ikke refusion eller kredit for delvist brugte medlemsmåneder.
                    </p>
                    <p>
                        Afmelding kan også ske ved at sende en mail til kontakt@dinsundhedsguide.dk.
                    </p>
                    <p>
                        Ved afmelding via. mail bedes du oplyse dit fulde navn.
                    </p>
                    <p>
                        Spørgsmål vedrørende afmelding kan ske til kontakt@dinsundhedsguide.dk
                    </p>
                    <h2>
                        Behandling af personoplysninger
                    </h2>
                    <p>
                        Ved køb hos Din SundhedsGuide ApS, registreres dit fulde navn, e-mailadresse og
                        betalingsoplysninger. Disse oplysninger gemmes med henblik på oprettelse af en
                        betalingsaftale samt entydig identifikation af vores medlemmer.
                    </p>
                    <p>
                        Ved køb hos Din SundhedsGuide ApS opbevarer dine personlige data, så længe, det er
                        nødvendigt for at levere vores services til dig. Vi beholder og bruger også dine personlige data,
                        som er nødvendige for at overholde juridiske forpligtelser, løse konflikter, og håndhæve vores
                        aftaler. Vi opbevarer højest dine data i 10 år. Vi bruger dataindsamlings værktøjer og online
                        annoncering og bruger cookies for at indsamle data, der hjælper os med at personliggøre din
                        brug af vores services.
                    </p>
                    <p>
                        Du kan til hver en tid få slettet oplysninger om dig, inden tidspunktet for vores almindelige
                        generelle sletning indtræffer.
                    </p>
                    <p>
                        Du har ret til at få indsigt i de oplysninger, som du tidligere har oplyst os.
                    </p>
                    <p>
                        Behandlingen af dine personoplysninger hos Din SundhedsGuide ApS sker altid i
                        overensstemmelse med Persondataloven (GDPR).
                    </p>
                    <p>
                        Vi henviser til vores privatlivspolitik du kan finde på dinsundhedsguide.dk for yderligere
                        information og kontakt.
                    </p>
                    <h2>
                        Tvister
                    </h2>
                    <p>
                        Hvis der er en tvist, som du eller Din SundhedsGuide ikke kan løse, har du retten til at indsende
                        en klage til:
                    </p>
                    <p>
                        Center for Klageløsning, <br>
                        Nævnenes Hus<br>
                        Toldboden 2<br>
                        8800 Viborg<br>
                        <a href="mailto:klageportalen@naevneneshus.dk">klageportalen@naevneneshus.dk</a>
                    </p>
                    <h2>
                        Dine data
                    </h2>
                    <p>
                        Vi passer på dine personlige data i henhold til gældende regler og ved at bruge vores services
                        samtykker du til indsamlingen, brugen og delingen af sådanne personlige data. Vi henviser til
                        Din SundhedsGuide’s Fortrolighedspolitik som du kan finde på dinsundhedsguide.dk.
                    </p>
                    <h2>
                        Tvivlsspørgsmål eller kommentarer.
                    </h2>
                    <p>
                        Såfremt der måtte opstå tvivlsspørgsmål kontakt os da på
                        <a href="mailto:kontakt@dinsundhedsguide.dk">kontakt@dinsundhedsguide.dk</a>
                    </p>
                    <h2>
                        Kontakt
                    </h2>
                    <p>
                        Hvis du har feedback, spørgsmål eller kommentarer til vores services, så kontakt
                        venligst:
                    </p>
                    <p>Din SundhedsGuide ApS</p>
                    <p>CVR-nr. 41961651</p>
                    <p>kontakt@dinsundhedsguide.dk</p>
                    <br>
                    <p>Tak fordi du læste alt det her, og velkommen til Din SundhedsGuide!</p>
                </div>
                <div>
                    <span class="TOStitle">Brugervilkår til Din SundhedsGuide</span>
                    <i> Pr. 22. november 2022</i>
                    <br><br>
                    <h2>Velkommen til Din SundhedsGuide.</h2>
                    <p>
                      Vi har udviklet en sundhedsløsning, som indeholder selvbehandlingsteknikker, øvelser, redskaber
                      og træningsmetoder, som vi har afprøvet i fysioterapeutisk praksis siden 2005. Din SundhedsGuide
                      leverer en individuelt tilpasset løsning til dig, som har ubalance i kroppen med fokus på at opbygge
                      og støtte din krops basale behov, herunder kropsfunktioner og systemer og er derfor meget lidt
                      indgribende på kroppen, hvilket sikrer os, at du kan anvende Din SundhedsGuide trygt og sikkert,
                      hvis du følger anvisningerne. Du kan til enhver tid kontakte os, hvis du er i tvivl om den måde din
                      krop reagerer på, da vi så kan vejlede dig ved brug af det rigtige sundhedsfaglige personale.
                    </p>
                    <p>
                      Disse brugsvilkår- og betingelser er lavet for at skabe sikre rammer for brugen af Din
                      SundhedsGuide. Ved at bruge Din SundhedsGuide accepterer du alle nedenstående vilkår.
                    </p>
                    <p>
                      Din SundhedsGuide tilbyder en sundheds-app til personer med spændinger, smerter, stress eller
                      sygdomme i kroppen, og indholdet leveres i samarbejde med autoriseret sundhedsfaglige
                      samarbejdspartnere.
                    </p>
                    <p>
                      Din SundhedsGuides indhold tager udgangspunkt i behandlingskonceptet ”FysziOnergi”, som er en
                      kombination af fysioterapi, zoneterapi og energiterapi.
                    </p>
                    <p>
                      Vi arbejder holistisk og helhedsorienteret og der vil være samarbejdspartnere, som leverer
                      materiale til kategorierne ”kost & ernæring” og ”mental træning”. Vi inddrager desuden teori og
                      praksis fra alternativ behandling som zoneterapi, akupunktur og andre RAB-godkendte udbydere.
                    </p>
                    <p>
                      Indholdet i Din SundhedsGuide tager udgangspunkt i Nationale kliniske retningslinjer for fysioterapi,
                      Sundhedsstyrelsens kostråd samt anbefalinger for fysisk træning som behandling af
                      livsstilssygdomme.
                    </p>
                    <p>
                      Disse brugervilkår (i det følgende kaldet “Vilkår”) styrer din adgang til og brugen af vores services.
                      Læs venligst vilkårene grundigt, før du bruger vores services, da de er juridisk bindende mellem dig
                      og Din SundhedsGuide.
                    </p>
                    <p>
                      Din brug af vores services udgør din accept af disse vilkår og vores fortrolighedspolitik, som er
                      indarbejdet i vilkårene. Det betyder, hvis du er uenig med nogen som helst del af disse vilkår, er det
                      ikke muligt for dig at bruge vores services. Ved at få adgang til og bruge vores services forstår og
                      accepterer du at give Din SundhedsGuide adgang til dine personlige data jf. vores fortrolighedspolitik.
                    </p>
                    <p>
                      Du er velkommen til at kontakte os på kontakt@dinsundhedsguide.dk, hvis du har nogle spørgsmål
                      eller forslag til forbedringer af vores tjeneste.
                    </p>

                    <h2>Din Bruger</h2>
                    <p>
                      Alle kan anvende Din SundhedsGuide. Er du under 18 år, kan du kun bruge vores services med en
                      forælder eller værges samtykke og tilsyn.                       
                    </p>
                    <p>
                      Du skal have en konto ved Din SundhedsGuide for at få adgang til vores services. Når du opretter
                      en konto, er du nødt til at give os præcise og fuldstændige oplysninger, som du bliver bedt om
                      under kontooprettelsen og registreringsprocessen og holde de oplysninger opdateret, da dit
                      leverede produkt er direkte afhængig af disse. Din bruger er personlig og må ikke benyttes af andre.
                    </p>
                    <p>
                      Du er ansvarlig for at opretholde fortroligheden af enhver handling, der finder sted, mens du
                      anvender vores tjeneste, og du skal øjeblikkeligt underrette os om ethvert reelt eller formodet tab,
                      tyveri eller uautoriseret brug af din konto eller konto-password. Vi hæfter ikke for tab, der skyldes
                      uautoriseret brug af dit brugernavn og password, med eller uden dit kendskab.
                    </p>
                    <p>
                      Du kan slette din bruger ved Din SundhedsGuide ved at kontakte vores supportfunktion. Ved
                      permanent sletning af konto, vil ikke-offentlige persondata, vi har associeret med din bruger, også
                      blive slettet.
                    </p>

                    <h2>Din SundhedsGuides services</h2>
                    <p>Din SundhedsGuides services tilbydes af</p>
                    <p>Din SundhedsGuide ApS,</p>
                    <p>CVR-nr. 41961651</p>
                    <p>
                      Serviceopdateringer, ændringer og begrænsninger: Vores services ændrer sig regelmæssigt, og
                      deres form og funktion kan ændre sig uden forudgående varsel.
                    </p>
                    <p>
                      Vi kan udvikle og levere opdateringer til vores services. Dette inkluderer opgraderinger, ændringer,
                      fejlrettelser, patches og andre kvalitetssikring og/eller nye appfunktioner. Nye appfunktioner kan
                      være pålagt særskilt betaling og tillægsvilkår.
                    </p>
                    <p>
                      Vores services kan muligvis ikke fungere, hvis du ikke installerer alle opdateringer. Disse
                      opdateringer kan ske automatisk, hvilket kan påvirke brugen i forbindelse med opdateringen.
                    </p>
                    <p>
                      Endvidere accepterer du, at vilkårene (og enhver yderligere ændring af samme) vil gælde for alle
                      opdateringer af vores services.
                    </p>
                    <p>
                      Serviceovervågning og afbrydelse: Vi kan foretage ændringer i appen, herunder tilgængeligheden
                      af enhver funktion og indhold. Vi kan også deaktivere, lukke eller afbryde din bruger på et hvilket
                      som helst tidspunkt (1) hvis vi skønner, at du overtræder eller har overtrådt vilkårene, (2) ved ophør
                      eller væsentlige ændringer af services, eller (3) grundet uventede tekniske problemer. Efter enhver
                      deaktivering, lukning eller suspendering bestræber vi os på at underrette dig via e-mail, eller næste
                      gang du forsøger at tilgå din konto. Vi forbeholder os retten til at overvåge enhver konto og/eller
                      aktivitet gennemført gennem eller på nogen måde relateret til vores services, inklusive den enkelte
                      brugers personlige data.
                    </p>
                    <p>
                      Sikkerhed: Vi interesserer os for vores brugeres sikkerhed og i at beskytte sikkerheden af dine
                      personlige data, brugergenereret indhold og kontooplysninger, men kan ikke garantere, at
                      uautoriserede tredjeparter ikke vil være i stand til at omgå vores sikkerhedsforanstaltninger.
                      Underret os venligst øjeblikkeligt om ethvert reelt eller formodet brud på, eller uautoriseret adgang
                      eller brug af din konto.
                    </p>
                    <p>
                      Giv tilladelse til at modtage kommunikationer: Efter du har oprettet en konto, kan du modtage e-mail
                      kommunikation omkring driften af vores services. Du kan ikke fravælge modtagelsen af denne
                      kommunikation. Du kan også modtage kampagner og andre tilbud eller materialer, vi mener kan
                      være interessante for dig.
                    </p>

                    <h2>Fortrolighed</h2>
                    <p>
                      Vi passer på dine personlige data i henhold til gældende regler og ved at bruge vores services
                      samtykker du til indsamlingen, brugen og delingen af sådanne personlige data. Vi henviser til Din
                      SundhedsGuides Fortrolighedspolitik som er tilgængelig som på Din Sundhedsguide hjemmeside.
                    </p>

                    <h2>Ejerskab og rettigheder</h2>
                    <p>
                      Vores materiale og indhold er beskyttet og der må derfor ikke ske kopiering, gengivelse eller
                      videregivelse heraf.
                    </p>
                    <p>
                      Materiale og indhold må ikke vises, gengives eller deles offentligt uden samtykke:
                    </p>
                    <p>
                      “Materiale og Indhold” dækker over ethvert kreativt udtryk i Din SundhedsGuide og inkluderer uden
                      begrænsninger; video, audio, fotos, billeder, illustrationer, animationer, logoer, værktøjer, tekst,
                      oplysninger, data, software, scripts, eksekverbare filer, grafik, øvelser og træningsdata,
                      træningsprogrammer, interaktive funktioner, designs, varemærker, patenter, lyde, applikationer og
                      enhver immateriel rettighed deri, hvor enhver af disse kan være genereret, givet, eller på anden
                      måde gjort tilgængelig på eller gennem vores services.
                    </p>
                    <p>
                      Termen “Brugergenereret Indhold” dækker over al indhold en bruger indsender, overfører, eller på anden vis leverer til eller gennem brugen af vores Services. Indhold inkluderer tillige al brugergenereret indhold.Termen “Brugergenereret Indhold” dækker over al indhold en bruger indsender, overfører, eller på
                      anden vis leverer til eller gennem brugen af vores Services. Indhold inkluderer tillige al
                      brugergenereret indhold.
                    </p>
                    <p>
                      Al Materiale og Indhold, bortset fra Brugergeneret indhold tilhører Din SundhedsGuide. Du
                      accepterer, at du vil respektere andres intellektuelle ejendomsrettigheder, og erklærer og
                      garanterer, at du har alle de nødvendige rettigheder til at tildele Din SundhedsGuide licens til det
                      brugergenererede indhold, du indsender i forbindelse med vores services.
                    </p>

                    <h2>Mobile Services</h2>
                    <p>
                      For at bruge eller få adgang til vores applikationer, skal du have en kompatibel enhed. Vi kan ikke
                      garantere, at Din SundhedsGuide vil være fuldt ud kompatible med eller tilgængelig på din enhed.
                      Når du har installeret Din SundhedsGuide på din telefon, vil du blive tilbudt at tegne abonnement for
                      at bruge applikationen med alle dens funktioner.
                    </p>
                    <p>
                      Dit telefonselskabs normale takster for meddelelser, data og andre gebyrer gælder stadig.
                    </p>
                    <p>
                      Hvis du har downloadet vores applikationer fra en tredjeparts app-butik (en “App-udbyder”),
                      anerkender og accepterer du at:
                    </p>
                    <ol>
                        <li>
                            <p>
                              Vilkårene er en aftale mellem dig og os, og ikke med app-udbyderen. Mellem Din
                              SundhedsGuide og app-udbyderen, er Din SundhedsGuide eneansvarlig for applikationen
                            </p>
                        </li>
                        <li>
                            <p>
                              App-udbyderen og eventuelle datterselskaber er tredjepartsmodtagere af disse vilkår, som
                              vedrører dine licenser til applikationerne. Når du har accepteret brugervilkårene, vil
                              app-udbyderen have retten (og blive anset for at have accepteret retten), til at håndhæve
                              disse vilkår som relateret til din licens af applikationerne imod dig, som en
                              tredjepartsmodtager deraf; og
                            </p>
                        </li>
                        <li>
                            <p>
                              Du skal også overholde alle gældende tredjepartsvilkår, når du bruger applikationerne.
                            </p>
                        </li>
                    </ol>
                    <br/>

                    <h2>Fortrydelsesret og opsigelse</h2>
                    <p>
                      Du får ved tegning af abonnement straks adgang til vores services. Du giver i den anledning afkald
                      på dine 14-dages fortrydelsesret.
                    </p>
                    <p>
                      Ved at tilmelde dig og bruge vores services, herunder at tilmelde dig gratis prøveversioner af vores
                      services, accepterer du at betale abonnement efter udløb af en eventuel prøveperiode.
                    </p>
                    <p>
                      Når du tilmelder dig vores services, registrerer du din betalingsform. Disse oplysninger skal være
                      fuldstændige og nøjagtige, og du er ansvarlig for at holde dem opdateret. I tilfælde af, at du ønsker
                      at udskifte til dine gældende kortoplysninger, bedes du kontakte os på
                      kontakt@dinsundhedsguide.dk. Du godkender, at vi må opkræve abonnementsbetaling via din
                      betalingsform for vores services og for ethvert andet køb, du vælger at gøre via vores services.
                    </p>
                    <p>
                      Betaling for vores services sker på abonnementsvilkår. Betaling for vores services sker forud, og vil
                      automatisk blive trukket ved begyndelsen af abonnementsperioden, hvilket er givet på dagen, hvor
                      dit abonnement er tegnet. Alle køb af vores services er endelige, og kan ikke refunderes. Dine
                      fakturaer vil fremgå under dine profiloplysninger.
                    </p>
                    <p>
                      Dit abonnement vil automatisk blive fornyet ved udløb af abonnementsperioden. Du modtager
                      advisering herom inden abonnementsperiodens udløb. Du kan opsige vores services når som helst
                      til ophør ved igangværende abonnementsperiodes udløb, dog mindst med en dags varsel. Kontakt;
                      kontakt@dinsundhedsguide.dk eller via. hjælpfunktionen i Din SundhedsGuide, hvis du er i tvivl om,
                      hvordan du opsiger. Når du har opsagt, vil du fortsat have adgang til vores services gennem resten
                      af abonnementsperioden.
                    </p>
                    <p>
                      Hvis betalingen ikke gennemføres korrekt, vil vi underrette dig herom for at løse problemet; men vi
                      forbeholder os retten til at deaktivere eller opsige din adgang til vores services, hvis betaling
                      udebliver.
                    </p>
                      
                    <h2>Sikker brug af Din SundhedsGuide</h2>
                    <p>
                      Ved brug af vores services skal du følge de vejledninger og instruktioner, der er udstedt af Din
                      SundhedsGuide.
                    </p>
                    <p>
                      Det er dit ansvar, at din kropsscreening til enhver tid svarer til dine fysiske forhold. Det er alene dit
                      ansvar at stoppe brugen, hvis du mærker ændringer af alvorlige forværrende karakter.
                    </p>
                    <p>
                      Du har ansvaret og pligten til at rådføre dig med en sundhedsfaglig behandler, hvis du er i tvivl om
                      brugen af Din SundhedsGuide.
                    </p>
                    <p>
                      Ved kontakt til os via hjælp funktion i Din SundhedsGuide vil du blive rådført af en fysioterapeut
                      med erfaring i praksis siden 2005, som kan vejlede dig eller henvise til eksterne sundhedsfaglige
                      personer.
                    </p>

                    <h2>Anvendelse af de 6 kategorier i Din SundhedsGuide</h2>
                    <p>
                      Din SundhedsGuide er ikke et værktøj til diagnosticering af sygdomme og vi indsamler udelukkende
                      data uden analyse af din aktuelle kropstilstand. Din SundhedsGuide er et telemedicinsk produkt,
                      som alene vejleder dig igennem selvbehandlingsteknikker og træningsmetoder, som kan anvendes
                      til behandling eller forebyggelse af dine kropslige udfordringer. På baggrund af den data som vi
                      indsamler sammensætter Din SundhedsGuide et individuelt program med de øvelser, som vores
                      algoritme finder, vil give dig størst gavn.
                    </p>
                    <p>
                      Din SundhedsGuide kan ikke erstatte sund fornuft. Det er meget vigtigt, at du selv er opmærksom
                      på dine smertesignaler under og uden for træningen.
                    </p>
                    <p>
                      Du skal altid rådføre dig hos os eller en anden sundhedsfaglig rådgiver, hvis du har den mindste
                      tvivl om, hvordan du skal reagere på dine smerter i hverdagen eller dine smerter under træningen
                      med Din SundhedsGuide.
                    </p>

                    <h2>Ændringer af vilkårene og produktspecifikke vilkår</h2>
                    <p>
                      Din SundhedsGuide forbeholder sig retten til at ændre disse vilkår. Ændringer gælder ikke med
                      tilbagevirkende kraft, medmindre påkrævet ved lov.
                    </p>
                    <p>
                      I tilfælde, hvor vi ikke beder om din udtrykkelige accept af en revideret version af vilkårene, vil den
                      reviderede version træde i kraft fra den dato specificeret i vilkårene. Din brug af vores services efter
                      den dato udgør din accept af vilkårene og betingelserne for de reviderede vilkår.
                    </p>

                    <h2>Ansvar og erstatning</h2>
                    <p>
                      Det er dig som bruger, der har ansvaret for at benyttelsen af vores services sker forsvarligt.
                    </p>
                    <p>
                      Din SundhedsGuide garanterer ikke, at du opnår et bestemt resultat ved at følge anvisningerne.
                    </p>
                    <p>
                      Din SundhedsGuide er ikke ansvarlig for nogen forværring af dine kropslige forhold som følge af
                      brug af vores services, medmindre dette skyldes væsentlige fejl eller forsømmelser i vores services.
                    </p>
                    <p>
                      Din SundhedsGuide er ansvarsforsikret. Din SundhedsGuide dækker ikke tab højere end
                      dækningsmaksimum på den tegnede forsikring, kr. *, og dækker alene, hvis de erstatningsretlige
                      betingelser er opfyldt.
                    </p>

                    <h2>Erhvervsbrug af vores services</h2>
                    <p>
                      Hvis du bruger vores services på en virksomheds vegne, accepterer virksomheden disse vilkår.
                      Virksomheden vil holde Din SundhedsGuide og dennes tilknyttede virksomheder, funktionærer,
                      repræsentanter og ansatte skadesløse i forhold til ethvert krav eller sagsanlæg og enhver handling,
                      der måtte opstå i forbindelse med brugen af services eller overtrædelsen af disse vilkår, herunder
                      ethvert ansvar og enhver udgift, der måtte opstå i forbindelse med krav, tab, skader, sagsanlæg,
                      domsfældelser, sagsomkostninger og advokatsalærer.
                    </p>

                    <h2>Gældende lovgivning</h2>
                    <p>
                      Disse vilkår er underlagt af og skal fortolkes i overensstemmelse med dansk lovgivning.
                    </p>

                    <h2>Tvister</h2>
                    <p>
                      Hvis der er en tvist, som du eller Din SundhedsGuide ikke kan løse, har du retten til at indsende en
                      klage til:
                    </p>
                    <p style="margin-bottom: -4px;">Center for Klageløsning,</p>
                    <p style="margin-bottom: -4px;">Nævnenes Hus</p>
                    <p style="margin-bottom: -4px;">Toldboden 2</p>
                    <p style="margin-bottom: -4px;">8800 Viborg</p>
                    <a href="mailto:klageportalen@naevneneshus.dk">klageportalen@naevneneshus.dk</a>
                    <br/>
                    <br/>

                    <h2>Kontakt</h2>
                    <p style="margin-bottom: 8px;">
                      Hvis du har feedback, spørgsmål eller kommentarer til vores services, så kontakt venligst:
                    </p>
                    <p style="margin-bottom: -4px;">Din SundhedsGuide ApS</p>
                    <p style="margin-bottom: -4px;">CVR-nr. 41961651</p>
                    <a href="mailto:kontakt@dinsundhedsguide.dk">kontakt@dinsundhedsguide.dk</a>
                    <p style="margin-top: 40px;">Tak fordi du læste alt det her, og velkommen til Din SundhedsGuide!</p>
                </div>
            </v-col>
        </div>
        <Footer></Footer>
    </v-row>
</template>

<script>// @ is an alias to /src
import Footer from "../components/Landing/Footer";

export default {
  name: 'TermsOfService',
  components: {
    Footer,
  },
}
</script>
<style lang="scss" scoped>
// h2 {
//     counter-reset: section;
//     font-size: 1.3rem;
//     font-weight: 500;
//     margin-top: 12px;
// }
h2 {
    font-size: 1.2;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    &::before {
        counter-increment: section;
        content: counter(section) ". " 
    }
}
.TermsOfService{
    width:100%;
    span.heading {
        font-weight: bold;
        display: block;
        font-size: 20px;
        padding: 20px 0;
    }
    span.TOStitle {
        font-weight: bold;
        display: block;
        font-size: 25px;
        padding: 20px 0;
        counter-reset: section;
    }
    span.heading-inline {
        font-weight: bold;
    }
    .contentWrap{
        counter-reset: section;
        max-width: 1200px;
        margin: 0 auto;
    padding: 2.75rem;
  }
  ul li {
    padding: 5px 2px;
  }
  ul, ol {
    margin: 10px 0;
  }
}
</style>
