<template>
  <div
    v-if="loggedInUser.role == 'Admin'"
    class="Groupings d-flex justify-center"
    style="padding: 10px; padding-top: 20px"
  >
    <v-toolbar v-if="dialog" flat color="white">
      <v-spacer />
      <v-dialog
        :persistent="loading"
        :no-click-animation="true"
        v-model="dialog"
        max-width="1500px"
      >
        <v-card tile :loading="loading" :loader-height="12">
          <v-form ref="form" v-model="valid">
            <v-card class="title-container" color="primary">
              <v-card-title v-if="isCreateGrouping">
                <span class="headline">Opret Gruppe</span>
              </v-card-title>
              <v-card-title v-if="isEditGrouping">
                <span class="headline"
                  >Rediger Gruppe: {{ editedGrouping.name }}</span
                >
              </v-card-title>
              <div style="flex: 1 1 auto"></div>
              <div
                style="display: flex; align-items: center; margin-right: 10px"
              >
                <v-icon
                  style="
                    color: white;
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                  "
                  @click="closeClicked"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-card>

            <div class="saveBtnContainer" @click="save">
              <div v-if="isCreateGrouping">OPRET GRUPPE</div>
              <div v-if="isEditGrouping">GEM ÆNDRINGER</div>
            </div>

            <v-card-text>
              <v-container
                style="margin-bottom: 40px; max-width: none; padding: 0 40px"
              >
                <v-row style="margin-bottom: 20px">
                  <v-col cols="12" sm="6" md="4" style="padding-left: 0">
                    <v-text-field
                      v-model="editedGrouping.name"
                      label="Titel"
                      hint="Indtast venligst et gruppe navn"
                      :error-messages="errorMessages"
                      :rules="[
                        () => !!editedGrouping.name || 'This field is required',
                      ]"
                      required
                    />
                    <v-text-field v-model="editedGrouping.tag" label="Tag" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="description-container">
                    <v-textarea
                      auto-grow
                      clearable
                      clearable-icon="cancel"
                      label="Beskrivelse"
                      hint="Indtast venligst en beskrivelse"
                      outlined
                      rows="8"
                      style="border-radius: 0"
                      v-model="editedGrouping.description"
                      :error-message="errorMessages"
                      :rules="[
                        () =>
                          !!editedGrouping.description ||
                          'You must enter a valid description',
                      ]"
                      required
                    />
                  </v-col>
                </v-row>

                <!-- Content -->
                <v-row>
                  <v-col>
                    <!-- Users -->
                    <v-row class="content-container">
                      <v-col style="padding-bottom: 5px">
                        <v-row>
                          <v-col class="content-col">
                            <v-row style="flex: 0 1 auto">
                              <span class="headline"
                                >Brugere i denne gruppe</span
                              >
                              <div style="flex: 1 1 auto"></div>
                              <div class="content-count-root">
                                <div class="content-count-container">
                                  <span class="headline">{{
                                    editedGroupingUserCount
                                  }}</span>
                                </div>
                                <div class="content-count-text-container">
                                  <span class="headline">På listen</span>
                                </div>
                              </div>
                            </v-row>
                            <v-row style="flex: 0 1 auto">
                              <v-text-field
                                v-model="searchInput_Users"
                                append-icon="mdi-magnify"
                                label="Søg i brugere i denne gruppe"
                              />
                            </v-row>
                            <v-row
                              style="flex: 1 1 auto"
                              class="simple-list-container"
                            >
                              <SimpleList
                                itemKey="userID"
                                :allowMoveElements="true"
                                :clearTrigger="clearTrigger_Users"
                                :contents="editedGrouping.users"
                                :disablePagination="true"
                                :headers="headers_Users"
                                :search="searchInput_Users"
                                :selectNext="selectNext_Users"
                                @selectedRowChange="selChanged_Users"
                                :showEditColumn="true"
                                @editRow="editUser"
                              />
                            </v-row>
                          </v-col>
                          <v-col class="content-col-save-btns">
                            <div class="move-btns-root">
                              <div class="move-btns-container">
                                <div style="margin-bottom: 30px">
                                  Tilføj/Fjern
                                </div>
                                <div style="margin-bottom: 15px">
                                  <img
                                    class="move-arrow"
                                    :class="[
                                      !!selectedEntry_Users && 'clickable',
                                    ]"
                                    :src="
                                      !!selectedEntry_Users
                                        ? require('@/assets/Pil_aktiv.png')
                                        : require('@/assets/Pil_inaktiv.png')
                                    "
                                    @click="removeUserFromGrouping"
                                  />
                                </div>
                                <div>
                                  <img
                                    class="move-arrow move-arrow-rotated"
                                    :class="[
                                      !!selectedEntry_UsersAll && 'clickable',
                                    ]"
                                    :src="
                                      !!selectedEntry_UsersAll
                                        ? require('@/assets/Pil_aktiv.png')
                                        : require('@/assets/Pil_inaktiv.png')
                                    "
                                    @click="moveUserToGrouping"
                                  />
                                </div>
                              </div>
                            </div>
                          </v-col>
                          <v-col class="content-col">
                            <v-row style="flex: 0 1 auto">
                              <span class="headline">Alle brugere</span>
                            </v-row>
                            <v-row style="flex: 0 1 auto">
                              <v-text-field
                                v-model="searchInput_UsersAll"
                                append-icon="mdi-magnify"
                                label="Søg i alle brugere"
                              />
                            </v-row>
                            <v-row class="simple-list-container">
                              <SimpleList
                                itemKey="userID"
                                :clearTrigger="clearTrigger_UsersAll"
                                :contents="allUsers"
                                :disablePagination="true"
                                :headers="headers_UsersAll"
                                :search="searchInput_UsersAll"
                                :selectNext="selectNext_UsersAll"
                                @selectedRowChange="selChanged_UsersAll"
                              />
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- \ Users -->
                    <!-- Online Courses -->
                    <v-row class="content-container">
                      <v-col style="padding-bottom: 5px">
                        <v-row>
                          <v-col class="content-col">
                            <v-row style="flex: 0 1 auto">
                              <span class="headline"
                                >Online kurser på gruppen</span
                              >
                              <div style="flex: 1 1 auto"></div>
                              <div class="content-count-root">
                                <div class="content-count-container">
                                  <span class="headline">{{
                                    editedGroupingCourseCount
                                  }}</span>
                                </div>
                                <div class="content-count-text-container">
                                  <span class="headline">På listen</span>
                                </div>
                              </div>
                            </v-row>
                            <v-row style="flex: 0 1 auto">
                              <v-text-field
                                v-model="searchInput_Courses"
                                append-icon="mdi-magnify"
                                label="Søg i alle Online kurser"
                              />
                            </v-row>
                            <v-row
                              style="flex: 1 1 auto"
                              class="simple-list-container"
                            >
                              <SimpleList
                                itemKey="courseID"
                                :clearTrigger="clearTrigger_Courses"
                                :contents="editedGrouping.courses"
                                :disablePagination="true"
                                :headers="headers_Courses"
                                :search="searchInput_Courses"
                                :selectNext="selectNext_Courses"
                                :showEditColumn="true"
                                @editRow="editCourse"
                                @selectedRowChange="selChanged_Courses"
                              >
                              </SimpleList>
                              <v-dialog
                                :no-click-animation="true"
                                v-model="courseDialog"
                                max-width="1600px"
                              >
                                <online-course-form
                                  v-if="courseDialog"
                                  :course="editedCourse"
                                  :duplicating="false"
                                  @saved="savedCourse"
                                  @close="courseDialog = false"
                                />
                              </v-dialog>
                            </v-row>
                          </v-col>
                          <v-col class="content-col-save-btns">
                            <div class="move-btns-root">
                              <div class="move-btns-container">
                                <div style="margin-bottom: 30px">
                                  Tilføj/Fjern
                                </div>
                                <div style="margin-bottom: 15px">
                                  <img
                                    class="move-arrow"
                                    :class="[
                                      !!selectedEntry_Courses && 'clickable',
                                    ]"
                                    :src="
                                      !!selectedEntry_Courses
                                        ? require('@/assets/Pil_aktiv.png')
                                        : require('@/assets/Pil_inaktiv.png')
                                    "
                                    @click="removeCourseFromGrouping"
                                  />
                                </div>
                                <div>
                                  <img
                                    class="move-arrow move-arrow-rotated"
                                    :class="[
                                      !!selectedEntry_CoursesAll && 'clickable',
                                    ]"
                                    :src="
                                      !!selectedEntry_CoursesAll
                                        ? require('@/assets/Pil_aktiv.png')
                                        : require('@/assets/Pil_inaktiv.png')
                                    "
                                    @click="moveCourseToGrouping"
                                  />
                                </div>
                              </div>
                            </div>
                          </v-col>
                          <v-col class="content-col">
                            <v-row style="flex: 0 1 auto">
                              <span class="headline">Alle Online kurser</span>
                            </v-row>
                            <v-row style="flex: 0 1 auto">
                              <v-text-field
                                v-model="searchInput_CoursesAll"
                                append-icon="mdi-magnify"
                                label="Søg i alle Online kurser"
                              />
                            </v-row>
                            <v-row class="simple-list-container">
                              <SimpleList
                                itemKey="courseID"
                                :clearTrigger="clearTrigger_CoursesAll"
                                :contents="
                                  allCourses.filter(
                                    (x) => !x.isFree || !x.isPublic
                                  )
                                "
                                :disablePagination="true"
                                :headers="headers_CoursesAll"
                                :search="searchInput_CoursesAll"
                                :selectNext="selectNext_CoursesAll"
                                @selectedRowChange="selChanged_CoursesAll"
                              />
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!-- \ Online Courses -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <List
      title="Grupper"
      label="Søg i grupper"
      :canAddOrRemove="true"
      :canDuplicate="true"
      :contents="groupings"
      :hasStats="false"
      :headers="headers"
      :showGroupings="false"
      @addClicked="addClicked"
      @deleteClicked="deleteClicked"
      @duplicateClicked="duplicateClicked"
      @editClicked="editClicked"
    />

    <div v-if="userDialog">
      <v-dialog v-model="userDialog" scrollable max-width="1500px">
        <UserForm
          formTitle="Rediger Bruger"
          :_editedBase="editedUser"
          :editedIndex="0"
          @close="userDialog = false"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import List from "@/components/List.vue";
import SimpleList from "@/components/SimpleList.vue";
import UserForm from "@/components/UserForm";
import OnlineCourseForm from "@/components/OnlineCourseForm.vue";

export default {
  name: "Groupings",
  components: {
    List,
    SimpleList,
    UserForm,
    OnlineCourseForm,
  },
  data: () => ({
    courseDialog: false,
    allCourses: [],
    allUsers: [],
    clearTrigger_Courses: 0,
    clearTrigger_CoursesAll: 0,
    clearTrigger_Users: 0,
    clearTrigger_UsersAll: 0,
    dialog: false,
    editedGrouping: {},
    errorMessages: "",
    headers: [
      { text: "Gruppe ID", align: "start", value: "id", sortable: true },
      { text: "Navn", value: "name", sortable: true },
      { text: "Brugere", value: "userCount", sortable: true },
      { text: "Kurser", value: "courses.length", sortable: true },
      { text: "Actions", value: "actions", sortable: false, align: "end" },
    ],
    headers_Courses: [
      { text: "Navn", align: "start", value: "name", sortable: true },
      { text: "Er betalt", align: "start", value: "hasPaid", sortable: false },
    ],
    headers_CoursesAll: [
      { text: "Kursus ID", align: "start", value: "id", sortable: true },
      { text: "Navn", value: "name", sortable: true },
    ],
    headers_Users: [
      { text: "Navn", align: "start", value: "name", sortable: true },
      { text: "Email", value: "email", sortable: true },
    ],
    headers_UsersAll: [
      { text: "Bruger ID", align: "start", value: "id", sortable: true },
      { text: "Navn", value: "name", sortable: true },
      { text: "Email", value: "email", sortable: true },
    ],
    isCreateGrouping: false,
    isEditGrouping: false,
    searchInput_Courses: "",
    searchInput_CoursesAll: "",
    searchInput_Users: "",
    searchInput_UsersAll: "",
    selectedEntry_Courses: null,
    selectedEntry_CoursesAll: null,
    selectedEntry_Users: null,
    selectedEntry_UsersAll: null,
    selectNext_Courses: 0,
    selectNext_CoursesAll: 0,
    selectNext_Users: 0,
    selectNext_UsersAll: 0,
    valid: false,
    userDialog: false,
  }),
  computed: {
    ...mapState({
      courses: (state) => state.courses.courses,
      groupings: (state) => state.groupings.groupings,
      loggedInUser: (state) => state.auth.user,
      loading: (state) => state.loading,
      loggedIn: (state) => !!state.auth.token,
      users: (state) => state.users.users,
    }),
    editedGroupingCourseCount() {
      return this.editedGrouping?.courses?.length ?? "N/A";
    },
    editedGroupingUserCount() {
      return this.editedGrouping?.users?.length ?? "N/A";
    },
  },
  created() {
    if (!this.loggedIn) {
      this.$router.push({
        path: "/",
      });
    } else {
      this.initialize();
    }
  },
  methods: {
    editCourse(item) {
      this.editedCourse = Object.assign({}, item);
      this.courseDialog = true;
    },
    async savedCourse(course) {
      Object.assign(
        this.editedGrouping.courses.find((x) => x.id == course.id),
        course
      );
      this.courseDialog = false;
    },
    addClicked() {
      this.resetAllSearchInputs();
      this.editedGrouping = this.getEmptyGrouping();
      this.filterArrays();
      this.isCreateGrouping = true;
      this.dialog = true;
    },
    closeClicked() {
      this.dialog = false;
    },
    deleteClicked(item) {
      confirm("Er du sikker på at du vil slette denne gruppe?") &&
        this.$store.dispatch("deleteGrouping", item.id).then(() => {
          this.initialize();
        });
    },
    async duplicateClicked(item) {
      await this.editedGroupingGetChildData(item);
      const grouping = this.editedGroupingToUpdateOrCreateObj(item);
      this.$store
        .dispatch("duplicateGrouping", _.cloneDeep(grouping))
        .then(() => {
          this.initialize();
        });
    },
    async editClicked(item) {
      await this.editedGroupingGetChildData(item);
      this.editedGrouping = item;
      this.filterArrays();
      this.isEditGrouping = true;
      this.dialog = true;
    },
    async editedGroupingGetChildData(groupingObj) {
      const groupingId = groupingObj.id;
      this.$set(
        groupingObj,
        "users",
        await this.$store.dispatch("getGroupingUsers", groupingId)
      );
      this.$set(
        groupingObj,
        "courses",
        await this.$store.dispatch("getGroupingCourses", groupingId)
      );
    },
    editedGroupingToUpdateOrCreateObj(groupingObj) {
      const courses = groupingObj.courses.filter((c) => !c.isLocked);
      return {
        description: groupingObj.description,
        id: groupingObj.id,
        name: groupingObj.name,
        tag: groupingObj.tag,
        courseIds: courses.map((x) => x.id),
        userIds: groupingObj.users.map((x) => x.id),
        coursesPaidFor: courses.filter((x) => x.hasPaid).map((x) => x.id),
      };
    },
    filterArrays() {
      this.allCourses = this.filterArrayWithArray(
        this.courses,
        this.editedGrouping.courses
      );
      this.allUsers = this.filterArrayWithArray(
        this.users,
        this.editedGrouping.users
      );
    },
    filterArrayWithArray(contentArr, filterArr) {
      return [...contentArr].filter(
        (elem) => filterArr?.findIndex((item) => item.id === elem.id) < 0
      ); // assumed objects are using 'id' as propertyname
    },
    getEmptyGrouping() {
      return {
        courses: [
          ...this.courses
            .map((c) => ({
              ...c,
              isLocked: c.isPublished && c.isPublic && c.isFree,
            }))
            .filter((c) => c.isLocked),
        ],
        description: "",
        id: -1,
        name: "",
        users: [],
      };
    },
    initialize() {
      this.$store.dispatch("getAllGroupings");
      this.$store.dispatch("getAllCourses");
      this.$store.dispatch("getAllUsers");
    },
    moveCourseToGrouping() {
      if (!this.selectedEntry_CoursesAll) return;
      if (
        this.editedGrouping.courses.indexOf(this.selectedEntry_CoursesAll) < 0
      ) {
        this.editedGrouping.courses.push(this.selectedEntry_CoursesAll);
      }
      this.filterArrays();
      this.selectNext_CoursesAll = this.selectNext_CoursesAll + 1;
      this.selectedEntry_CoursesAll = null;
    },
    moveUserToGrouping() {
      if (!this.selectedEntry_UsersAll) return;
      if (this.editedGrouping.users.indexOf(this.selectedEntry_UsersAll) < 0) {
        this.editedGrouping.users.push(this.selectedEntry_UsersAll);
      }
      this.filterArrays();
      this.selectNext_UsersAll = this.selectNext_UsersAll + 1;
      this.selectedEntry_UsersAll = null;
    },
    removeCourseFromGrouping() {
      if (!this.selectedEntry_Courses) return;
      this.editedGrouping.courses.splice(
        this.editedGrouping.courses.indexOf(this.selectedEntry_Courses),
        1
      );
      this.clearTrigger_Courses = this.clearTrigger_Courses + 1;
      this.filterArrays();
      this.selectNext_Courses = this.selectNext_Courses + 1;
      this.selectedEntry_Courses = null;
    },
    removeUserFromGrouping() {
      if (!this.selectedEntry_Users) return;
      this.editedGrouping.users.splice(
        this.editedGrouping.users.indexOf(this.selectedEntry_Users),
        1
      );
      this.clearTrigger_Users = this.clearTrigger_Users + 1;
      this.filterArrays();
      this.selectNext_Users = this.selectNext_Users + 1;
      this.selectedEntry_Users = null;
    },
    resetAllSearchInputs() {
      this.searchInput_Courses = "";
      this.searchInput_CoursesAll = "";
      this.searchInput_Users = "";
      this.searchInput_UsersAll = "";
    },
    save() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      const grouping = this.editedGroupingToUpdateOrCreateObj(
        this.editedGrouping
      );
      if (this.isCreateGrouping) {
        // Create Grouping
        this.$store.dispatch("createGrouping", grouping).then(
          () => {
            this.initialize();
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (this.isEditGrouping) {
        // Edit Course
        const obj = {
          Idx: this.editedGrouping.id,
          updatedGrouping: grouping,
        };
        this.$store.dispatch("updateGrouping", obj).then(
          () => {
            this.initialize();
          },
          (error) => {
            console.log(error);
          }
        );
      }

      this.dialog = false;
    },
    selChanged_Courses(val) {
      this.selectedEntry_Courses = val;
      if (
        this.selectedEntry_CoursesAll != null &&
        this.selectedEntry_Courses != null
      ) {
        this.selectedEntry_CoursesAll = null;
        this.clearTrigger_CoursesAll = this.clearTrigger_CoursesAll + 1;
      }
    },
    selChanged_CoursesAll(val) {
      this.selectedEntry_CoursesAll = val;
      if (
        this.selectedEntry_Courses != null &&
        this.selectedEntry_CoursesAll != null
      ) {
        this.selectedEntry_Courses = null;
        this.clearTrigger_Courses = this.clearTrigger_Courses + 1;
      }
    },
    selChanged_Users(val) {
      this.selectedEntry_Users = val;
      if (
        this.selectedEntry_UsersAll != null &&
        this.selectedEntry_Users != null
      ) {
        this.selectedEntry_UsersAll = null;
        this.clearTrigger_UsersAll = this.clearTrigger_UsersAll + 1;
      }
    },
    selChanged_UsersAll(val) {
      this.selectedEntry_UsersAll = val;
      if (
        this.selectedEntry_Users != null &&
        this.selectedEntry_Users != null
      ) {
        this.selectedEntry_Users = null;
        this.clearTrigger_Users = this.clearTrigger_Users + 1;
      }
    },
    editUser(item) {
      console.log(item);
      this.editedUser = Object.assign({}, item);
      this.userDialog = true;
    },
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) {
        this.isCreateGrouping = false;
        this.isEditGrouping = false;
        this.editedGrouping = this.getEmptyGrouping();
        this.resetAllSearchInputs();
      }
    },
  },
  mounted() {
    this.editedGrouping = this.getEmptyGrouping();
  },
};
</script>

<style scoped lang="scss">
.saveBtnContainer {
  background: transparent;
  color: #738ea6;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 65px;
  padding: 12px 15px;
  z-index: 2;
  float: right;
}

.content-container {
  border: 1px solid black;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 0;
}

.content-container:first-child {
  margin-top: 0px;
}

.content-container:not(:first-child) {
  margin-top: 55px;
}

.content-count-root {
  color: #738ea6;
  display: flex;
  flex-direction: row;
  line-height: 1.2rem;
}

.content-count-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  border-bottom: 2px solid #738ea6;
  width: 35px;
  margin-right: 5px;
}

.content-count-text-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  border: 2px solid transparent;
}

.move-btns-root {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.move-btns-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-field-right >>> input {
  text-align: end;
}

.title-container {
  color: white;
  border: none;
  border-radius: 0;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
}

.description-container {
  margin-right: 40px;
  padding: 0;
}

.description-container >>> fieldset {
  border-color: black;
}

.move-arrow {
  height: 30px;
  width: 35px;
  cursor: not-allowed;

  &.move-arrow-rotated {
    transform: rotate(180deg);
  }

  &.clickable {
    cursor: pointer;
  }
}

.simple-list-container {
  flex: 1 1 auto;
  height: 300px;
  overflow: scroll;
}

.simple-list-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.content-col {
  flex: 0 0 45%;
  max-width: 45%;
}

.content-col-save-btns {
  flex: 0 0 10%;
  max-width: 10%;
  padding-left: 0;
  padding-right: 0;
}
</style>
