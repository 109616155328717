<template>
    <div
        class="LoginModule"
        style="padding: 10px; padding-top: 0px; width: 100%"
    >
        <v-container>
            <v-row style="padding: 10px">
                <v-card tile width="100%">
                    <v-form ref="form" v-model="valid" width="100%">
                        <v-card-title> Login </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="8" md="12">
                                        <v-text-field
                                            v-model="Email"
                                            label="Email"
                                            hint="Indtast venligst din email"
                                            :error-messages="errorMessages"
                                            :rules="[
                                                () =>
                                                    !!Email ||
                                                    'Email er krævet',
                                            ]"
                                            required
                                            @keydown.enter="Login"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="8" md="12">
                                        <v-text-field
                                            v-model="Password"
                                            label="Kodeord"
                                            hint="Indtast venligst dit kodeord"
                                            :error-messages="errorMessages"
                                            :rules="[
                                                () =>
                                                    !!Password ||
                                                    'Kodeord er krævet',
                                            ]"
                                            :type="
                                                showPwdMessage
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            required
                                            @keydown.enter="Login"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn
                                class="hidden-sm-and-down"
                                text
                                @click="ForgotMyPassword"
                                >Glemt Kodeord?</v-btn
                            >
                            <v-spacer class="hidden-sm-and-down"></v-spacer>
                            <v-btn
                                tile
                                color="primary"
                                class="hidden-sm-and-down"
                                @click="Login"
                                >Log Ind</v-btn
                            >
                            <v-btn
                                tile
                                color="primary"
                                block
                                class="hidden-md-and-up"
                                style="margin-left: 0px"
                                @click="Login"
                                >Log Ind</v-btn
                            >
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-row>
            <v-row class="hidden-sm-and-up justify-center">
                <v-btn text @click="ForgotMyPassword">Glemt Kodeord?</v-btn>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "LoginModule",
    props: ["login_path"],
    data: () => ({
        Email: "",
        Password: "",
        errorMessages: "",
        showPwdMessage: false,
        valid: false,
        rules: {
            required: (value) => !!value || "Required.",
            min: (v) => v.length >= 8 || "Min 8 characters",
            emailMatch: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        },
    }),
    computed: {
        ...mapState({
            loggedIn: (state) => !!state.auth.token,
            alertText: (state) => state.alertText,
            alertBool: (state) => state.alertBool,
            alertColor: (state) => state.alertColor,
            users: (state) => state.users,
        }),
    },
    methods: {
        Login() {
            this.$store.commit("setLoading", true);
            this.$store
                .dispatch("login", {
                    Email: this.Email,
                    Password: this.Password,
                    router: this.$router,
                })
                .then(() => this.$emit("LoggedIn"))
                .finally(() => {
                    this.$store.commit("setLoading", false);
                    this.Email = '';
                    this.Password = '';
                });
        },
        CreateNewUserClicked() {
            this.$emit("CreateUser", { CreateUser: true });
        },
        ForgotMyPassword() {
            this.$emit("ForgotMyPassword");
        },
    },
};
</script>

<style>
</style>