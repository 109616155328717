<template>
    <v-container>
        <v-row style="padding:10px">
            <v-card flat :loading="loading" width="100%">
                <v-form  ref="form" v-model="valid">
                    <v-card-title>
                        <span class="headline">Skift password</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-card flat>
                                        <v-card-text>
                                            <v-text-field v-model="oldPassword" 
                                                          label="Nuværende Kodeord" 
                                                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                          :tabindex="1"
                                                          :type="show1 ? 'text' : 'password'" 
                                                          @click:append="show1 = !show1" />
                                            <v-text-field v-model="newPassword" 
                                                          label="Nyt Kodeord" 
                                                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                          :tabindex="2"
                                                          :type="show2 ? 'text' : 'password'" 
                                                          @click:append="show2 = !show2" />
                                            <v-text-field v-model="confirmNewPassword" 
                                                          label="Bekræft Nyt Kodeord" 
                                                          :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                                                          :tabindex="3"
                                                          :type="show3 ? 'text' : 'password'" 
                                                          @click:append="show3 = !show3"/>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="ChangeMyPassword">Skift mit Kodeord.</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>

                    </v-card-actions>
                </v-form>
            </v-card>
        </v-row>
    </v-container>
</template>


<script>
    //import moment from 'moment'
    import { mapState } from 'vuex'
    //import _ from 'lodash';

    export default {
        name: 'HomeSecurity',
        data: () => ({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            errorMessages: '',
            showPwdMessage: false,
            valid: false,
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            show6: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            }
        }),
        computed: {
            passwordConfirmationRule() {
                return this.newPassword === this.confirmNewPassword || "Passwords must match";
            },
            ...mapState({
                loading: state => state.loading,
                loggedInUser: state => state.auth.user
            })
        },
        methods: {
            async ChangeMyPassword() {
                if (this.newPassword !== this.confirmNewPassword) {
                    this.$store.commit('setAlertErrorSnack', "nye passwords matcher ikke.");
                    return;
                }
                await this.$store.dispatch("UpdateMyUserPassword", {
                    password: this.oldPassword,
                    newPassword: this.newPassword,
                });
                this.oldPassword = "";
                this.newPassword = "";
                this.confirmNewPassword = "";
            },
        }
    }
</script>

<style>
</style>


