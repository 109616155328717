<template>
	<v-container>
		<v-row>
			<v-col col="12">
				<v-card v-if="ShowCreateSubscription == true || ShowAuthorizeSubscription == true" :loading="loading" flat
					width="100%">
					<v-card-text>
						<div style="text-align:center;">
							<p class="salespitch-header" style="margin-top: 0px;">
								Simpel smertelindring i din lomme som kun kræver 3 minutters dagligt fokus.
							</p>
							<p class="salespitch-body">
								Vi har udviklet en sundhedsløsning, som indeholder selvbehandlingsteknikker, øvelser, <br>
								redskaber og træningsmetoder, som vi har afprøvet i fysioterapeutisk praksis siden 2005.
							</p>

							<p class="salespitch-header">
								2 ugers gratis adgang
							</p>
							<p class="salespitch-body">
								Appens fulde funktionalitet er gratis at benytte de første 14 dage. <br />
								Efter de 14 dages prøveperiode, kan du vælge at fortsætte i appen som en gratis-bruger.
								Denne bruger har <br />
								dog begrænset funktionalitet og færre øvelser end antallet, du tidligere har haft adgang
								til.
							</p>
							<p class="salespitch-body">
								Ønsker du at oprette et abonnement efter din prøveperiode, vil du beholde din eksisterende
								funktionalitet <br />
								og have adgang til alle øvelser i appen. Prisen for et abonnement er 59 kr. pr. måned.
							</p>

							<p class="salespitch-header">
								Trækdato
							</p>
							<p class="salespitch-body">
								Dagen efter din gratis adgang er udløbet, vil du blive trukket 59 kr. på din konto.
								Efterfølgende <br />
								trækninger i din løbende abonnementsbetaling vil ske 30 dage efter din forrige trækdato.
							</p>

							<p class="salespitch-header">
								Opsigelse af abonnement
							</p>
							<p class="salespitch-body">
								Du kan til enhver tid opsige dit abonnement under “Min konto”. Ved opsigelse er ophør af dit
								abonnement <br />
								angivet til din igangværende abonnementsperiodes udløb.
							</p>

							<p class="salespitch-body">
								Du kan til enhver tid annullere dit abonnement herunder.
								<br />
								Abonnementet stopper efter udløbet af den betalte periode.
							</p>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" tile v-if="ShowAuthorizeSubscription == true"
							@click="CreateSubscription">Bekræft afventende Abonnement</v-btn>
						<v-btn color="primary" tile v-if="ShowCreateSubscription == true" @click="CreateSubscription">Opret
							Abonnement</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col col="12">
				<v-card :loading="loading" flat width="100%">
					<v-card-title>
						Abonnementer
						<v-spacer></v-spacer>
						<v-text-field v-model="search" append-icon="mdi-magnify" label="Søg i Abonnementer" single-line
							hide-details>
						</v-text-field>
					</v-card-title>
					<v-card-text>
						Tryk på et abonnement i listen for at se dine fakturaer tilhørende abonnementet.
						<v-data-table style="margin:10px" :headers="subscriptionHeaders" :items="subscriptions"
							:footer-props="{ 'items-per-page-options': [5, 15, 25, 35, 99] }" :items-per-page="25"
							class="elevation-1" :search="search" :expanded.sync="expanded" @click:row="expandRow">
							<template v-slot:item.actions="{ item }">
								<v-btn text small color="error" v-if="hasOneSubscriptionActive(item) == true"
									@click="CancelSubscription(item)">Afbryd Abonnement</v-btn>
							</template>
							<template v-slot:item.open="{}">
								<v-icon medium>mdi-arrow-expand-vertical</v-icon>
							</template>

							<template v-slot:item.created="{ item }">{{ formatDate(item.created) }}</template>
							<template v-slot:item.updated="{ item }">{{ formatDate(item.updated) }}</template>

							<template v-slot:item.status="{ item }">
								<span style="color:#77BC27" v-if="item.status == 'Active'">Aktivt</span>
								<span style="color:#DD8800" v-else-if="isPendingCancelled(item)">Udløber {{
									formatDate(item.nextChargeDate) }}</span>
								<span style="color:#FF5B6C" v-else-if="isFullyCancelled(item)">Aflyst</span>
								<span style="color:#E2CB2E" v-if="item.status == 'Pending'">Afventer Oplysninger</span>
							</template>

							<template v-slot:item.feeExcludingVat="{ item }">{{ item.feeExcludingVat }},-</template>
							<template v-slot:item.subscriptionFee="{ item }">{{ item.subscriptionFee }},-</template>
							<template v-slot:item.nextChargeDate="{ item }">
								<span v-if="item.nextChargeDate == null"> - </span>
								<span v-else-if="isPendingCancelled(item)" style="color:#DD8800">{{
									formatDate(item.nextChargeDate) }}</span>
								<span v-else-if="isFullyCancelled(item)" style="color:#FF5B6C">{{
									formatDate(item.nextChargeDate) }}</span>
								<span v-else>{{ formatDate(item.nextChargeDate) }}</span>
							</template>

							<template v-slot:expanded-item="{ headers, item }">
								<td align="center" justify="center" :colspan="headers.length">
									<v-card flat width="100%" style="margin:10px">
										<v-card-title>
											Faktura visning for abonnement: {{ item.id }}
										</v-card-title>
										<v-card-text>
											<v-data-table :headers="invoiceHeaders" :items="sortedInvoiceList"
												:footer-props="{ 'items-per-page-options': [5, 15, 25, 35, 99] }"
												:items-per-page="25" class="elevation-1">
												<template v-slot:item.status="{ item }">
													<span style="color:#E2CB2E"
														v-if="item.status == 'Pending'">Afventer</span>
													<span style="color:#77BC27"
														v-if="item.status == 'Success'">Gennemført</span>
													<span style="color:#FF5B6C" v-if="item.status == 'Failed'">Fejlet</span>
													<span style="color:#DD8800"
														v-if="item.status == 'Cancelled'">Anulleret</span>
												</template>
												<template v-slot:item.created="{ item }">{{ formatDate(item.created)
												}}</template>
												<template v-slot:item.processed="{ item }">{{ formatDate(item.processed)
												}}</template>
												<template v-slot:item.feeExcludingVat="{ item }">{{ item.feeExcludingVat
												}},-</template>
												<template v-slot:item.feeTaken="{ item }">{{ item.feeTaken }},-</template>
											</v-data-table>
										</v-card-text>
									</v-card>
								</td>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
				<v-card :v-if="ShowGoogleAppleSubscriptionNotice" color="info">
					<v-card-text>
						<h2 style="color:#eee">OBS:</h2>
						<p style="margin:10px; color:#eee">
							Apple og Google kræver at vi tilbyder abonnementer igennem appen via. deres services, og
							tillader ikke at vi tilbyder vores egen betalingsmulighed via. Appen.<br />
							Har du tidligere været skrevet op via. Google og/eller Apple, skal du aflyse dit abonnement i
							vores service før du opretter et nyt abonnement.
							Knappen nedenfor "aflys abonnement hos tredjepart", aflyser kun abonnementet i vores system, og
							du skal derfor være opmærksom på <u>også</u> at aflyse abonnementet inde i google play/app
							store, på din telefon.<br /><br />

							Muligheden her på hjemmesiden for at aflyse et abonnement aflyser derfor <i>IKKE</i>
							eksisterende abonnementer hos Google og Apple.
						</p>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="newUserDialog" max-width="1000">
			<v-card>
				<v-card-title style="word-break: normal;" class="headline">
					Opret dit abonnement nu og få 14 dages gratis prøvetid i Din Sundhedsguide-appen.
				</v-card-title>

				<v-card-text>
					Oplever du tilbagevendende eller kroniske smerter - og vil du løse det med simple hverdagsbevægelser og
					selvbehandling?
					<br>
					<br>
					Download appen i <a target="_blank"
						href="https://apps.apple.com/us/app/dinsundhedsguide/id1530217830">App-store</a> eller <a
						target="_blank"
						href="https://play.google.com/store/apps/details?id=com.fyzionergi.dinsundhedsguide">GooglePLAY</a>
					og log ind med din e-mail adresse og dit valgte kodeord for at få adgang til video-øvelserne.
					<br>
					<br>
					Vi har hjulpet mange smerteramte til et liv med færre bekymringer og mindre begrænsninger i hverdagen
					med disse simple øvelser, som hverken kræver tid eller træningstøj.
					<br>
					<br>
					Hvis du vælger at udfylde Krops-Screeningen i appen, kan du få adgang til et personligt tilpasses
					video-feed, hvor du kan oprette dine egne favoritlister med de videoer, som passer bedst til dig og din
					hverdag.
					<br>
					<br>
					Er du klar til at investere 1-3 minutter dagligt til simple bevægelser som kan smertelindre dig?
				</v-card-text>

				<v-card-actions>
					<div class="mx-auto">

						<v-btn dark color="#4B788E" class="mb-2 white--text d-block"
							@click="newUserDialog = false; CreateSubscription();">
							Ja tak - jeg er klar
						</v-btn>
						<v-btn class="d-block mx-auto" style="word-break: normal; font-size:12px" color="black"
							@click=" newUserDialog = false" outlined dark text>
							Nej - ikke lige nu
						</v-btn>
					</div>

				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	name: 'HomeSubscriptionManager',
	props: ['newUser'],
	data: () => ({
		search: '',
		subscriptionHeaders: [
			{ text: 'Udvid', value: 'open', sortable: false, filterable: false },
			{ text: 'Abonnements Id', value: 'id', sortable: true, filterable: false },
			{ text: 'Registreret - d.', value: 'created', sortable: true, filterable: false },
			{ text: 'Sidst Opdateret - d.', value: 'updated', sortable: true, filterable: false },
			{ text: 'Type', value: 'type', sortable: true, filterable: true },
			{ text: 'Status', value: 'status', sortable: true, filterable: true },
			{ text: 'Pris u. moms', value: 'feeExcludingVat', sortable: false, filterable: false },
			{ text: 'Pris', value: 'subscriptionFee', sortable: false, filterable: false },
			{ text: 'Udbyder', value: 'subscriptionProvider', sortable: false, filterable: true },
			{ text: 'Næste træk d.', value: 'nextChargeDate', sortable: false, filterable: false },
			{ text: 'Actions', value: 'actions', sortable: false, filterable: false },
		],
		invoiceHeaders: [
			{ text: 'Id', align: 'start', value: 'id', sortable: true, filterable: true },
			{ text: 'AbonnementsId', value: 'subscriptionId', sortable: true, filterable: true },
			{ text: 'Registreret - d', value: 'created', sortable: true, filterable: true },
			{ text: 'Penge trukket - d.', value: 'processed', sortable: true, filterable: true },
			{ text: 'Status', value: 'status', sortable: true, filterable: true },
			{ text: 'Pris u. moms', value: 'feeExcludingVat', sortable: false, filterable: false },
			{ text: 'Pris', value: 'feeTaken', sortable: false, filterable: false },
		],
		expanded: [],
		newUserDialog: false
	}),
	mounted() {
		this.initialize();
	},
	computed: {
		passwordConfirmationRule() {
			return this.password === this.confirmPassword || "Passwords must match";
		},
		isCreating() {
			return this.editedIndex < 0;
		},
		ShowAuthorizeSubscription() {
			var idx = this.subscriptions.findIndex(x => x.status == 'Pending');
			if (idx != -1)
				return true;
			return false;
		},
		ShowCreateSubscription() {
			let idx = this.subscriptions.findIndex(
				(x) => x.status == "Active" || x.status == "Pending"
			);
			if (idx != -1) {
				return false;
			}
			idx = this.subscriptions.findIndex(
				(x) => x.subscriptionProvider == 'Quickpay' && x.status == 'Cancelled' && moment(x.nextChargeDate).isAfter(moment())
			);
			if (idx != -1) {
				return false;
			}
			return true;
		},
		ShowGoogleAppleSubscriptionNotice() {
			var idx = this.subscriptions.findIndex(x => x.status == 'Pending' && x.subscriptionProvider != 'Quickpay');
			if (idx != -1)
				return true;
			return false;
		},
		sortedInvoiceList() {
			var listToDisplay = [];
			if (this.expanded.length > 0) {
				for (var i = 0; i < this.invoices.length; i++) {
					if (this.invoices[i].subscriptionId === this.expanded[0].id)
						listToDisplay.push(this.invoices[i]);
				}
			}
			return listToDisplay.sort((a, b) => {
				if (a.created === b.created) {
					return 0;
				}
				return moment(a.created).isSameOrAfter(b.created) ? -1 : 1;
			});
		},
		...mapState({
			loading: state => state.loading,
			invoices: state => state.subscriptions.myInvoices.map(i => ({
				...i,
				feeExcludingVat: i.feeTaken * 0.75
			})),
			subscriptions: state => state.subscriptions.mySubscriptions.map(s => ({
				...s,
				feeExcludingVat: s.subscriptionFee * 0.75
			}))
		})
	},
	deactivated() {
		const timeoutId = this.newUserDialogTimeout;
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
	},
	methods: {
		initialize() {
			if (!this.loading) {
				this.$store.commit('setLoading', true);
				let getSubs = this.$store.dispatch('GetMySubscriptions');
				let getInvoices = this.$store.dispatch('GetMyInvoices');
				Promise.all([getSubs, getInvoices])
					.finally(() => {
						this.$store.commit('setLoading', false)
					});
			}

			if (this.$props.newUser) {
				this.newUserDialogTimeout = setTimeout(() => {
					this.newUserDialog = true;
				}, 15000);
			}
		},
		hasOneSubscriptionActive(item) {
			if (item.status == 'Active' && item.subscriptionProvider == "Quickpay")
				return true;
			return false;
		},
		CreateSubscription() {
			if (!this.loading) {
				this.$store.commit('setLoading', true);
				this.$store.dispatch('CreateSubscription')
					.finally(() => {
						this.$store.commit('setLoading', false)
					});
			}
		},
		CancelSubscription() {
			if (!this.loading) {
				this.$store.commit('setLoading', true);
				this.$store.dispatch('CancelSubscription_User')
					.finally(() => {
						let getSubs = this.$store.dispatch('GetMySubscriptions');
						let getInvoices = this.$store.dispatch('GetMyInvoices');
						Promise.all([getSubs, getInvoices])
							.finally(() => {
								this.$store.commit('setLoading', false)
							});
					});
			}
		},
		expandRow(item) {
			if (!this.loading) {
				this.expanded = item === this.expanded[0] ? [] : [item]
			}
		},
		formatDate(date) {
			if (!date) {
				return '-';
			}
			return moment(date).format('DD/MM/YYYY');
		},
		isFullyCancelled(item) {
			if (item.status !== 'Cancelled') {
				return false;
			}

			if (!item.nextChargeDate) {
				return true;
			}

			return moment(item.nextChargeDate).isBefore(moment());
		},
		isPendingCancelled(item) {
			if (item.status !== 'Cancelled') {
				return false;
			}

			if (!item.nextChargeDate) {
				return false;
			}

			return moment(item.nextChargeDate).isAfter(moment());
		}
	}

}
</script>

<style lang="scss" scoped>
.salespitch-header {
	font-weight: 800;
	margin-top: 40px;
	margin-bottom: 0px;
}

.salespitch-body {
	margin-bottom: 20px;
}
</style>




                    