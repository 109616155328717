<template>
    <div
        v-if="loggedInUser.role == 'Admin'"
        class="Categories d-flex justify-center"
        style="padding: 10px; padding-top: 20px"
    >
        <v-toolbar v-if="dialog" flat color="white">
            <v-spacer></v-spacer>
            <v-dialog
                :persistent="loading"
                :no-click-animation="true"
                v-model="dialog"
                max-width="1500px"
            >
                <v-card tile :loading="loading" :loader-height="12">
                    <v-form ref="form" v-model="valid">
                        <v-card-title>
                            <span class="headline">Rediger Kategori</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field
                                            v-model="editedItem.title"
                                            label="Titel"
                                            hint="Indtast venligst en kategori titel"
                                            :error-messages="errorMessages"
                                            :rules="[
                                                () =>
                                                    !!editedItem.title ||
                                                    'This field is required',
                                            ]"
                                            required
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-switch
                                            v-model="editedItem.enabled"
                                            label="Aktiv"
                                        >
                                        </v-switch>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea
                                            clearable
                                            clearable-icon="cancel"
                                            label="beskrivelse"
                                            hint="Indtast venligst en beskrivelse"
                                            v-model="editedItem.description"
                                            :error-messages="errorMessages"
                                            :rules="[
                                                () =>
                                                    !!editedItem.description ||
                                                    'You must enter a valid description',
                                            ]"
                                            required
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-select
                                            v-model="editedItem.categoryTitle"
                                            :items="categoryTitles"
                                            label="Kategori Type"
                                            hint="Angiv venligst en type"
                                            :error-messages="errorMessages"
                                            :rules="[
                                                () =>
                                                    !!editedItem.categoryTitle ||
                                                    'This field is required',
                                            ]"
                                            required
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn tile color="error" text @click="close"
                                >Afbryd</v-btn
                            >
                            <v-spacer></v-spacer>
                            <v-btn tile color="primary" text @click="save"
                                >Gem</v-btn
                            >
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <List
            groupBy="categoryTitle"
            label="Søg i Kategorier"
            title="Kategorier"
            :canAddOrRemove="false"
            :contents="categories"
            :hasStats="false"
            :headers="headers"
            :showGrouping="false"
            @editClicked="editItem"
        ></List>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import moment from "moment";
import List from "../components/List.vue";

export default {
    name: "Categories",
    components: {
        List,
    },
    data: () => ({
        dialog: false,
        search: "",
        editedIndex: -1,
        errorMessages: "",
        valid: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
        headers: [
            { text: "Title", align: "start", sortable: true, value: "title" },
            { text: "Created", value: "created", sortable: true },
            { text: "Updated", value: "updated", sortable: false },
            { text: "Type", value: "categoryTitle", sortable: true },
            { text: "Actions", value: "actions", sortable: false },
        ],
        defaultItem: {
            title: "",
            description: "",
            categoryTitle: "",
            enabled: true,
            typeId: 0,
            id: -1,
        },
        editedItem: {
            title: "",
            description: "",
            categoryTitle: "",
            enabled: true,
            typeId: 0,
            id: -1,
        },
    }),
    computed: {
        ...mapState({
            categories: (state) => state.categories.categories,
            categoryTypes: (state) => state.categories.categoryTypes,
            categoryTitles: (state) => state.categories.categoryTitles,
            loading: (state) => state.loading,
            loggedInUser: (state) => state.auth.user,
            loggedIn: (state) => !!state.auth.token,
        }),
    },
    created() {
        if (!this.loggedIn)
            this.$router.push({
                path: "/",
            });
        else this.initialize();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
    },
    methods: {
        assignEditedType() {
            if (this.editedItem.categoryType != "") {
                const y = this.categoryTypes.find(
                    (x) => this.editedItem.categoryTitle == x.title
                );
                if (y != null) this.editedItem.typeId = y.typeId;
                else this.editedItem.typeId = undefined;
            }
        },
        initialize() {
            this.$store.dispatch("getAllCategories");
        },
        save() {
            this.$refs.form.validate();
            console.log("save: ");
            if (this.valid) {
                this.assignEditedType();
                //update user.
                if (this.editedIndex > -1) {
                    this.editedItem.updated = moment.utc().unix();
                    console.log(this.editedItem.updated);
                    const obj = {
                        Idx: this.editedIndex,
                        updatedCategory: this.editedItem,
                    };
                    console.log("updated");
                    console.log(obj);
                    this.$store.dispatch("updateCategory", obj).then(
                        (response) => {
                            if (response == true)
                                this.editedItem.updated = moment
                                    .unix(this.editedItem.updated)
                                    .format("YYYY-MM-DD");
                            this.close();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            }
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        editItem(item) {
            this.editedIndex = this.$store.getters.getCategoryByItem(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
    },
};
</script>
