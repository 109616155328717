<template>
	<v-container class="verify d-flex flex-column">
		<div class="my-auto text-center">
			<div class="text-h6">
				Godt klaret!
				<br />
				Du er nu klar til at logge ind i appen Din Sundhedsguide, hvis du har downloadet den.
			</div>
			<v-btn class="mt-8 v-size--large" href="com.fyzionergi.dinsundhedsguide://" color="primary" v-show="isMobile">
				Klik her for at gå til appen
			</v-btn>
			<div class="text-h6">
				<span v-show="isMobile">
					<br>
					ELLERS
				</span>
				<br>
				Download Din Sundhedsguide i App Store eller Google Play
			</div>
			<v-row dense class="mt-12">
				<v-col cols="12" sm="6">
					<a href="https://apps.apple.com/us/app/dinsundhedsguide/id1530217830" target="_blank">
						<v-img src="@/assets/AppleAppStore.png" class="mx-auto" max-width="225" />
					</a>
				</v-col>
				<v-col cols="12" sm="6" class="mt-3 mt-sm-0">
					<a href="https://play.google.com/store/apps/details?id=com.fyzionergi.dinsundhedsguide" target="_blank">
						<v-img src="@/assets/GooglePlay.png" class="mx-auto" max-width="225" />
					</a>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
export default {
	name: "Verify",
	data: () => ({
		isMobile: false,
	}),
	created() {
		this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

		if (this.isMobile) {
			location.href = "com.fyzionergi.dinsundhedsguide://";
		}
	},
};
</script>

<style lang="scss">
.verify {
	height: 100%;
	max-width: 500px;
}
</style>