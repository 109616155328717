import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './Modules/Auth';
//Modules
import Categories from './Modules/Categories';
import Consultations from './Modules/Consultations';
import Courses from './Modules/Courses';
import Exercises from './Modules/Exercises';
import Groupings from './Modules/Groupings';
import Me from './Modules/Me';
import Subscriptions from './Modules/Subscriptions';
import Users from './Modules/Users';

Vue.use(Vuex);

export interface RootStore {
    alertText: string;
    alertBool: boolean;
    alertColor: string;
    loading: boolean;
}

export default new Vuex.Store<RootStore>({
    state: {
        alertText: '',
        alertBool: false,
        alertColor: 'info',
        loading: false
    },
    mutations: {
        setAlertErrorSnack(state, text) {
            console.log("alert error snack called:", text);
            state.alertText = text || "Action failed, please try again later. If the error persists please contact your system administrator.";
            state.alertColor = 'error';
            state.alertBool = true;
            state.loading = false;
        },
        setAlertSuccessSnack(state, text) {
            state.alertText = text || "Action suceeded.";
            state.alertColor = 'success';
            state.alertBool = true;
            state.loading = false;
        },
        setLoading(state, loading) {
            state.loading = loading;
        }
    },
    getters: {
    },
    actions: {
    },
    modules: {
        auth: Auth,
        categories: Categories,
        consultations: Consultations,
        courses: Courses,
        exercises: Exercises,
        groupings: Groupings,
        me: Me,
        subscriptions: Subscriptions,
        users: Users,
    }
})
