<template>
    <div class="home" v-if="isLoggedIn === true" style="padding:10px;">
        <div v-if="needsVerify">
            <prompt-verify-email />
        </div>
        <v-card flat tile :loading="loading">
            <v-card-title>
                <v-tabs fixed-tabs
                        show-arrows
                        background-color="primary"
                        dark
                        v-model="activeTab">
                    <v-tab key="account">
                        Bruger oplysninger
                    </v-tab>
                    <v-tab key="security">
                        Skift kodeord
                    </v-tab>
                    <v-tab key="subscription" v-if="loggedInUser.role != 'Admin'">
                        Abonnement
                    </v-tab>
                    <v-spacer />
                </v-tabs>
            </v-card-title>
            <v-card-text>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key="account">
                        <v-col>
                            <v-row>
                                <v-col cols="12">
                                    <HomeUserForm :_baseUser="me" :nextScreeningDate="nextScreening"></HomeUserForm>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <div style="margin-left: 20px; margin-bottom: -20px;">Mine konsultationer</div>
                                    <ListView title=""
                                              label="" 
                                              :clickableSlots="clickableSlots"
                                              :contents="consultations"
                                              :headers="headers_Consultations"
                                              :isDisabled="true"
                                              :vimeoLinkSlots="linkSlots_Consultations"
                                              style="margin-left: 0;"/>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" style="margin-left: 20px;">
                                    <div>Kurser</div>
                                    <v-chip v-for="(course,index) in courses" :key="index" class="chip-listings">
                                        {{ course.name }}
                                    </v-chip>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" style="margin-left: 20px;">
                                    <div>Grupper</div>
                                    <v-chip v-for="(group,index) in groupings" :key="index" class="chip-listings">
                                        {{ group.name }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-tab-item>
                    <v-tab-item key="security">
                        <v-row>
                            <v-col cols="12">
                                <HomeSecurity :hint="me.hint"></HomeSecurity>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="subscription" v-if="loggedInUser.role != 'Admin'">
                        <v-row>
                            <v-col cols="12">
                                <HomeSubscriptionManager :user="me" :newUser="newUser"></HomeSubscriptionManager>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import HomeUserForm from '../components/Home/HomeUserForm.vue'
    import HomeSubscriptionManager from '../components/Home/HomeSubscriptionManager.vue'
    import HomeSecurity from '../components/Home/HomeSecurity.vue'
    import ListView from "@/components/ListView.vue"
    import PromptVerifyEmail from './PromptVerifyEmail.vue'

    export default {
        name: 'Home',
        components: {
            HomeUserForm,
            HomeSubscriptionManager,
            HomeSecurity,
            ListView,
            PromptVerifyEmail,
        },
        data: () => ({
            activeTab: -1,
            consultations: [],
            courses: [],
            groupings: [],
            headers_Consultations: [
                { text: "ID", align: "start", sortable: true, value: "id" },
                { text: "Titel", sortable: true, value: "name" },
                { text: "Oprettet", sortable: true, value: "created" },
                { text: "Video", sortable: true, value: "videoLink" },
                { text: "", sortable: false, value: "", width: "40%" }
            ],
            newUser: false
        }),
        computed: {
            ...mapState({
                isLoggedIn: (state) => !!state.auth.token,
                isSubscribed: (state) => state.me.isSubscribed,
                loading: (state) => state.loading,
                loggedInUser: (state) => state.auth.user,
                me: (state) => state.me.me,
                nextScreening: (state) => state.me.nextScreeningDate
            }),
            clickableSlots() {
                return this.getClickableSlots();
            },
            linkSlots_Consultations() {
                return this.getLinkSlots_Consultations();
            },
            needsVerify() {
                return this.isLoggedIn && !this.loggedInUser.verifiedAt;
            },
        },
        mounted() {
            if (this.$route.query.newUser !== undefined) {
                this.activeTab = 2;
                this.newUser = true;
            } else {
                this.activeTab = 0;
            }
            this.initialize();
        },
        methods: {
            getClickableSlots() {
                const func = this.navItemClicked;
                var clickableSlots = [
                    { field: "videoLink", action(item) { func(item); }, showStaticLabel: true, label: "LINK" },
                ];
                return clickableSlots;
            },
            getLinkSlots_Consultations() {
                const func = this.linkSlotItemClicked;
                const linkSlots = [
                    { field: "videoLink", disableField: "vimeoId", action(item) { func(item); } }
                ];
                return linkSlots;
            },
            initialize() {
                if (this.loading) {
                    return;
                }
                this.$store.commit('setLoading', true);
                this.$store.dispatch('GetMyUserInformation')
                    .finally(() => {
                        this.$store.commit('setLoading', false);
                    });
            },
            async linkSlotItemClicked(item) {
                console.log("TT");
            },
            navItemClicked(item) {
                if (!item) {
                    return;
                }

                const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g; //Found here: https://stackoverflow.com/questions/30970068/js-regex-url-validation
                const isUrl = regex.test(item.videoLink);
                if (!isUrl) {
                    return;
                }
                window.open(item.videoLink);
            },
        },
        watch: {
            async me(newVal, oldVal) {
                if (newVal.id < 1 || newVal === null || newVal === undefined || this.consultations.length > 0) {
                    return;
                }

                const userId = this.me.id;

                const consultations = await this.$store.dispatch('getUserConsultations', userId);
                this.$set(this, 'consultations', consultations);

                const courses = await this.$store.dispatch('getUserCourses', userId);
                this.$set(this, 'courses', courses);

                const groupings = await this.$store.dispatch('getUserGroupings', userId);
                this.$set(this, 'groupings', groupings);
            }
        }

    }
</script>

<style scoped>
    .chip-listings {
        background-color: #738ea6 !important;
        color: white;
        margin: 5px;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 35px;
        padding-right: 35px;
    }

    .v-tab--active {
        background-color: #4B6F8F;
        color: white;
    }
</style>
