<template>
    <v-app>
        <v-navigation-drawer
            v-if="isLoggedIn === true"
            v-model="drawer"
            app
            color="#738ea6"
        >
            <v-list dense
                    class="list-style">
                <v-list-item link to="Home">
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-pencil
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Min profil
                        </v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="loggedInUser.role == 'Admin'"
                             link
                             to="Users">
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-account
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Brugere
                        </v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="loggedInUser.role == 'Admin'"
                    link
                    to="Subscriptions"
                >
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-account-cash
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Abonnementer
                        </v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="loggedInUser.role == 'Admin'"
                    link
                    to="Categories"
                >
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-apps
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Kategorier
                        </v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="loggedInUser.role == 'Admin'"
                    link
                    to="Exercises"
                >
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-animation-play
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Videoer
                        </v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="loggedInUser.role == 'Admin'"
                    link
                    to="OnlineCourses"
                >
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-clipboard-text-outline
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Online Kurser
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="loggedInUser.role == 'Admin'"
                    link
                    to="Groupings"
                >
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-account-group
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Grupper
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="terms-of-service">
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-clipboard-text-outline
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Terms of Service
                        </v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link to="Fortrolighedspolitik">
                    <v-list-item-action>
                        <v-icon style="color: white;">
                            mdi-file-document-outline
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title style="color: white">
                            Fortrolighedspolitik
                        </v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-card-actions style="display: flex; flex-direction: column;">
                    <v-btn
                        class="text-center mt-4"
                        text
                        v-if="ShowAuthorizeSubscription == true"
                        @click="CreateSubscription"
                        style="width: 100%; color: white"
                        >Bekræft Abonnement</v-btn
                    >
                    <v-btn
                        class="text-center mt-4"
                        text
                        v-if="ShowCreateSubscription == true"
                        @click="CreateSubscription"
                        style="width: 100%; color: white"
                        >Opret Abonnement</v-btn
                    >
                    <v-btn
                        class="text-center mt-4"
                        text
                        v-if="true == true"
                        @click="TakeNewScreening"
                        style="width: 100%; color: white"
                        >Ny Krops-screening</v-btn
                    >
                </v-card-actions>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app color="#738ea6">
            <template v-if="isLoggedIn === true">
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    style="color: white"
                />

                <v-spacer />
                <router-link
                    to="/Home"
                    style="height: 90%; width: 33%"
                >
                    <v-img
                        class="justify-center"
                        contain
                        style="height: 100%"
                        src="@/assets/logo_invert.png"
                    ></v-img>
                </router-link>

                <v-spacer />
                <v-btn
                    class="text-right"
                    text
                    @click="logout"
                    style="color: white"
                >
                    Log ud
                </v-btn>
            </template>
            <template v-else>
                <v-btn
                    class="text-right"
                    v-if="isFromWebview === false"
                    text
                    @click="loginOpen"
                    style="color: white; margin-left: auto;"
                >
                    Log ind
                </v-btn>
            </template>
        </v-app-bar>

        <template>
            <div class="text-center ma-2">
                <v-snackbar
                    v-model="alertBool"
                    :top="true"
                    :vertical="true"
                    :timeout="6000"
                    :color="alertColor"
                    @input="closeAlert"
                >
                    {{ alertText }}
                    <v-spacer />
                    <v-btn color="white" text @click="closeAlert" style="float: right;">
                        Close
                    </v-btn>
                </v-snackbar>
            </div>
        </template>

        <v-main style="padding-top: 48px">
            <!-- if logged into an admin user, display the admin site. -->
            <router-view v-if="!isChangingPassword" />

            <!-- if not logged in, and has a password token, display reset password module. -->
            <div v-if="isChangingPassword && isLoggedIn === false">
                <ResetPassword></ResetPassword>
            </div>

            <v-dialog v-model="loginDialogue" width="60vw" min-width="300px">
                <Login login_path="/home"
                       style="padding: 10px; width: 100%; height: 100%"
                       @LoggedIn="loginClose"></Login>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
//import Landing from "./views/Landing";
import Login from "./components/Login";
import ResetPassword from "./views/ResetPassword";
import moment from "moment";

export default {
    name: "App",
    data: () => ({
        loginDialogue: false,
        drawer: null,
        pwdChangeToken: undefined,
    }),
    components: {
        Login,
        //Landing,
        ResetPassword,
    },
    created() {
        this.$store.dispatch("configureIsLoggedIn");
		this.drawer = this.$route.name != "Verify";
    },
    computed: {
        isChangingPassword() {
            return this.$route.query.pwdChangeToken != undefined;
        },
        isFromWebview() {
            if (this.$route.query.webview != undefined) return true;
            else return false;
        },
        ShowAuthorizeSubscription() {
            var idx = this.subscriptions.findIndex(
                (x) => x.status == "Pending"
            );
            if (idx != -1) return true;
            return false;
        },
        ShowCreateSubscription() {
            var idx = this.subscriptions.findIndex(
                (x) => x.status == "Active" || x.status == "Pending"
            );
            if (idx != -1) {
                return false;
            }
            idx = this.subscriptions.findIndex(
                (x) =>
                    x.subscriptionProvider == "Quickpay" &&
                    x.status == "Cancelled" &&
                    moment(x.nextChargeDate).isAfter(moment())
            );
            if (idx != -1) {
                return false;
            }
            return true;
        },
        ...mapGetters(['isLoggedIn']),
        ...mapState({
            alertText: (state) => state.alertText,
            alertBool: (state) => state.alertBool,
            alertColor: (state) => state.alertColor,
            loggedInUser: (state) => state.auth.user,
            subscriptions: (state) => state.subscriptions.mySubscriptions,
        }),
    },
    mounted() {
        this.initialize();
    },
    methods: {
        async initialize() {
            if (!this.loading && this.isLoggedIn) {
                this.$store.commit("setLoading", true);
                await this.$store.dispatch("GetMySubscriptions");
                this.$store.commit("setLoading", false);
            }
        },
        closeAlert() {
            this.$store.state.alertBool = false;
        },
        logout() {
            this.$store.dispatch("logout");
            this.$router.push('/');
        },
        loginOpen() {
            this.loginDialogue = true;
        },
        loginClose() {
            console.log("login should close");
            this.loginDialogue = false;
        },
        async CreateSubscription() {
            if (!this.loading) {
                this.$store.commit("setLoading", true);
                await this.$store.dispatch("CreateSubscription");
                this.$store.commit("setLoading", false);
            }
        },
        async TakeNewScreening() {
            if (!this.loading) {
                this.$store.commit("setLoading", true);
                await this.$store.dispatch("getNewScreening");
                this.$store.commit("setLoading", false);
            }
        },
    },
};
</script>

<style lang="scss">
    .v-snack__content {
        width: 100%;
    }

    .list-style .v-list-item--active {
        background-color: #4B6F8F;
        color: white;
    }
    .list-item-active {
        color: purple;
    }

    .list-item-active::before {
        opacity: 1;
    }
</style>