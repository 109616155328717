<template>
    <v-card tile style="margin:10px;">
        <v-container>
            <v-row>
                <v-col cols="7">
                    <v-text-field v-model="search"
                                  label="Søg"></v-text-field>
                </v-col>
                <v-col>
                    <v-layout align-end justify-end>
                        <v-btn @click="onRevertChanges">Fjern Alle Ændringer</v-btn>
                    </v-layout>
                </v-col>
            </v-row>
            <v-row v-if="categories.length > 0">
                <template v-for="item in categories">
                    <v-col :key="item.categoryId" cols="12" sm="4" md="4">
                        <v-list-item @click="onSelectCategory(item)">
                            <v-list-item-title v-text="item.displayLabel"></v-list-item-title>
                        </v-list-item>
                    </v-col>
                </template>
            </v-row>
            <v-row v-if="selectedItem != null">
                <v-col>
                    <v-divider></v-divider>
                    <v-row style="padding-left:20px; padding-top:20px;">
                        <v-col cols="10" style="padding-bottom:0px;">
                            <h3>{{selectedItem.displayLabel}}</h3>
                        </v-col>
                    </v-row>
                    <v-row style="padding:20px; padding-bottom:10px; padding-top:0px;">
                        <v-col>
                            <h5>Værdi: {{selectedItem.computedValue}}</h5>
                            <h5>Likes: {{selectedItem.modifier}}</h5>
                            <h5>Vægt: {{(this.weightMod - 10) / 10}}</h5>
                            <v-slider v-model="weightMod" label="Vægt" value="weightMod" min="0" max="30" @change="onChange($event)"></v-slider>
                        </v-col>
                    </v-row>
                    <v-row style="padding-left:20px; padding-right:20px">
                        <v-layout align-start justify-start>
                            <v-btn @click="addChange">Lås Ændring</v-btn>
                        </v-layout>
                        <v-layout align-end justify-end>
                            <v-btn @click="onRegretChange">Fortryd</v-btn>
                        </v-layout>

                    </v-row>
                    <v-divider style="margin-top:10px; margin-bottom: 10px;"></v-divider>
                </v-col>
            </v-row>
            <v-row v-if="currentChanges.length > 0">
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                    <List title="Ændringer" label="" :contents="currentChanges" :headers="changeHeader" :canAddOrRemove="false" :showGrouping="false" :hasStats="false"></List>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn tile color="secondary" @click="onRequestPreview">Forhåndsvis Ændringer</v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn tile color="primary" @click="onSaveChanges">Gem Ændringer</v-btn>
                </v-col>
            </v-row>


            <!--<h3>Normaliser/Skævvrid</h3>
    <h5>Vægt: {{Math.min(Math.max(normWeight+(normWeightMod/10), -1), 2)}}</h5>
    <v-slider v-model="normWeightMod" label="Vægt" value="normWeightMod" min="-10" max="10" @change="onChange($event)"></v-slider>-->
        </v-container>
    </v-card>
</template>

<script>
    import List from './List.vue';
    import _ from 'lodash';
    export default {
        name: 'StatControl',
        props: ['screeningValues',],
        components: {
            List
        },
        data: () => ({
            weightMod: 20,
            normWeight: 1.0,
            normWeightMod: 0,
            search: '',
            selectedItem: null,
            items: [],
            changedItems: [],
            changeHeader: [
                { text: 'Kategori', align: 'start', value: 'displayLabel' },
                { text: 'Original Vægt', value: 'origWeight' },
                { text: 'Ny Vægt', value: 'newWeight' },
                { text: 'Original Værdi', value: 'origComputedValue' },
            ],
            currentChanges: [
                
            ],
        }),
        mounted() {
            this.items = _.cloneDeep(this.screeningValues); //JSON.parse(JSON.stringify(this.screeningValues));
            this.changedItems = _.cloneDeep(this.screeningValues);//JSON.parse(JSON.stringify(this.screeningValues));
        },
        computed: {
            categories() {
                const search = this.search.toLowerCase()

                if (!search) return [];

                return this.items.filter(item => {
                    const text = item.displayLabel.toLowerCase();
                    return text.indexOf(search) > -1;
                })
            },
        },
        methods: {
            addChange() {
                console.log(this.selectedItem.categoryId);
                if (this.selectedItem != null) {
                    var idx = this.changedItems.findIndex(x => x.categoryId === this.selectedItem.categoryId);
                    if (idx != -1) {
                        var item = this.items.slice(idx, idx+1)[0];
                        var origWeight = item.weight;
                        var origComputedValue = item.computedValue;
                        this.selectedItem.weight = (this.weightMod - 10) / 10;

                        var currChangeIdx = this.currentChanges.findIndex(x => x.codeLabel == this.selectedItem.categoryId);
                        console.log("weight: " + this.selectedItem.weight);
                        var displayChange = {
                            displayLabel: this.selectedItem.displayLabel,
                            codeLabel: this.selectedItem.categoryId,
                            origWeight: origWeight,
                            newWeight: this.selectedItem.weight,
                            origComputedValue: origComputedValue,
                        }

                        if (currChangeIdx == -1) {
                            this.currentChanges.push(displayChange);
                        } else {
                            this.currentChanges.splice(currChangeIdx, currChangeIdx + 1);
                            this.currentChanges.push(displayChange);
                        }
                    }
                    //this.weightMod = 1;
                }
            },
            onChange() {
                if (this.selectedItem != null) {
                    this.weight = Math.min(Math.max(this.selectedItem.weight + (this.weightMod / 10), -1), 2)
                    this.$emit('weightChanged', { weight: this.selectedItem.weight, title: this.selectedItem.title });
                }
            },
            onNormChange() {
                //some sort of alg. that normalizes/skew the collective values. Probably just have the server perform this, and adjust the weight param on site.
            },
            onSelectCategory(item) {
                var idx = this.changedItems.findIndex(x => x.categoryId === item.categoryId);
                this.selectedItem = this.changedItems.slice(idx, idx + 1)[0];
                this.weightMod = (1+this.selectedItem.weight) * 10;
            },
            onRequestPreview() {
                this.$emit('preview', { changedItems: this.changedItems });
            },
            onSaveChanges() {
                this.$emit('save', { changedItems: this.changedItems });
            },
            onRevertChanges(){
                this.selectedItem = null;
                this.items = _.cloneDeep(this.screeningValues); //JSON.parse(JSON.stringify(this.screeningValues));
                this.changedItems = _.cloneDeep(this.screeningValues);//JSON.parse(JSON.stringify(this.screeningValues));
                this.currentChanges = [];
                this.weightMod = 20;
            },
            onRegretChange() {
                var idx = this.currentChanges.findIndex(x => x.codeLabel == this.selectedItem.categoryId);
                if (idx != -1) {
                    this.currentChanges.splice(idx, 1);
                }
                idx = this.changedItems.findIndex(x => x.codeLabel == this.selectedItem.categoryId);
                if (idx != -1) {
                    this.changedItems[idx] = this.items.slice(idx)[0];
                }
                this.selectedItem = null;
                this.weightMod = 1;
            }
        }

    }
</script>

<style>

</style>