<template>
    <div v-if="loggedInUser.role == 'Admin'" class="Users d-flex justify-center" style="padding:10px; padding-top:20px">
        <v-toolbar v-if="dialog" flat color="white">
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" scrollable max-width="1500px">
                <UserForm 
                    :formTitle="formTitle" 
                    :_editedBase="editedItem" 
                    :editedIndex="editedIndex" 
                    dispatchType="addUser" 
                    @close="dialog = false"
                    @saved="initialize()"
                />
            </v-dialog>
        </v-toolbar>
        <v-dialog v-model="passwordDialog" max-width="1500px">
            <ChangePasswordForm :_editedBase="editedItem" @close="closeChangePassword"></ChangePasswordForm>
        </v-dialog>

        <v-toolbar v-if="statsDialog" flat color="white">
            <v-spacer></v-spacer>
            <v-dialog v-model="statsDialog" max-width="80vw">

                <v-card tile :loading="loading">
                    <v-card-title>
                        <span class="headline">{{this.editedItem.Name}}</span>
                        <v-spacer></v-spacer>
                        <v-icon large @click="goToFullScreenStats">mdi-fullscreen</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <UserStats :userID="editedItem.id"></UserStats>
                    </v-card-text>
                    <!-- if has errors, then disable save button. -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile color="primary" text @click="closeStats">Luk</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>

        <v-card tile :loading="loading" width="100vw" min-width="300px">
            <v-card-title>
                <v-btn tile color="primary" class="mb-2" @click="addClicked" :disabled="loading" style="margin-right:10px"><h2>+</h2></v-btn>
                Brugere
                <v-spacer></v-spacer>
                <v-btn color="secondary" text class="mx-4 mt-3" @click="importCsv" :disabled="loading" outlined>Masse-inviter brugere</v-btn>
                <v-btn color="secondary" text class="mx-4 mt-3" @click="exportCsv" :disabled="loading" outlined>Exportér CSV</v-btn>
                <v-text-field v-model="search"
                              append-icon="mdi-magnify"
                              label="Søg i Brugere"
                              single-line
                              hide-details>
                </v-text-field>
            </v-card-title>
            <v-data-table :headers="headers"
                          :items="users"
                          :footer-props="{'items-per-page-options': [5, 15, 25, 35, 99]}"
                          :items-per-page="35"
                          class="elevation-1"
                          :search="search"
                          sort-by="created"
                          :sort-desc="true">
                <template v-slot:item.created="{ item }">
                    <span>{{ dateFormat(item.created) }}</span>
                    <!--<span>{{ new Date(item.created).toLocaleDateString() }}</span>-->
                </template>

                <template v-slot:item.subscription="{ item }">

                    <span class="text-start" v-if="item.role == 'Admin'" style="color:#AB00BA;"> - </span>
                    <template v-else>
                        <span class="text-start" v-if="item.subscription == null" style="color:#0069BA;"> Intet abonnement </span>
                        <template v-else>
                            <span class="text-start" v-if="item.subscription.status == 'Pending'" style="color:#D99F00;"> Afventer </span>
                            <template v-else-if="item.subscription.status == 'Active'">
                                <span class="text-start" v-if="isActiveTrial(item.subscription)" style="color:#a61bc4; font-weight: bold;"> Aktiv trial </span>
                                <span class="text-start" v-else style="color:#5b9617; font-weight: bold;"> Aktivt ({{formatDate(item.subscription.nextChargeDate)}})</span>
                            </template>
                            <span class="text-start" v-else-if="item.subscription.status == 'Cancelled'" style="color:#FF4336;"> Annulleret </span>

                            <span class="text-start" v-if="item.subscription != null" style="font-size: small;"> - {{item.subscription.subscriptionProvider}} </span>
                        </template>
                    </template>
                </template>

                <!-- <template v-slot:item.groups="{ item }">
                    <span class="text-start">{{ getGroupText(item.groups) }}</span>
                </template> -->

                <template v-slot:item.trialEnd="{ item }">
                    <span class="text-start" v-if="item.role == 'Admin'" style="color:#AB00BA"> - </span>
                    <span class="text-start" v-else-if="isTrialExpired(item.trialEnd)" style="color:#FF4336;"> Udløbet </span>
                    <span class="text-start" v-else-if="trialExpiresSoon(item.trialEnd)" style="color:#D99F00;"> {{formatDate(item.trialEnd)}} </span>
                    <span class="text-start" v-else style="color:#5b9617;"> {{formatDate(item.trialEnd)}} </span>
                </template>

                <template v-slot:item.screeningToken="{ item }">
                    <v-btn color="secondary" v-if="item.screeningToken !== null" icon v-bind:href="`https://DinSundhedsGuide.Typeform.com/to/hIjmcLsY#token=${item.screeningToken}`" target="_blank">
                        Link
                    </v-btn>
                    <span class="text-start" v-else-if="item.hasCompletedScreening" style="color:#5b9617;"> Screening komplet </span>
                    <span class="text-start" v-else style="color:#DD2200;"> Mangler screening </span>
                </template>

                <template v-slot:item.verifiedAt="{ item }">
                    <v-btn 
                        v-if="item.verifiedAt"
                        color="colorSuccess600"
                        icon 
                        target="_blank"
                        @click.prevent="toggleVerified(item)">
                        Verificeret
                    </v-btn>
                    <v-btn 
                        v-else
                        color="colorDanger700" 
                        icon 
                        target="_blank"
                        @click.prevent="toggleVerified(item)">
                        Afventer
                    </v-btn>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs"
                                    v-on="on"
                                    medium
                                    color="primary"
                                    class="mr-2"
                                    @click="editItem(item)"
                                    :disabled="loading">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Redigér bruger</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs"
                                    v-on="on"
                                    medium
                                    color="primary"
                                    class="mr-2"
                                    @click="deleteItem(item)"
                                    :disabled="loading">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Slet bruger</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs"
                                    v-on="on"
                                    medium
                                    color="primary"
                                    class="mr-2"
                                    @click="displayStats(item)"
                                    :disabled="loading">
                                mdi-chart-bar-stacked
                            </v-icon>
                        </template>
                        <span>Screening graf</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs"
                                    v-on="on"
                                    medium
                                    color="primary"
                                    class="mr-2"
                                    @click="displayChangePassword(item)"
                                    :disabled="loading">
                                mdi-lock-outline
                            </v-icon>
                        </template>
                        <span>Skift kodeord</span>
                    </v-tooltip>
                </template>

                <template #footer>
                    <v-toolbar flat color="white">
                        <v-btn tile color="primary" class="mb-2" @click="addClicked" :disabled="loading"><h2>+</h2></v-btn>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card>

    </div>
</template>

<script>
import { mapState } from "vuex";
import UserStats from "../components/UserStats";
import UserForm from "../components/UserForm";
import moment from "moment";
import ChangePasswordForm from "../components/ChangePasswordForm.vue";

function isActiveTrial(subscription) {
    const nextPayment = moment(subscription.nextChargeDate);
    return (nextPayment.diff(moment(), 'days')) > 31;
}

export default {
    name: "Users",
    components: {
        UserStats,
        UserForm,
        ChangePasswordForm,
    },
    data: () => ({
        dialog: false,
        statsDialog: false,
        passwordDialog: false,
        search: "",
        editedIndex: -1,
        headers: [
            {
                text: "Dato for oprettelse",
                align: "start",
                sortable: true,
                value: "created",
            },
            { text: "Navn", align: "start", sortable: true, value: "name" },
            { text: "Email", sortable: true, value: "email" },
            { text: "Role", value: "role", sortable: true },
            {
                text: "Trial",
                value: "trialEnd",
                sortable: true,
                filterable: false,
                sort: (a, b) => {
                    const aTime = moment(a);
                    const bTime = moment(b);
                    if (!aTime.isValid()) {
                        return 1;
                    }
                    if (!bTime.isValid()) {
                        return -1;
                    }

                    if (aTime.isBefore(bTime)) {
                        return 1;
                    }
                    if (aTime.isAfter(bTime)) {
                        return -1;
                    }
                    return 0;
                },
            },
            {
                text: "Abonnement",
                value: "subscription",
                sortable: true,
                filterable: false,
                sort: (a, b) => {
                    if (a === b && a === null) {
                        return 0;
                    }
                    if (a === null) {
                        return 1;
                    }
                    if (b === null) {
                        return -1;
                    }

                    switch (a.status) {
                        case "Active":
                            if (b.status === "Active" && b.nextChargeDate) {
                                if (!a.nextChargeDate) {
                                    return 1;
                                }
                                const aCharge = moment(a.nextChargeDate);
                                const bCharge = moment(b.nextChargeDate);
                                if (aCharge.isAfter(bCharge)) {
                                    return -1;
                                }
                                if (bCharge.isAfter(aCharge)) {
                                    return 1;
                                }
                                return 0;
                            }
                            return -1;
                        case "Pending":
                            if (a.status === b.status) {
                                return 0;
                            }
                            switch (b.status) {
                                case "Active":
                                    return 1;
                                case "Cancelled":
                                    return -1;
                            }
                            break;
                        case "Cancelled":
                            if (a.status === b.status) {
                                return 0;
                            }
                            return 1;
                    }
                    return 0;
                },
            },
            {
                text: "Screening",
                value: "screeningToken",
                sortable: true,
                filterable: false,
                sort: (a, b) => {
                    if (a && !b) {
                        return -1;
                    }
                    if (b && !a) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                text: "Verificeret",
                value: "verifiedAt",
                sortable: true,
                filterable: false,
                sort: (a, b) => {
                    if (a && !b) {
                        return -1;
                    }
                    if (b && !a) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                filterable: false,
            },
        ],
        roles: ["Freemium", "Premium", "Admin"],
        defaultItem: {
            name: "",
            email: "",
            role: "Freemium",
            created: "",
            updated: "",
            birthDate: "",
            gender: "Other",
            height: undefined,
            weight: undefined,
            onTrial: false,
            trialEnd: null,
            trialEndDate: moment().format(),
            id: -1,
        },
        editedItem: {
            name: "",
            email: "",
            role: "Freemium",
            created: "",
            updated: "",
            birthDate: "",
            gender: "Other",
            height: undefined,
            weight: undefined,
            onTrial: false,
            trialEnd: null,
            trialEndDate: moment().format(),
            id: -1,
        },
    }),
    computed: {
        ...mapState({
            users: (state) => state.users.users,
            loggedInUser: (state) => state.auth.user,
            loggedIn: (state) => !!state.auth.token,
            loading: (state) => state.loading,
        }),
        formTitle() {
            return this.editedIndex === -1 ? "Tilføj bruger" : "Rediger Bruger";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        statsDialog(val) {
            val || this.closeStats();
        },
        passwordDialog(val) {
            val || this.closeChangePassword();
        },
        users(val) {
            console.log(val.map(u => ({
                id: u.id,
                trialEnd: u.trialEnd,
            })));
        }
    },
    created() {
        if (!this.loggedIn)
            this.$router.push({
                path: "/",
            });
        else this.initialize();
    },
    methods: {
        addClicked() {
            this.dialog = true;
            this.editedIndex = -1;
            this.editedItem = Object.assign({}, this.defaultItem);
        },
        closeChangePassword() {
            this.passwordDialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        closeStats() {
            this.statsDialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        dateFormat(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        displayChangePassword(item) {
            this.editedIndex = this.$store.getters.getUserByItem(item);
            this.editedItem = Object.assign({}, item);
            this.passwordDialog = true;
        },
        displayStats(item) {
            this.editedIndex = this.$store.getters.getUserByItem(item);
            this.editedItem = Object.assign({}, item);
            this.statsDialog = true;
        },
        goToFullScreenStats() {
            console.log(this.editedItem);
        },
        async editItem(item) {
            this.editedIndex = this.$store.getters.getUserByItem(item);
            this.editedItem = Object.assign({}, item);

            const _consultations = await this.$store.dispatch("getUserConsultations", item.id);
            this.$set(this.editedItem, 'consultations', _consultations);

            if (Number.isInteger(this.editedItem.birthDate))
                //assumes that the trialEnd is a unix timestamp.
                this.editedItem._birthDate = moment
                    .unix(this.editedItem.birthDate)
                    .format("YYYY-MM-DD");
            //assumes that the trialend is a datestring
            else
                this.editedItem._birthDate = new Date(this.editedItem.birthDate)
                    .toISOString()
                    .substr(0, 10);

            if (this.editedItem.onTrial) {
                if (this.editedItem.trialEnd != null) {
                    if (Number.isInteger(this.editedItem.trialEnd))
                        //assumes that the trialEnd is a unix timestamp.
                        this.editedItem.trialEndDate = moment
                            .unix(this.editedItem.trialEnd)
                            .format("YYYY-MM-DD");
                    else {
                        //assumes that the trialend is a datestring
                        this.editedItem.trialEndDate = new Date(
                            this.editedItem.trialEnd
                        )
                            .toISOString()
                            .substr(0, 10);
                    }
                }
            } else {
                this.editedItem.trialEndDate = new Date()
                    .toISOString()
                    .substr(0, 10);
            }

            this.dialog = true;
        },
        deleteItem(item) {
            if (!this.loading) this.$store.commit("setLoading", true);
            const index = this.$store.getters.getUserByItem(item);
            var obj = { id: item.id, idx: index };
            confirm("Er du sikker på at du ønsker at slette denne bruger?") &&
                this.$store
                    .dispatch("removeUser", obj)
                    .then(() =>
                        this.$store.commit(
                            "setAlertSuccessSnack",
                            "Bruger slettet korrekt."
                        )
                    )
                    .catch((err) =>
                        this.$store.commit("setAlertErrorSnack", err)
                    );
        },
        async toggleVerified(item) {
            if (item.verifiedAt) {
                const yes = confirm(
                    "Nulstil denne brugers e-mail verificering?\n\nVær opmærksom på at dette vil sende en ny verificerings-mail ud til brugeren."
                );
                if (!yes) {
                    return;
                }
            } else {
                const yes = confirm(
                    "Ønsker du at manuelt verificere denne brugers e-mail?"
                );
                if (!yes) {
                    return;
                }
            }
            if (!this.loading) {
                this.$store.commit("setLoading", true);
            }
            try {
                await this.$store.dispatch("toggleVerified", { userId: item.id, verified: !item.verifiedAt });
                this.$store.commit(
                    "setAlertSuccessSnack",
                    "Bruger blev opdateret."
                );
            } catch (err) {
                this.$store.commit("setAlertErrorSnack", err);
            } finally {
                this.$store.commit("setLoading", false);
            }
        },
        close() {
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        initialize() {
            if (!this.loading) this.$store.commit("setLoading", true);
            this.$store
                .dispatch("getAllUsers")
                .then(() => this.$store.commit("setLoading", false));
        },
        async exportCsv() {
            this.$store.commit("setLoading", true);
            try {
                const response = await this.$store.dispatch("generateExport");
                if (response.status === 200) {
                    // Magic way to receive a file response and save it locally, without downloading it via a specific URL.
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "export.txt");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            } finally {
                this.$store.commit("setLoading", false);
            }
        },
        async importCsv() {
            const file = await selectFile(".csv", false);
            this.$store.commit("setLoading", true);
            try {
                const response = await this.$store.dispatch("importInvite", file);
                if (response.status === 200) {
                    alert(`Importeret ${response.data.success.length} brugere.`);
                    if (response.data.errors && response.data.errors.length > 0) {
                        const errMsg = response.data.errors.map(e => `Email ${e.email} fejlede: ${e.message}`).join("\n");
                        alert(`Advarsel! ${response.data.errors.length} brugere fejlede import.`);
                        alert('Følgende brugere fejlede:\n\n' + errMsg);
                    }
                }
                await this.$store.dispatch("getAllUsers");
            } catch (error) {
                alert('Den importerede fil kunne ikke behandles. Tjek venligst at første række består af følgende kolonner: "Navn", "Email", "Gruppe", "Startdato" og "Slutdato".\nDato-formatet er "DD-MM-YYYY", f.eks. 21-06-2022.');
            } finally {
                this.$store.commit("setLoading", false);
            }
        },
        isTrialExpired(trialEnd) {
            if (!trialEnd) {
                return true;
            }
            const ends = moment(trialEnd);
            return moment().isSameOrAfter(ends);
        },
        trialExpiresSoon(trialEnd) {
            const ends = moment(trialEnd);
            return moment().add(3, "days").isSameOrAfter(ends);
        },
        formatDate(trialEnd) {
            const ends = moment(trialEnd);
            return ends.format("DD/MM/YYYY");
        },
        isActiveTrial: isActiveTrial,
        getGroupText(groups) {
            if (!groups || !groups.length) {
                return 'Ingen grupper';
            }

            switch (groups.length) {
                case 1:
                    return `${groups.length} gruppe`;
                default:
                    return `${groups.length} grupper`;
            }
        }
    },
};

/**
 * Select file(s).
 * @param {String} contentType The content type of files you wish to select. For instance, use "image/*" to select all types of images.
 * @param {Boolean} multiple Indicates if the user can select multiple files.
 * @returns {Promise<File>} A promise of a file or array of files in case the multiple parameter is true.
 */
function selectFile(contentType, multiple) {
    return new Promise((resolve) => {
        let input = document.createElement("input");
        input.type = "file";
        input.multiple = multiple;
        input.accept = contentType;

        input.onchange = () => {
            let files = Array.from(input.files);
            resolve(files[0]);
        };
        input.click();
    });
}
</script>
