<template>
    <v-form ref="form" v-model="valid">
        <v-card>
            <v-card-title style="background: #738ea6; color: white;">
                <span class="headline">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-icon style="cursor: pointer; color: white; width: 50px; height: 32px;"
                        @click="close">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider />

            <v-card-text>
                <v-container>
                    <div class="save-btn-container">
                        <div class="save-btn"
                             @click="save">
                            Gem ændringer
                        </div>
                    </div>

                    <v-row style="margin-bottom: 50px;">
                        <v-col cols="4" sm="4" md="4">
                            <v-row>
                                <v-text-field v-model="editedItem.name"
                                              label="Titel"
                                              hint="Indtast venligst en titel"
                                              :error-message="errorMessages"
                                              :rules="[()=> !!editedItem.name || 'This field is required']"
                                              required />
                            </v-row>
                        </v-col>
                        <v-col cols="4" sm="4" md="4"></v-col>
                        <v-col cols="4" sm="4" md="4">
                            <v-row>
                                <v-text-field v-model="editedItem.date"
                                              type="date"
                                              label="Dato"
                                              placeholder="dd-mm-åååå"
                                              hint="Indtast venligst en dato for konsultationen"
                                              :rules="[
                                              !!editedItem.date ||
                                              'This field is required'
                                              ]"
                                              required/>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-textarea v-model="editedItem.description" 
                                    label="Journal / Kommentar / Besked (Synlig for brugeren under videoen i appen))"
                                    outlined
                                    style="border-radius: 0;" />
                    </v-row>

                    <v-row>
                        <!-- Vimeo ID -->
                        <v-col cols="4" sm="4" md="4">
                            <div style="padding-right: 50px;">
                                <v-row>
                                    <v-text-field v-model="editedItem.vimeoId"
                                                  label="Vimeo ID" />
                                </v-row>
                                <v-row>
                                    <!--<v-btn color="error"
                                           :disabled="loading"
                                           @click="removeVideoOnVimeo(editedItem.vimeoId)">
                                        Fjern Video
                                    </v-btn>-->
                                </v-row>
                            </div>
                        </v-col>

                        <!-- Video Link -->
                        <v-col cols="4" sm="4" md="4"
                               >
                            <div style="padding: 0 50px;">
                                <v-row>
                                    <v-text-field v-model="editedItem.videoLink"
                                                  label="Video Link" />
                                </v-row>
                                <v-row>
                                    <div style="display: flex;">
                                        <div>
                                            <v-btn color="primary"
                                                   :disabled="disableGetLinkBtn(editedItem)"
                                                   @click="getLatestVideoLink">
                                                Hent Link
                                            </v-btn>
                                        </div>
                                        <div style="margin-left: 20px; margin-top: 5px; font-size: 0.6rem; line-height: 0.7rem;">
                                            Der kan gå op til 20min før du kan hente linket. Du kan altid komme tilbage og fuldføre senere
                                        </div>
                                    </div>
                                </v-row>
                            </div>
                        </v-col>

                        <!-- Upload -->
                        <v-col cols="4" sm="4" md="4">
                            <div style="padding-left: 50px;">
                                <v-row>
                                    <v-file-input v-model="videoFile"
                                                  label="upload" />
                                </v-row>
                                <v-row>
                                    <v-btn color="primary"
                                           :disabled="loading"
                                           @click="uploadOrReplaceVideoOnVimeo">
                                        Upload Video
                                    </v-btn>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row style="margin-top: 90px; margin-bottom: 10px;">
                        <v-btn color="error"
                               style="padding: 20px 35px; border-radius: 0;"
                               outlined
                               @click="clearAll">
                            Ryd Alt
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
    import { mapState } from "vuex";
    import _ from "lodash";
    import axios from "axios";
    import moment from "moment";

    export default {
        name: "ConsultationForm",
        computed: {
            ...mapState({
                loading: (state) => state.loading,
            }),
        },
        data: () => ({
            editedItem: {},
            errorMessages: "",
            valid: false,
            videoFile: null,
        }),
        methods: {
            async getLatestVideoLink() {
                //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
                if (this.editedItem.vimeoId && !this.loading) {
                    this.setLoadingOn();
                    await axios.get("/api/vimeo/getLink/" + this.editedItem.vimeoId)
                        .then((response) => {
                            if (response.data.files.length > 0) {
                                this.editedItem.videoLink =
                                    response.data.files[0].link;
                                this.setLoadingOff();
                                this.save();
                            } else
                                this.$store.commit(
                                    "setAlertErrorSnack",
                                    "Der er ingen filer tilgængelige på vimeo, prøv igen senere."
                                );
                        })
                        .catch(() => this.setLoadingOff());
                }
            },
            async removeVideoOnVimeo(id) {
                //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
                if (id != "" && !this.loading) {
                    this.setLoadingOn();
                    await axios.delete("/api/vimeo/delete/" + id)
                        .then((response) => {
                            if (this.editedItem.vimeoId == id) {
                                this.editedItem.vimeoId = "";
                                this.editedItem.videoLink = "no link";
                                this.setLoadingOff();
                                this.save();
                            }
                        })
                        .catch(() => this.setLoadingOff());
                }
            },
            async replaceVideoOnVimeo() {
                //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
                if (this.editedItem.vimeoId && !this.loading) {
                    var oldVimeoID = this.editedItem.vimeoId;
                    await this.uploadVideoToVimeo();
                    //await this.removeVideoOnVimeo(oldVimeoID);
                }
            },
            async uploadVideoToVimeo() {
                //works through the DotNet API, and not directly on the vimeo API. See VimeoController.
                if (this.videoFile != null && !this.loading) {
                    this.$refs.form.validate();

                    if (this.valid) {
                        this.setLoadingOn();
                        const formData = new FormData();

                        formData.append("file_data", this.videoFile);
                        await axios.post(
                            "/api/vimeo/create/" + this.editedItem.title,
                            formData
                        )
                            .then((response) => {
                                this.editedItem.vimeoId = response.data.videoID;
                                this.editedItem.videoLink = "no link";
                                this.setLoadingOff();
                                this.save();
                            })
                            .catch(() => this.setLoadingOff());
                    } else {
                        this.$store.commit(
                            "setAlertErrorSnack",
                            "Udfyld venligst alle data inden du uploader/gemmer."
                        );
                    }
                }
            },
            clearAll() {
                if (this.isEdit()) {
                    this.editedItem.description = "";
                    this.editedItem.name = "";
                    this.editedItem.vimeoId = "";
                    this.editedItem.videoLink = "";
                }
                else {
                    this.editedItem = this.getEmptyConsultation();
                }
            },
            close() {
                this.editedItem = this.getEmptyConsultation();
                this.$emit("close");
            },
            disableGetLinkBtn(obj) {
                if (obj.vimeoId && !this.loading) {
                    return false;
                }
                return true;
            },
            getEmptyConsultation() {
                return {
                    created: "",
                    date: "",
                    description: "",
                    id: -1,
                    name: "",
                    userId: -1,
                    videoLink: "",
                    vimeoId: "",
                }
            },
            isEdit() {
                return this._baseEditedItem?.id > 1;
            },
            save() {
                this.$refs.form.validate();
                if (!this.valid) {
                    return;
                }

                const consultation = this.editedItem;
                if (this.isEdit()) { // Update Consultation
                    const obj = {
                        Idx: consultation.id,
                        updatedConsultation: consultation,
                    };
                    this.$store.dispatch("updateConsultation", obj).then(
                        (error) => {
                            console.log(error);
                        }
                    );
                }
                else { // Create Consultation
                    this.$store.dispatch("createConsultation", consultation)
                        .then(
                            (error) => {
                                console.log(error);
                            }
                        );
                }
                this.$emit('save');
                this.close();
            },
            setLoadingOn() {
                if (!this.loading) this.$store.commit("setLoading", true);
            },
            setLoadingOff() {
                if (this.loading) this.$store.commit("setLoading", false);
            },
            uploadOrReplaceVideoOnVimeo() {
                if (this.editedItem.vimeoId) {
                    this.replaceVideoOnVimeo();
                } else {
                    this.uploadVideoToVimeo();
                }
            },
        },
        mounted() {
            this.editedItem = {
                created: this._baseEditedItem.created,
                date: moment(this._baseEditedItem.date).format('YYYY-MM-DD'),
                description: this._baseEditedItem.description,
                id: this._baseEditedItem.id,
                name: this._baseEditedItem.name,
                userId: this.userId,
                videoLink: this._baseEditedItem.videoLink,
                vimeoId: this._baseEditedItem.vimeoId,
            }
        },
        props: ['formTitle', '_baseEditedItem', 'userId'],
    }
</script>

<style scoped>
    .save-btn-container {
        background: transparent;
        color: #738ea6;
        display: flex;
        justify-content: end;
        top: 0;
        z-index: 2;
        margin-bottom: 15px;
    }

    .save-btn {
        cursor: pointer;
        margin-right: -25px;
        padding: 6px 15px;
        font-size: 1.2rem !important;
        font-weight: 400;
        line-height: 1.3rem;
        letter-spacing: normal !important;
        font-family: "Roboto", sans-serif !important;
        padding-top: 0;
    }
</style>