
<template>
	<div v-if="loggedInUser.role == 'Admin'" class="OnlineCourses d-flex justify-center"
		style="padding: 10px; padding-top: 20px">

		<v-dialog :persistent="loading" :no-click-animation="true" v-model="dialog" max-width="1600px">
			<online-course-form v-if="dialog" :course="editingCourse" :duplicating="duplicating" @saved="savedTriggered"
				@close="closeTriggered" />
			<!-- Online course form -->
		</v-dialog>

		<List title="Online Kurser" label="Søg i kursuslister" :canAddOrRemove="true" :canDuplicate="true"
			:chipSlots="chipSlots" :clickableSlots="clickableSlots" :contents="courses" :hasStats="false" :headers="headers"
			:showGroupings="false" @addClicked="addClicked" @deleteClicked="deleteClicked"
			@duplicateClicked="duplicateClicked" @editClicked="editClicked" @updatePriority="updatePriority" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import List from "@/components/List.vue";
import OnlineCourseForm from "@/components/OnlineCourseForm.vue";
import moment from "moment";

export default {
	name: "OnlineCourses",
	components: {
		List,
		OnlineCourseForm,
	},
	data: () => ({
		chipSlots: [
			{ field: "groupings", propertyToShow: "name", shownElemsCount: 2, shownElemsWidth: "105px" }
		],
		dialog: false,
		headers: [
			{ text: "Prioritet", align: "start", value: "priority", sortable: true },
			{ text: "Navn", value: "name", sortable: true },
			{ text: "Grupper på kursus", value: "groupings", sortable: false },
			{ text: "Oprettet", value: "created", sortable: false },
			{ text: "Brugere", value: "userCount", sortable: true },
			{ text: "Grupper", value: "groupingCount", sortable: true },
			{ text: "Actions", value: "actions", sortable: false }
		],
		startDateMenu: false,
		valid: false,

		editingCourse: null,
		duplicating: false,
	}),
	computed: {
		...mapState({
			courses: (state) => state.courses.courses,
			loggedInUser: (state) => state.auth.user,
			loading: (state) => state.loading,
			loggedIn: (state) => !!state.auth.token,
		}),
		clickableSlots() {
			return [
				{ field: "userCount", action(item) { this.editClicked(item); } },
				{ field: "groupingCount", action(item) { this.editClicked(item); } }
			];
		},
	},
	created() {
		if (!this.loggedIn)
			this.$router.push({
				path: "/",
			})
		else this.initialize();
	},
	methods: {
		initialize() {
			this.$store.dispatch("getAllCourses");
		},
		isInt(value) {
			return !isNaN(value) &&
				parseInt(Number(value)) == value &&
				!isNaN(parseInt(value, 10));
		},
		addClicked() {
			this.dialog = true;
		},
		savedTriggered() {
			this.dialog = false;
			this.initialize();
		},
		closeTriggered() {
			this.dialog = false;
		},
		async deleteClicked(item) {
			if (!confirm("Er du sikker på at du vil slette dette kursus?")) {
				return;
			}
			await this.$store.dispatch("deleteCourse", item.id);
			this.initialize();
		},
		async duplicateClicked(item) {
			this.duplicating = true;
			this.editingCourse = { ...item };
			this.dialog = true;
		},
		async updatePriority(item) {
			await this.$store.dispatch('updatePriority', item);
			this.initialize();
		},
		async editClicked(item) {
			this.editingCourse = { ...item };
			this.dialog = true;
		},
	},
	watch: {
		dialog(newVal, oldVal) {
			if (!newVal) {
				this.duplicating = false;
				this.editingCourse = null;
			}
		}
	}
}
</script>

<style scoped>
.numberInput>>>input::-webkit-inner-spin-button,
.numberInput>>>input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.saveBtnContainer {
	background: transparent;
	color: #738ea6;
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: sticky;
	top: 65px;
	padding: 12px 15px;
	z-index: 2;
	float: right;
}

.content-container {
	border: 1px solid black;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 30px;
	padding-bottom: 0;
}

.content-container:first-child {
	margin-top: 0px;
}

.content-container:not(:first-child) {
	margin-top: 55px;
}

.content-count-root {
	color: #738ea6;
	display: flex;
	flex-direction: row;
	line-height: 1.2rem;
}

.content-count-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: end;
	border-bottom: 2px solid #738ea6;
	width: 35px;
	margin-right: 5px;
}

.content-count-text-container {
	display: flex;
	flex-direction: column;
	justify-content: end;
	border: 2px solid transparent;
}

.move-btns-root {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.move-btns-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.text-field-right>>>input {
	text-align: end;
}

.title-container {
	color: white;
	border: none;
	border-radius: 0;
	display: flex;
	position: sticky;
	top: 0;
	z-index: 2;
}

.description-container {
	padding: 0;
	padding-right: 40px;
}

.description-container>>>fieldset {
	border-color: black;
}

.move-arrow {
	cursor: pointer;
	height: 30px;
	width: 35px;
}

.move-arrow-rotated {
	transform: rotate(180deg);
}

.simple-list-container {
	flex: 1 1 auto;
	height: 300px;
	overflow: scroll;
}

.simple-list-container::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.course-availability-row {
	justify-content: center;
}

.course-availability-header {
	font-weight: bold;
}

.course-availability-content-container {
	display: flex;
	align-items: center;
}

.course-availability-text-container {
	display: flex;
	justify-content: center;
	align-content: center;
}

.course-availability-text {
	text-align: center;
	margin-bottom: 0;
}

.course-availability-text-left {
	width: 110px;
}

.course-availability-text-right {
	width: 135px;
}

.course-availability-switch-container {
	display: flex;
	justify-content: center;
	width: 90px;
	padding-left: 10px;
	padding-right: 10px;
}
</style>