import { render, staticRenderFns } from "./SimpleList.vue?vue&type=template&id=2160f952&scoped=true"
import script from "./SimpleList.vue?vue&type=script&lang=js"
export * from "./SimpleList.vue?vue&type=script&lang=js"
import style0 from "./SimpleList.vue?vue&type=style&index=0&id=2160f952&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2160f952",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VDataTable,VIcon,VLayout})
