import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#738EA6",
                secondary: "#555",
                accent: "#9E9E9E",
                error: "#FF5B6C",
                info: "#555",
                success: "#77BC27",
                warning: "#F2DB2E",
                colorPrimary100: "#EBF6FA",
                colorPrimary200: "#D7ECF6",
                colorPrimary300: "#BAD4E4",
                colorPrimary400: "#9BB6C9",
                colorPrimary500: "#738EA6",
                colorPrimary600: "#54708E",
                colorPrimary700: "#395477",
                colorPrimary800: "#243A60",
                colorPrimary900: "#16284F",
                colorPrimaryTransparent100: "rgba(115, 142, 166, 0.08)",
                colorPrimaryTransparent200: "rgba(115, 142, 166, 0.16)",
                colorPrimaryTransparent300: "rgba(115, 142, 166, 0.24)",
                colorPrimaryTransparent400: "rgba(115, 142, 166, 0.32)",
                colorPrimaryTransparent500: "rgba(115, 142, 166, 0.4)",
                colorPrimaryTransparent600: "rgba(115, 142, 166, 0.48)",
                colorSuccess100: "#F0FBD3",
                colorSuccess200: "#DEF8A9",
                colorSuccess300: "#C1EA7B",
                colorSuccess400: "#A1D657",
                colorSuccess500: "#77BC27",
                colorSuccess600: "#5DA11C",
                colorSuccess700: "#468713",
                colorSuccess800: "#326D0C",
                colorSuccess900: "#245A07",
                colorSuccessTransparent100: "rgba(119, 188, 39, 0.08)",
                colorSuccessTransparent200: "rgba(119, 188, 39, 0.16)",
                colorSuccessTransparent300: "rgba(119, 188, 39, 0.24)",
                colorSuccessTransparent400: "rgba(119, 188, 39, 0.32)",
                colorSuccessTransparent500: "rgba(119, 188, 39, 0.4)",
                colorSuccessTransparent600: "rgba(119, 188, 39, 0.48)",
                colorInfo100: "#F6F6F6",
                colorInfo200: "#EEEEEE",
                colorInfo300: "#CCCCCC",
                colorInfo400: "#999999",
                colorInfo500: "#555",
                colorInfo600: "#493E3F",
                colorInfo700: "#3D2A2E",
                colorInfo800: "#311B20",
                colorInfo900: "#281018",
                colorInfoTransparent100: "rgba(85, 85, 85, 0.08)",
                colorInfoTransparent200: "rgba(85, 85, 85, 0.16)",
                colorInfoTransparent300: "rgba(85, 85, 85, 0.24)",
                colorInfoTransparent400: "rgba(85, 85, 85, 0.32)",
                colorInfoTransparent500: "rgba(85, 85, 85, 0.4)",
                colorInfoTransparent600: "rgba(85, 85, 85, 0.48)",
                colorWarning100: "#FEFBD5",
                colorWarning200: "#FDF7AB",
                colorWarning300: "#FBEF81",
                colorWarning400: "#F7E761",
                colorWarning500: "#F2DB2E",
                colorWarning600: "#D0B921",
                colorWarning700: "#AE9817",
                colorWarning800: "#8C780E",
                colorWarning900: "#746208",
                colorWarningTransparent100: "rgba(242, 219, 46, 0.08)",
                colorWarningTransparent200: "rgba(242, 219, 46, 0.16)",
                colorWarningTransparent300: "rgba(242, 219, 46, 0.24)",
                colorWarningTransparent400: "rgba(242, 219, 46, 0.32)",
                colorWarningTransparent500: "rgba(242, 219, 46, 0.4)",
                colorWarningTransparent600: "rgba(242, 219, 46, 0.48)",
                colorDanger100: "#FFE6DE",
                colorDanger200: "#FFC8BD",
                colorDanger300: "#FFA49C",
                colorDanger400: "#FF8385",
                colorDanger500: "#FF5B6C",
                colorDanger600: "#DB4260",
                colorDanger700: "#B72D55",
                colorDanger800: "#931D49",
                colorDanger900: "#7A1142",
                colorDangerTransparent100: "rgba(255, 91, 108, 0.08)",
                colorDangerTransparent200: "rgba(255, 91, 108, 0.16)",
                colorDangerTransparent300: "rgba(255, 91, 108, 0.24)",
                colorDangerTransparent400: "rgba(255, 91, 108, 0.32)",
                colorDangerTransparent500: "rgba(255, 91, 108, 0.4)",
                colorDangerTransparent600: "rgba(255, 91, 108, 0.48)"
            },
        },
    },
});
